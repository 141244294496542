/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Alert, Row, Col } from "react-bootstrap";
import InputFields from "../../InputFields";
import { currencyFormatter } from "../../utils";

class MainForm extends Component {
  render() {
    const { deal, dispatch, investForm, options } = this.props;
    const checkMaxPayment = (v) => {
      const amount = investForm?.investment_info?.amount?.replace(
        /[^0-9.]/g,
        ""
      );
      const inputType = v?.target?.name?.replace(/[.]/g, "")?.toUpperCase();
      const transactionType =
        inputType === "TRANSACTIONTYPE"
          ? v?.target?.value
          : investForm?.investment_info?.transactionType;
      const paymentLimits = options?.paymentLimits?.find(
        (p) =>
          p?.type?.toUpperCase() === transactionType?.toUpperCase() && p?.max
      );
      if (paymentLimits && amount > paymentLimits?.max) {
        dispatch(
          actions.change(
            "investForm.max_amount_error_message",
            `The max amount allowed for investment through the chosen payment method is: ${currencyFormatter.format(
              paymentLimits?.max
            )}`
          )
        );
        dispatch(actions.change("investForm.max_payment_limit", true));
      } else {
        dispatch(actions.change("investForm.max_amount_error_message", null));
        dispatch(actions.change("investForm.max_payment_limit", false));
      }
    };

    return (
      <React.Fragment>
        <div className="investFormHeaderDiv">
          <h1 className="investFormHeader">Investment Information</h1>
        </div>
        <Row className="inputRow">
          <Col lg={6} className="inputCol">
            <InputFields
              label="Your full name"
              type="text"
              inputType="text"
              model=".investor_name"
              tooltip="The name of the human filling out this form."
              value={investForm?.investment_info?.investor_name}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Email"
              type="email"
              inputType="text"
              model=".investor_email"
              value={investForm?.investment_info?.investor_email}
            />
          </Col>
        </Row>
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <label className="formInputsLabel">
              I am investing as
              {!investForm.investment_info?.type && investForm.showFormError ? (
                <p className="formInputError">Required*</p>
              ) : null}
            </label>
            {/* Individual, Entity, TIC, JTWROS, IRA, SepIRA, ROTH, Joint */}
            <Row>
              <Col lg={6}>
                <InputFields
                  label="an Individual"
                  type="radio"
                  inputType="radio"
                  value="Individual"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="an Entity"
                  type="radio"
                  inputType="radio"
                  value="Entity"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="a Joint Tenants in Common (TIC)"
                  type="radio"
                  inputType="radio"
                  value="TIC"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="a Joint Tenants with Right of Survivorship (JTWROS)"
                  type="radio"
                  inputType="radio"
                  value="JTWROS"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="a Self-Directed IRA or 401(k)"
                  type="radio"
                  inputType="radio"
                  value="IRA"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="a SEP IRA"
                  type="radio"
                  inputType="radio"
                  value="SepIRA"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="a ROTH"
                  type="radio"
                  inputType="radio"
                  value="ROTH"
                  model=".type"
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="a Joint"
                  type="radio"
                  inputType="radio"
                  value="Joint"
                  model=".type"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {investForm?.investment_info?.type?.toUpperCase() === "ENTITY" && (
          <Row className="inputRow">
            <Col lg={12} className="inputCol">
              <label className="formInputsLabel">
                Entity Type:
                {!investForm.investment_info?.entityType &&
                investForm.showFormError ? (
                  <p className="formInputError">Required*</p>
                ) : null}
              </label>
              <Row>
                <Col lg={6}>
                  <InputFields
                    label="Revocable Trust"
                    type="radio"
                    inputType="radio"
                    value="Revocable Trust"
                    model=".entityType"
                  />
                </Col>
                <Col lg={6}>
                  <InputFields
                    label="Irrevocable Trust"
                    type="radio"
                    inputType="radio"
                    value="Irrevocable Trust"
                    model=".entityType"
                  />
                </Col>
                <Col lg={6}>
                  <InputFields
                    label="Limited Partnership"
                    type="radio"
                    inputType="radio"
                    value="Limited Partnership"
                    model=".entityType"
                  />
                </Col>
                <Col lg={6}>
                  <InputFields
                    label="LLC"
                    type="radio"
                    inputType="radio"
                    value="LLC"
                    model=".entityType"
                  />
                </Col>
                <Col lg={6}>
                  <InputFields
                    label="Corporation"
                    type="radio"
                    inputType="radio"
                    value="Corporation"
                    model=".entityType"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={6} className="inputCol">
            <InputFields
              label="How much do you wish to invest in this offering?"
              type="text"
              inputType="currency"
              model=".amount"
              maxValue={deal?.targetAmount}
              maxValueMessage="The investment amount cannot exceed the value of the offering"
              value={investForm?.investment_info?.amount}
              tooltip={
                !deal?.allow_fractional_investment ? (
                  <>
                    Amount will automatically adjust to match the price per
                    share of {currencyFormatter.format(deal?.unitPrice)}
                  </>
                ) : null
              }
              onBlur={() => {
                dispatch(
                  deal?.unitPrice &&
                    actions.change(
                      "investForm.investment_info.transactionUnits",
                      deal?.allow_fractional_investment
                        ? (
                            investForm?.investment_info?.amount?.replace(
                              /[^0-9.]/g,
                              ""
                            ) / deal?.unitPrice
                          )?.toFixed(6)
                        : Math.floor(
                            investForm?.investment_info?.amount?.replace(
                              /[^0-9.]/g,
                              ""
                            ) / deal?.unitPrice
                          )?.toFixed(0)
                    )
                );
                const amount = investForm?.investment_info?.amount?.replace(
                  /[^0-9.]/g,
                  ""
                );
                if (Number(amount) < Number(deal?.minAmount)) {
                  dispatch(
                    actions.change(
                      "investForm.min_amount_error_message",
                      `The minimum amount allowed for investment is: ${currencyFormatter.format(
                        deal?.minAmount
                      )}`
                    )
                  );
                  dispatch(
                    actions.change("investForm.min_payment_limit", true)
                  );
                } else {
                  dispatch(
                    actions.change("investForm.min_amount_error_message", null)
                  );
                  dispatch(
                    actions.change("investForm.min_payment_limit", false)
                  );
                }

                if (
                  !deal?.allow_fractional_investment &&
                  deal?.unitPrice &&
                  investForm?.investment_info?.amount
                ) {
                  const amountInNumber = Number(
                    investForm.investment_info.amount?.replace(/[^0-9.]/g, "")
                  );
                  dispatch(
                    deal?.unitPrice &&
                      actions.change(
                        "investForm.investment_info.amount",
                        currencyFormatter.format(
                          amountInNumber - (amountInNumber % deal?.unitPrice)
                        )
                      )
                  );
                }
                checkMaxPayment();
              }}
            />
            {deal?.minAmount > 0 ? (
              <span className="sourceRegular">
                Minimum investment:{" "}
                <b>{currencyFormatter.format(deal?.minAmount)}</b>
              </span>
            ) : null}
          </Col>
          {/* cannot be greater than "$100,000.00" when using "ach" funds transfer method */}
          <Col lg={6} className="inputCol">
            <InputFields
              label="No. of Units"
              type="number"
              inputType="text"
              model=".transactionUnits"
              // minValue={0.1}
              // minLength={0}
              value={investForm?.investment_info?.transactionUnits}
              onBlur={() => {
                if (
                  deal?.unitPrice &&
                  investForm?.investment_info?.transactionUnits &&
                  !isNaN(investForm?.investment_info?.transactionUnits)
                ) {
                  dispatch(
                    actions.change(
                      "investForm.investment_info.amount",
                      currencyFormatter.format(
                        deal?.unitPrice *
                          investForm?.investment_info?.transactionUnits
                      )
                    )
                  );
                  checkMaxPayment();
                }
              }}
            />
          </Col>
        </Row>
        {investForm?.max_amount_error_message && (
          <Alert className="formAlert sourceBold" variant="warning">
            {investForm?.max_amount_error_message || "Error occured"}
          </Alert>
        )}
        {investForm?.min_amount_error_message && (
          <Alert className="formAlert sourceBold" variant="warning">
            {investForm?.min_amount_error_message || "Error occured"}
          </Alert>
        )}
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <label className="formInputsLabel">
              How will you be sending the funds for this investment?
              {!investForm.investment_info?.transactionType &&
              investForm?.showFormError ? (
                <p className="formInputError">Required*</p>
              ) : null}
            </label>
            <Row>
              <Col lg={6}>
                <InputFields
                  label="ACH (Electronic Check) - US Bank Account Only"
                  type="radio"
                  inputType="radio"
                  value="ACH"
                  model=".transactionType"
                  onClick={(v) => checkMaxPayment(v)}
                />
              </Col>
              {/* <Col lg={6}>
                    <InputFields
                      label="Mail a Check"
                      type="radio"
                      inputType="radio"
                      value="check"
                      model=".transactionType"
                    />
                  </Col> */}
              <Col lg={6}>
                <InputFields
                  label="Wire Transfer"
                  type="radio"
                  inputType="radio"
                  value="WIRE"
                  model=".transactionType"
                  onClick={(v) => checkMaxPayment(v)}
                />
              </Col>
              <Col lg={6}>
                <InputFields
                  label="Credit card"
                  type="radio"
                  inputType="radio"
                  value="CREDITCARD"
                  model=".transactionType"
                  onClick={(v) => checkMaxPayment(v)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
});

export default connect(mapStateToProps)(MainForm);
