/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import {
  Row,
  Col,
  Button,
  Modal,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import Close from "../../../../global/img/close.png";
import InputFields from "../../../InputFields";
import TooltipIcon from "../../../../global/img/tooltipIcon.svg";
import { currencyFormatter } from "../../../utils";

class InvestorQuestionnaire extends Component {
  state = {
    show: false,
    secRules: false,
    customTooltip: false,
  };
  render() {
    const { dispatch, deal, investForm } = this.props;
    const { show, secRules, customTooltip } = this.state;

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" className="tooltipBody" {...props}>
        {props?.popper?.state?.options?.tooltip}
      </Tooltip>
    );

    // const accreditedInvestorOptions = options?.accreditedInvestorOptions?.find(
    //   (a) =>
    //     a?.investor_type?.toUpperCase() ===
    //       investForm?.investment_info?.investor_type?.toUpperCase() ||
    //     a?.investor_type?.toUpperCase() ===
    //       investForm?.investment_info?.type?.toUpperCase()
    // );

    const handleShow = (showSecRules, customTooltip) =>
      this.setState({ show: !show, secRules: showSecRules, customTooltip });
    const handleClose = () =>
      this.setState({ show: false, secRules: false, customTooltip: null });

    const company = {
      bank: "We are a bank; registered broker-dealer; investment adviser registered with the SEC or a state; investment company registered under the Investment Company Act of 1940; or insurance company.",
      employee_benefit_plan:
        "We are an employee benefit plan within the meaning of the Employee Retirement Income Security Act of 1974 where the investment decision is made by a plan fiduciary which is either a bank, savings and loan association, insurance company, or registered investment adviser, or where the employee benefit plan has total assets in excess of $5,000,000 or, if a self-directed plan, the investment decisions are made solely by persons that are accredited investors.",
      trust:
        "We are a trust with assets in excess of $5 million, not specifically formed to acquire the securities offered, whose purchases are made by a sophisticated investor.",
      business:
        "We are a business in which all the equity owners are accredited investors.",
      corporation:
        "We are an organization described in section 501(c)(3) of the Internal Revenue Code, corporation, Massachusetts or similar business trust, partnership, or limited liability company, not formed for the specific purpose of acquiring the securities offered, with total assets in excess of $5,000,000; or",
      family_office:
        "We are a family office, not specifically formed to acquire the securities offered, with at least $5 million in assets under management, whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment, and our family clients.",
      foreign_entity:
        "An entity, including Indian tribes, governmental bodies, funds, and entities organized under the laws of foreign countries, that own investments, as defined in Rule 2a51-1(b) under the Investment Company Act, in excess of $5 million and that was not formed for the specific purpose of investing in the securities offered.",
    };

    const individual = {
      // Changed
      individual_income: (
        <>
          I am an individual with income of over $200,000 in each of the last
          two years, or joint income with my spouse{" "}
          <a
            href="#/"
            className="generalLink"
            title="A spousal equivalent is a cohabitant occupying a relationship generally equivalent to that of a spouse."
            onClick={() =>
              handleShow(
                false,
                "A spousal equivalent is a cohabitant occupying a relationship generally equivalent to that of a spouse."
              )
            }
          >
            or spousal equivalent
          </a>{" "}
          exceeding $300,000 in those years, and I reasonably expect at least
          the same income this year.
        </>
      ),
      // Changed
      certified_individual:
        "I am an individual with my FINRA Series 7, Series 65 and Series 82 licenses in good standing.",
      // Changed
      individual_net_worth: (
        <>
          I am an individual with a net worth, or joint net worth with my
          spouse, that exceeds $1 million including any IRA's, 401K's and other
          retirement accounts, but excluding the net value of my primary
          residence.
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            // show={true}
            popperConfig={{
              tooltip: `In calculating net worth, include all of your assets (other than your primary residence) whether liquid or illiquid, such as cash, stock, securities, personal property and real estate based on the fair market value of such property MINUS all debts and liabilities (other than a mortgage or other debt secured by your primary residence). A "spousal equivalent" is a cohabitant occupying a relationship generally equivalent to that of a spouse.`,
            }}
          >
            <img src={TooltipIcon} alt="Tooltip" className="tooltipIcon" />
          </OverlayTrigger>
        </>
      ),
      // Changed
      knowledgable_employee:
        "I am a director, executive officer, or general partner of the issuer of the securities being offered or sold.",
    };

    const trust = {
      trust:
        "We are a trust with assets in excess of $5 million, not specifically formed to acquire the securities offered, whose purchases are made by a sophisticated investor;",
    };

    const ira = {
      spousal_pool:
        "I have pooled my finances with my spousal equivalent and qualify as an accredited investor.",
      knowledgable_employee:
        "I am a director, executive officer, or general partner of the issuer of the securities being offered or sold.",
    };

    const emptyIncomeAndNetWorth = () => {
      if (!investForm?.prevent_income_change) {
        dispatch(
          actions.change("investForm.investor_questionnaire.annual_income", "")
        );
        dispatch(
          actions.change("investForm.investor_questionnaire.net_worth", "")
        );
      }
    };

    const isCompany =
      investForm?.investment_info?.type?.toUpperCase() === "ENTITY" &&
      !investForm?.investment_info?.entityType
        ?.toUpperCase()
        ?.includes("TRUST");

    const isJoint =
      investForm?.investment_info?.type?.toUpperCase() === "TIC" ||
      investForm?.investment_info?.type?.toUpperCase() === "JTWROS" ||
      investForm?.investment_info?.type?.toUpperCase() === "JOINT";

    const isTrust =
      investForm?.investment_info?.type?.toUpperCase() === "ENTITY" &&
      investForm?.investment_info?.entityType?.toUpperCase()?.includes("TRUST");

    const isIRA =
      investForm?.investment_info?.type?.toUpperCase() === "SEPIRA" ||
      investForm?.investment_info?.type?.toUpperCase() === "IRA" ||
      investForm?.investment_info?.type?.toUpperCase() === "ROTH";

    const AccreditedOptions = () => {
      let optionsObj = {};
      let accredOptions = [];
      if (isCompany) {
        optionsObj = company;
      } else if (
        investForm?.investment_info?.type?.toUpperCase() === "INDIVIDUAL" ||
        isJoint
      ) {
        optionsObj = individual;
      } else if (isTrust) {
        optionsObj = trust;
      } else if (isIRA) {
        optionsObj = ira;
      }
      for (const [key, value] of Object.entries(optionsObj)) {
        accredOptions.push(
          <Col lg={12}>
            <InputFields
              label={[value]}
              type="radio"
              inputType="radio"
              value={key}
              model=".accredited_investor_type"
              onChange={() => emptyIncomeAndNetWorth()}
            />
          </Col>
        );
      }
      return accredOptions;
    };

    // const annual_income =
    //   investForm?.investor_questionnaire?.annual_income?.replace(
    //     /[^0-9.]/g,
    //     ""
    //   );
    // const net_worth = investForm?.investor_questionnaire?.net_worth?.replace(
    //   /[^0-9.]/g,
    //   ""
    // );
    // const investorQuestionnaire = investForm?.investor_questionnaire;

    // let test = false;

    // if (
    //   investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() !==
    //     "YES" ||
    //   (investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() ===
    //     "YES" &&
    //     ((investorQuestionnaire?.accredited_investor_type?.toUpperCase() !==
    //       "INDIVIDUAL_INCOME" &&
    //       investorQuestionnaire?.accredited_investor_type?.toUpperCase() !==
    //         "INDIVIDUAL_NET_WORTH" &&
    //       annual_income <= investForm?.accredited_income_minimum &&
    //       net_worth <= investForm?.accredited_net_worth_minimum) ||
    //       (investorQuestionnaire?.accredited_investor_type?.toUpperCase() ===
    //         "INDIVIDUAL_INCOME" &&
    //         annual_income <= investForm?.accredited_income_minimum) ||
    //       (investorQuestionnaire?.accredited_investor_type?.toUpperCase() ===
    //         "INDIVIDUAL_NET_WORTH" &&
    //         net_worth <= investForm?.accredited_net_worth_minimum)))
    // ) {
    //   console.log("HEY YOU SHOULD NOT BE ALLOWED!!!!!!");
    //   test = true;
    // }

    const { investor_questionnaire } = investForm;

    const useIncome =
      investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() ===
        "1" &&
      investor_questionnaire?.accredited_investor_type?.toUpperCase() ===
        "INDIVIDUAL_INCOME";

    const useNetWorth =
      investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() ===
        "1" &&
      investor_questionnaire?.accredited_investor_type?.toUpperCase() ===
        "INDIVIDUAL_NET_WORTH";

    return (
      <>
        <Modal
          id="loginModalDialog"
          show={show}
          onHide={handleClose}
          backdrop
          backdropClassName="modalBackdrop"
          centered
        >
          <Modal.Header className="signupModalHeader">
            <section className="loginHeaderSection">
              <Modal.Title className="loginModalTitle sourceBold"></Modal.Title>
              <Button
                className="noStyleBtn loginModalCloseBtn"
                onClick={handleClose}
              >
                <img src={Close} alt="Close" className="modalClose" />
              </Button>
            </section>
          </Modal.Header>
          {secRules && (
            <Modal.Body className="accreditedModalBody">
              <div className="accreditedDiv">
                <p className="accreditedHeader">SEC rules:</p>
                <p className="accreditedText">
                  <b>1.</b> The aggregate amount of securities sold to any
                  non-accredited investor across all Regulation Crowdfunding
                  issuers during the 12-month period preceding the date of this
                  transaction, including the securities sold to such investor in
                  this Regulation Crowdfunding transaction, shall not exceed:
                  <ul>
                    <li>
                      The greater of $2,200 or 5 percent of the greater of the
                      non-accredited investor's annual income or net worth if
                      either the investor's annual income or net worth is less
                      than $107,000; or
                    </li>
                    <li>
                      10 percent of the greater of the non-accredited investor's
                      annual income or net worth, not to exceed an amount sold
                      of $107,000, if both the investor's annual income and net
                      worth are equal to or more than $107,000.
                    </li>
                  </ul>
                  <b>2.</b> These investor limitations do not apply to
                  accredited investors.
                </p>
              </div>
            </Modal.Body>
          )}
          {customTooltip && (
            <Modal.Body style={{ paddingBottom: "60px" }}>
              <p className="accreditedHeader">{customTooltip}</p>
            </Modal.Body>
          )}
          {!customTooltip && !secRules && (
            <Modal.Body className="accreditedModalBody">
              <div className="accreditedDiv">
                <p className="accreditedHeader">An Accredited Investor is:</p>
                <p className="accreditedText">
                  a) Any natural person who had an individual income in excess
                  of $200,000 in each of the two most recent years or joint
                  income with that person's spouse or{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8db0b0bded928f27dce3c6467f1c6945&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    spousal equivalent
                  </a>{" "}
                  in excess of $300,000 in each of those years and has a
                  reasonable expectation of reaching the same income level in
                  the current year; The term spousal equivalent shall mean a
                  cohabitant occupying a relationship generally equivalent to
                  that of a spouse; or
                </p>
                <p className="accreditedText">
                  b) a natural person whose today net worth (i.e., excess of
                  total assets over total liabilities) exceeds $1,000,000,
                  except that: a) the person's primary residence shall not be
                  included as an asset; b) indebtedness that is secured by the
                  person's primary residence, up to the estimated fair market
                  value of the primary residence at the time of the sale of
                  securities, shall not be included as a liability (except that
                  if the{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=5c8ffa363e62cc98ec44e4cc891b775d&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    amount
                  </a>{" "}
                  of such indebtedness outstanding at the time of sale of
                  securities exceeds the{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=5c8ffa363e62cc98ec44e4cc891b775d&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    amount
                  </a>{" "}
                  outstanding 60 days before such time, other than as a result
                  of the acquisition of the primary residence, the{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=5c8ffa363e62cc98ec44e4cc891b775d&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    amount
                  </a>{" "}
                  of such excess shall be included as a liability); and c)
                  indebtedness that is secured by the person's primary residence
                  in excess of the estimated fair market value of the primary
                  residence at the time of the sale of securities shall be
                  included as a liability; or
                </p>
                <p className="accreditedText">
                  c) Any bank as defined in section 3(a)(2) of the Securities
                  Act of 1933 (the “Act”), or any savings and loan association
                  or other institution as defined in section 3(a)(5)(A) of the
                  Act whether acting in its individual or fiduciary capacity;
                  any broker or dealer registered pursuant to section 15 of the
                  Securities Exchange Act of 1934 (the “Exchange Act”); any
                  investment adviser registered pursuant to section 203 of the
                  Investment Advisers Act of 1940 (the “Advisers Act”) or
                  registered pursuant to the laws of a state; any investment
                  adviser relying on the exemption from registering with the SEC
                  under section 203(l) or (m) of the Advisers Act; any insurance
                  company as defined in section 2(a)(13) of the Act; any
                  investment company registered under the Investment Company Act
                  of 1940 (the “40 Act”) or a business development company as
                  defined in section 2(a)(48) of the 40 Act; any Small Business
                  Investment Company licensed by the U.S. Small Business
                  Administration under section 301(c) or (d) of the Small
                  Business Investment Act of 1958; any Rural Business Investment
                  Company as defined in section 384A of the Consolidated Farm
                  and Rural Development Act; any plan established and maintained
                  by a state, its political subdivisions, or any agency or
                  instrumentality of a state or its political subdivisions, for
                  the benefit of its employees, if such plan has total assets in
                  excess of $5,000,000; any employee benefit plan within the
                  meaning of the Employee Retirement Income Security Act of 1974
                  if the investment decision is made by a plan fiduciary, as
                  defined in section 3(21) of such act, which is either a bank,
                  savings and loan association, insurance company, or registered
                  investment adviser, or if the employee benefit plan has total
                  assets in excess of $5,000,000 or, if a self-directed plan,
                  with investment decisions made solely by persons that are
                  accredited investors; or
                </p>
                <p className="accreditedText">
                  d) Any organization described in section 501(c)(3) of the
                  Internal Revenue Code, corporation, Massachusetts or similar
                  business trust, partnership, or limited liability company, not
                  formed for the specific purpose of acquiring the securities
                  offered, with total assets in excess of $5,000,000; or
                </p>
                <p className="accreditedText">
                  e) Any{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=839a969a537e67e83f39146e3537ec5e&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    director
                  </a>{" "}
                  ,{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8f3c18f254557c20e5e39dccb3c05188&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    executive officer
                  </a>{" "}
                  , or general partner of the{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8ef38ef231dcd4f032b6403f8f0d8971&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    issuer
                  </a>{" "}
                  of the securities being offered or sold, or any{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=839a969a537e67e83f39146e3537ec5e&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    director
                  </a>{" "}
                  ,{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8f3c18f254557c20e5e39dccb3c05188&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    executive officer
                  </a>{" "}
                  , or general partner of a general partner of that{" "}
                  <a
                    href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8ef38ef231dcd4f032b6403f8f0d8971&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                    className="generalLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    issuer
                  </a>
                  ; or
                </p>
                <p className="accreditedText">
                  f) Any entity, of a type not listed above, not formed for the
                  specific purpose of acquiring the securities offered, owning
                  investments in excess of $5,000,000 as defined in rule
                  2a51-1(b) under the 40 Act; or
                </p>
                <p className="accreditedText">
                  g) Any “family office,” as defined in rule 202(a)(11)(G)-1
                  under the Advisers Act. (i) with assets under management in
                  excess of $5,000,000, (ii) That is not formed for the specific
                  purpose of acquiring the securities offered, and (iii) whose
                  prospective investment is directed by a person who has such
                  knowledge and experience in financial and business matters
                  that such family office is capable of evaluating the merits
                  and risks of the prospective investment; and any “family
                  client,” as defined in rule 202(a)(11)(G)-1 under the Advisers
                  Act, of a family office meeting the requirements in paragraph
                  (a)(12) of this section and whose prospective investment in
                  the issuer is directed by such family office pursuant to
                  paragraph (a)(12)(iii).
                </p>
                <p className="accreditedHeader">
                  An Institutional Investor is:
                </p>
                <p className="accreditedText">
                  a) a bank, savings and loan institution, insurance company or
                  registered investment company; or
                </p>
                <p className="accreditedText">
                  b) an investment adviser registered either with the SEC under
                  Section 203 of the Advisers Act or with a state securities
                  commission; or
                </p>
                <p className="accreditedText">
                  c) any person (whether a natural person, a corporation,
                  partnership, trust, or otherwise) with total assets of at
                  least $50 million.
                </p>
              </div>
            </Modal.Body>
          )}
        </Modal>
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <p className="investorQuestText sourceRegular">
              This information is needed for us to comply with SEC and state
              securities regulations. We ask the following questions to
              determine if the amount you may invest is limited by law.
            </p>
            <label className="formInputsLabel">
              Are you an "accredited" investor (meaning do you earn over
              $200,000 per year, have a net worth of $1m or more, or are an
              institutional investor)?
              <img
                src={TooltipIcon}
                alt="Tooltip"
                className="tooltipIcon"
                onClick={() => handleShow()}
              />
              {!investForm?.investor_questionnaire?.accredited_investor &&
              investForm?.showFormError ? (
                <p className="formInputError">Required*</p>
              ) : null}
            </label>
            <Row>
              <Col lg={1}>
                <InputFields
                  label="Yes"
                  type="radio"
                  inputType="radio"
                  value="1"
                  model=".accredited_investor"
                  onChange={() => emptyIncomeAndNetWorth()}
                />
              </Col>
              <Col lg={1}>
                <InputFields
                  label="No"
                  type="radio"
                  inputType="radio"
                  value="0"
                  model=".accredited_investor"
                  onChange={() => emptyIncomeAndNetWorth()}
                />
              </Col>
            </Row>
            {investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() ===
              "1" && (
              <>
                <label className="formInputsLabel">
                  {investForm?.investment_info?.type?.toUpperCase() === "IRA"
                    ? "All beneficial owners must meet the following criteria:"
                    : "I qualify as an accredited investor as follows:"}
                  {!investForm?.investor_questionnaire
                    ?.accredited_investor_type && investForm?.showFormError ? (
                    <p className="formInputError">Required*</p>
                  ) : null}
                </label>
                <Row>
                  <AccreditedOptions />
                </Row>
              </>
            )}
          </Col>
          <Col lg={12} className="">
            <p className="investorQuestText sourceRegular">
              The law limits the total amount you can invest based on your
              annual income and your net worth. Please provide these so that we
              may determine if the amount you wish to invest is within these
              limitations.
            </p>
          </Col>
          {investForm?.prevent_income_change &&
          investForm?.accredited?.prevent_error ? (
            <Col lg={12}>
              <Alert className="formAlert sourceBold" variant="warning">
                {investForm?.accredited?.prevent_error}
              </Alert>
            </Col>
          ) : null}
          <Col lg={6} className="inputCol">
            <InputFields
              label="Annual Income"
              type="text"
              inputType="currency"
              model=".annual_income"
              disabled={investForm?.prevent_income_change}
              value={investForm?.investor_questionnaire?.annual_income}
              minValue={
                useIncome ? investForm?.accredited_income_minimum : null
              }
              minValueMessage={
                "The annual income entered above indicates that you are not an accredited investor."
              }
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Net Worth"
              type="text"
              inputType="currency"
              model=".net_worth"
              disabled={investForm?.prevent_income_change}
              value={investForm?.investor_questionnaire?.net_worth}
              minValue={
                useNetWorth ? investForm?.accredited_net_worth_minimum : null
              }
              minValueMessage={
                "The net worth entered above indicates that you are not an accredited investor."
              }
            />
          </Col>
          {!investForm?.amount_allowed && investForm?.accredited?.error && (
            <Col lg={12} className="inputCol">
              <Alert className="formAlert sourceBold" variant="warning">
                {investForm?.accredited?.error}
              </Alert>
              <InputFields
                label="How much do you wish to invest in this offering?"
                type="text"
                inputType="currency"
                model="investForm.investment_info.amount"
                value={investForm?.investment_info?.amount}
                tooltip={
                  !deal?.allow_fractional_investment ? (
                    <>
                      Amount will automatically adjust to match the price per
                      share of {currencyFormatter.format(deal?.unitPrice)}
                    </>
                  ) : null
                }
                onBlur={() => {
                  dispatch(
                    deal?.unitPrice &&
                      actions.change(
                        "investForm.investment_info.transactionUnits",
                        deal?.allow_fractional_investment
                          ? (
                              investForm?.investment_info?.amount?.replace(
                                /[^0-9.]/g,
                                ""
                              ) / deal?.unitPrice
                            )?.toFixed(2)
                          : Math.floor(
                              investForm?.investment_info?.amount?.replace(
                                /[^0-9.]/g,
                                ""
                              ) / deal?.unitPrice
                            )?.toFixed(0)
                      )
                  );
                  if (
                    !deal?.allow_fractional_investment &&
                    deal?.unitPrice &&
                    investForm?.investment_info?.amount
                  ) {
                    const amountInNumber = Number(
                      investForm.investment_info.amount?.replace(/[^0-9.]/g, "")
                    );
                    dispatch(
                      deal?.unitPrice &&
                        actions.change(
                          "investForm.investment_info.amount",
                          currencyFormatter.format(
                            amountInNumber - (amountInNumber % deal?.unitPrice)
                          )
                        )
                    );
                  }
                }}
                // onBlur={() =>
                //   dispatch(
                //     actions.change(
                //       "investForm.investment_info.transactionUnits",
                //       Math.floor(
                //         investForm?.investment_info?.amount?.replace(
                //           /[^0-9.]/g,
                //           ""
                //         ) / deal?.unitPrice
                //       ).toFixed(0)
                //     )
                //   )
                // }
              />
            </Col>
          )}
          <Col lg={12} className="inputCol">
            <InputFields
              label="Total amount in USD invested in Regulation Crowdfunding offerings in past 12 months:"
              type="text"
              inputType="currency"
              model=".usd_invested"
              tooltip="Individual investors are limited in the amounts they are allowed to invest in all Regulation Crowdfunding offerings over the course of a 12-month period. For more information please review SEC guidelines linked below."
            />
            <div style={{ padding: "5px 0" }} />
            <InputFields
              label={
                <b>
                  I certify that I have not exceeded my annual investment caps
                  as permitted{" "}
                  <a
                    className="checkboxLabelLink"
                    href="#/"
                    onClick={() => handleShow(true)}
                  >
                    by SEC rules
                  </a>
                  .
                </b>
              }
              type="checkbox"
              inputType="checkbox"
              model=".not_exceeded_annual_investment"
              // standardLabel={true}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <p className="formSubHeader sourceRegular">
              Substitute Form W-9 Statement
            </p>
            <label className="formInputsLabel">
              Under penalty of perjury, by accepting the agreement below I
              certify that I have provided my correct taxpayer identification
              number, and:
              {/* {!investForm?.investor_questionnaire?.us_citizen &&
              investForm?.showFormError ? (
                <p className="formInputError">Required*</p>
              ) : null} */}
            </label>
            {/* <Row>
              <Col lg={12}>
                <InputFields
                  label="I am a US citizen, US resident, or other US person."
                  type="radio"
                  inputType="radio"
                  value="Yes"
                  model=".us_citizen"
                />
              </Col>
              <Col lg={12}>
                <InputFields
                  label="I am not a US citizen, US resident, or other US person."
                  type="radio"
                  inputType="radio"
                  value="No"
                  model=".us_citizen"
                />
                {investForm?.investor_questionnaire?.us_citizen?.toUpperCase() ===
                "NO" ? (
                  <p className="formInputError">
                    We are not accepting non-U.S. citizens at this time.
                  </p>
                ) : null}
              </Col>
            </Row> */}
            <label className="formInputsLabel">
              {/* And: */}
              {!investForm?.investor_questionnaire?.withholding &&
              investForm?.showFormError ? (
                <p className="formInputError">Required*</p>
              ) : null}
            </label>
            <Row>
              <Col lg={12}>
                <InputFields
                  label="I am exempt from backup withholding."
                  type="radio"
                  inputType="radio"
                  value="Yes"
                  model=".withholding"
                />
              </Col>
              <Col lg={12}>
                <InputFields
                  label="I am subject to backup withholding. (Only check this option if you've been notified by the IRS that you are subject to backup withholding.)"
                  type="radio"
                  inputType="radio"
                  value="No"
                  model=".withholding"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(InvestorQuestionnaire);
