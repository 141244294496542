/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Row, Col, Button, Modal } from "react-bootstrap";
import queryString from "query-string";
import Close from "../../global/img/close.png";
import "react-dropzone-uploader/dist/styles.css";
import Login from "./login";
import Signup from "./signup";
import ForgotPassword from "./forgotpassword";
import Dropzone from "react-dropzone-uploader";

import "./style.css";

class LoginContainer extends Component {
  state = {
    isSignup: this.props.showSignup ? true : false,
    login: this.props.show ? true : false,
    forgotPassword: false,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const parsed = queryString.parse(window.location.search);
    const token = parsed?.token;
    if (token) {
      dispatch({ type: "VERIFY_PASSWORD_RESET_TOKEN", payload: token });
    }
  }

  checkExistingUser() {
    const { signup, dispatch } = this.props;
    dispatch({
      type: "CHECK_EXISTING_USER",
      payload: { email: signup?.email },
    });
  }

  handleSignup() {
    const { dispatch, signup } = this.props;
    this.checkExistingUser();
    if (!signup?.existing_user) {
      dispatch(actions.change("signup.showFormError", null));
      // handleClose();
      dispatch({ type: "SIGNUP" });
    }
  }

  handle2FA() {
    const { dispatch } = this.props;
    // handleClose();
    dispatch({
      type: "TWO_FACTOR",
    });
  }

  handleLogin() {
    const { dispatch, handleClose } = this.props;
    // handleClose();
    dispatch({
      type: "LOGIN",
      payload: { handleClose: () => handleClose() },
    });
  }

  handleSendPasswordReset() {
    this.props.dispatch({ type: "SEND_PASSWORD_RESET" });
  }

  handleChangePassword() {
    this.props.dispatch({ type: "PASSWORD_RESET" });
  }

  toggleSignupModal(isSignup, login, forgotPassword) {
    this.setState({
      isSignup,
      login,
      forgotPassword,
    });
  }

  addFiles(file) {
    const { dispatch } = this.props;
    let files = [];
    files.push(file);
    dispatch(actions.change("signup.file", files));
    // this.setState({ [type]: files });
  }

  removeFile(fileName) {
    const { signup, dispatch } = this.props;
    let files = [];
    files = signup?.file;
    files = files?.filter((x) => x.name !== fileName);
    dispatch(actions.change("signup.file", files));
    // this.setState({ [type]: files });
  }

  render() {
    const { isSignup, login, forgotPassword } = this.state;
    const { passwordReset, show, showSignup, handleClose, signup } = this.props;

    // if (userActions?.isLoading || passwordReset?.isLoading) {
    //   // return <Loading inline={true} />;
    //   return <Loading />;
    // }

    const changePassword = passwordReset?.verifyPasswordToken;

    const DocumentUploader = (type, subType) => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.addFiles(file, type, subType);
        } else if (status === "removed") {
          this.removeFile(file?.name, type, subType);
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/*"
          PreviewComponent={null}
          inputContent="Upload"
        />
      );
    };

    const passwordResetSuccess = passwordReset?.passwordReset;

    return (
      <Modal
        id={isSignup ? `investModalDialog` : "loginModalDialog"}
        show={
          passwordReset?.verifyPasswordToken ||
          passwordReset?.passwordReset ||
          show ||
          showSignup
        }
        onHide={handleClose}
        contentClassName={signup ? `investModalContent` : ""}
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="signupModalHeader">
          {isSignup ? (
            <section className="investModalHeaderSection">
              <Modal.Title className="investModalTitle sourceBold">
                Join Now
              </Modal.Title>
              <Button
                className="noStyleBtn modalCloseBtn"
                onClick={handleClose}
              >
                <img src={Close} alt="Close" className="modalClose" />
              </Button>
            </section>
          ) : null}
          {login && !changePassword && !passwordResetSuccess ? (
            <section className="loginHeaderSection">
              <Modal.Title className="loginModalTitle sourceBold">
                Login
              </Modal.Title>
              <Button
                className="noStyleBtn loginModalCloseBtn"
                onClick={handleClose}
              >
                <img src={Close} alt="Close" className="modalClose" />
              </Button>
            </section>
          ) : null}
          {forgotPassword || changePassword || passwordResetSuccess ? (
            <section className="loginHeaderSection">
              {forgotPassword || passwordResetSuccess ? (
                <>
                  <Modal.Title className="loginModalTitle sourceBold">
                    Forgot Password
                  </Modal.Title>
                  <Button
                    className="noStyleBtn loginModalCloseBtn"
                    onClick={handleClose}
                  >
                    <img src={Close} alt="Close" className="modalClose" />
                  </Button>
                </>
              ) : (
                <Modal.Title className="loginModalTitle sourceBold">
                  {/* Your password was successfully changed, return home to login. */}
                  Please change your password below
                </Modal.Title>
              )}
            </section>
          ) : null}
        </Modal.Header>
        <Modal.Body className="signupModalBody">
          {passwordResetSuccess ? (
            <div className="loginForm container-fluid">
              <Row>
                <Col lg={12} className="inputCol">
                  {/* <Alert className="formAlert sourceBold" variant="success"> */}
                  <h1 className="messageHeader sourceBold">
                    You have successfully changed your password, click the
                    button below to return home
                  </h1>
                  {/* </Alert> */}
                  <Button
                    className="signupButton generalButtonInverted"
                    href="/"
                  >
                    Home
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
          {isSignup && !passwordResetSuccess ? (
            <Signup
              handleSignup={() => this.handleSignup()}
              toggleSignupModal={(x, y, z) => this.toggleSignupModal(x, y, z)}
              handleClose={() => handleClose()}
              removeFile={(x) => this.removeFile(x)}
              DocumentUploader={DocumentUploader}
              checkExistingUser={() => this.checkExistingUser()}
            />
          ) : null}
          {login && !changePassword && !passwordResetSuccess ? (
            <Login
              handleLogin={() => this.handleLogin()}
              handle2FA={() => this.handle2FA()}
              toggleSignupModal={(x, y, z) => this.toggleSignupModal(x, y, z)}
              handleClose={() => handleClose()}
              changePassword={changePassword}
            />
          ) : null}
          {(forgotPassword || changePassword) && !passwordResetSuccess ? (
            <ForgotPassword
              handleSendPasswordReset={() => this.handleSendPasswordReset()}
              handleChangePassword={() => this.handleChangePassword()}
              toggleSignupModal={(x, y, z) => this.toggleSignupModal(x, y, z)}
              handleClose={() => handleClose()}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  passwordReset: state.passwordReset,
});

export default connect(mapStateToProps)(LoginContainer);
