/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { actions } from "react-redux-form";
import { Button, Modal } from "react-bootstrap";
import Footer from "../Footer";
import Loading from "../Loading";
// import playImg from "./img/play.png";
// import Cover from "./img/cover.png";
import Close from "../../global/img/close.png";
// import img1 from "./img/image1.png";
// import img2 from "./img/image2.png";
// import img3 from "./img/image3.png";
// import img4 from "./img/image4.png";
// import img5 from "./img/image5.png";
// import img6 from "./img/image6.png";
// import seen1 from "./img/seen1.png";
// import seen2 from "./img/seen2.png";
// import seen3 from "./img/seen3.png";
// import seen4 from "./img/seen4.png";
// import reuters from "./img/reuters.png";
// import cnn from "./img/cnn.png";
// import sky from "./img/sky.png";
// import bbc from "./img/bbc.png";
// import BlackArrowLeft from "./img/blackArrowLeft.png";
// import BlackArrowRight from "./img/blackArrowRight.png";
// import WhiteArrowLeft from "./img/whiteArrowLeft.png";
// import WhiteArrowRight from "./img/whiteArrowRight.png";
// import TestimonialImg1 from "./img/testimonialImg1.png";
// import CurrentOpportunities from "./currentOpportunities";
// import DealCards from "../DealCards";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";

function Home(props) {
  useEffect(() => {
    const { toggleMenu, isHome, isFunderr, isInvesterr } = props;
    if (!isHome && window.screen.width > 768 && (isInvesterr || isFunderr)) {
      toggleMenu();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [show, handleShow] = useState(false);
  const [showSubscription, handleSubscriptionShow] = useState(false);

  // const handleUpdatesSignup = () => {
  //   const { userActions, dispatch } = this.props;
  //   dispatch({
  //     type: "EMAIL_SUBSCRIPTIONS",
  //     payload: { email: userActions?.email },
  //   });
  // };

  const { userActions, allDeals, options } = props;

  if (userActions?.isLoading || allDeals?.isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Modal
        id="loginModalDialog"
        show={show || showSubscription}
        onHide={() => {
          handleShow();
          handleSubscriptionShow();
        }}
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="signupModalHeader">
          <section className="loginHeaderSection">
            <Modal.Title className="loginModalTitle sourceBold">
              {show ? "How it Works" : ""}
              {showSubscription ? "Signup for Vesterr News and Updates" : ""}
            </Modal.Title>
            <Button
              className="noStyleBtn loginModalCloseBtn"
              onClick={() => {
                handleShow();
                handleSubscriptionShow();
              }}
            >
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "60px" }}>
          {show ? (
            <iframe
              width="100%"
              height="315"
              src={options?.videos?.length && options?.videos[0]?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe>
          ) : null}
          {showSubscription ? (
            <div id="mc_embed_signup">
              <form
                action="https://vesterr.us12.list-manage.com/subscribe/post?u=16ba686aaf711cd276009861c&amp;id=f45c38a301&amp;f_id=00176ce0f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  {/* <h2>Signup for Vesterr News and Updates</h2> */}
                  <div className="indicates-required">
                    <span className="asterisk">*</span> indicates required
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL" className="formInputsLabel">
                      Email Address <span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      // value=""
                      name="EMAIL"
                      className="formInputs"
                      id="mce-EMAIL"
                      required={true}
                    />
                    <span
                      id="mce-EMAIL-HELPERTEXT"
                      className="helper_text"
                    ></span>
                  </div>
                  <div className="mc-field-group">
                    <label htmlFor="mce-FNAME" className="formInputsLabel">
                      First Name{" "}
                    </label>
                    <input
                      type="text"
                      // value=""
                      name="FNAME"
                      className="formInputs"
                      id="mce-FNAME"
                    />
                    <span
                      id="mce-FNAME-HELPERTEXT"
                      className="helper_text"
                    ></span>
                  </div>
                  <div id="mce-responses" className="clear">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_e9b75d67d6845a496d676fa52_94cc33549b"
                      tabIndex="-1"
                      // value=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
              </form>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
      <section className="coverSection coverSectionDesktop">
        <div style={{ backgroundColor: "rgba(0,0,0,1 )" }}>
          <div className="coverDiv">
            <div className="coverWhiteDiv mainContainer">
              <div className="coverTextDiv">
                <h1 className="homeCoverHeader">
                  A crowdfunding platform for commercial real estate.
                </h1>
                <h1 className="homeCoverText sourceRegular">
                  We are putting access to real estate opportunities at the
                  fingertips of commercial real estate investors, owners, and
                  developers.
                </h1>
                {/* <h1 className="homeCoverText sourceRegular">
                    A crowdfunding platform
                    that is offering hotel investments by putting access to real
                    estate opportunities at the fingertips of investors and
                    innovative hotel owners and developers.
                  </h1> */}
                <div className="coverButtonDiv sourceRegular">
                  <Button
                    href="/investerr"
                    className="coverButton coverButton1 hoverDelay"
                    title="View Current Investment Securities"
                  >
                    INVESTERR
                    <br />
                    CLICK HERE TO INVEST
                  </Button>
                  <Button
                    href="/funderr"
                    className="coverButton coverButton2 hoverDelay"
                    title="View Current Investment Securities"
                  >
                    FUNDERR
                    <br />
                    CLICK HERE TO RAISE CAPITAL
                  </Button>
                  <Button
                    href="#/"
                    className="coverButton coverButton3 hoverDelay"
                    title="Subscribe to Newsletter"
                    onClick={handleSubscriptionShow}
                  >
                    Sign up for News and Updates
                  </Button>
                  {/* <Button
                      className="coverButton coverButton2 hoverDelay"
                      onClick={handleShow}
                    >
                      <img src={playImg} alt="Play" className="playImg" /> How
                      it works
                    </Button> */}
                  <a
                    href="#/"
                    className="coverHowItWorksLink hoverDelay"
                    onClick={handleShow}
                  >
                    How Vesterr works video >
                  </a>
                </div>
              </div>
              <p className="homeDisclaimerText">
                These types of investments are highly speculative, illiquid, and
                subject to risk of loss of the entire amount invested.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="updatesSection">
            <div className="updatesDiv">
              <h1 className="updatesHeader">
                Signup for Vesterr news and updates
              </h1>
              <br />
              <form className="updateInputDiv sourceRegular">
                <input
                  placeholder="Enter your email"
                  type="email"
                  className="updateInputField"
                  onChange={(v) =>
                    dispatch(
                      actions.change("userActions.email", v?.target?.value)
                    )
                  }
                />
                <button
                  className="updateInputButton"
                  onClick={() => this.handleUpdatesSignup()}
                  disabled={!userActions?.email}
                >
                  Submit
                </button>
              </form>
            </div>
          </section> */}
      <section className="coverSection coverSectionMobile">
        <div className="coverImgDiv" />
        <div className="coverTextDiv">
          <h1 className="homeCoverHeader homeCoverHeaderMobile">
            A crowdfunding platform for commercial real estate.
          </h1>
          <h1 className="homeCoverText sourceRegular">
            We are putting access to real estate opportunities at the fingertips
            of commercial real estate investors, owners, and developers.
          </h1>
          {/* <h1 className="homeCoverText sourceRegular">
              A crowdfunding platform that is
              offering hotel investments by putting access to real estate
              opportunities at the fingertips of investors and innovative hotel
              owners and developers.
            </h1> */}
          <div className="coverButtonDiv sourceRegular">
            <Button
              href="/investerr/howitworks"
              className="coverButton coverButton1 hoverDelay"
              title="View Current Investment Securities"
            >
              INVESTERR
              <br />
              CLICK HERE TO INVEST
            </Button>
            <Button
              href="/funderr/howitworks"
              className="coverButton coverButton2 hoverDelay"
              title="View Current Investment Securities"
            >
              FUNDERR
              <br />
              CLICK HERE TO RAISE CAPITAL
            </Button>
            {/* <Button
                      className="coverButton coverButton2 hoverDelay"
                      onClick={handleShow}
                    >
                      <img src={playImg} alt="Play" className="playImg" /> How
                      it works
                    </Button> */}
            <Button
              href="#/"
              className="coverButton coverButton3 hoverDelay"
              title="Subscribe to Newsletter"
              onClick={handleSubscriptionShow}
            >
              Sign up for News and Updates
            </Button>
            <a
              href="#/"
              className="coverHowItWorksLink hoverDelay"
              onClick={handleShow}
            >
              How Vesterr works video >
            </a>
            <p className="homeDisclaimerText">
              These types of investments are highly speculative, illiquid, and
              subject to risk of loss of the entire amount invested.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  allDeals: state.allDeals,
});

export default connect(mapStateToProps)(Home);
