/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Modal } from "react-bootstrap";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Footer from "../Footer";
import BioDavonne from "./img/bio_davonne.png";
import BioPhillip from "./img/bio_phillip.png";
import BioAnand from "./img/bio_anand.png";
import ln from "../../global/img/logos/purple/ln_inverted.png";
import Close from "../../global/img/closeWhite.png";
import MobileCoverImg from "./img/CoverPhoto.png";
import "./style.css";

function OurStory(props) {
  const [bioSelection, handleBioSelection] = useState(null);

  return (
    <React.Fragment>
      <Modal
        id="loginModalDialog"
        show={bioSelection}
        onHide={() => handleBioSelection(null)}
        contentClassName=""
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="bioModalHeader">
          <section className="investModalHeaderSection">
            <Modal.Title className="investModalTitle sourceBold">
              {bioSelection?.name}
              <span className="bioModalTitle">{bioSelection?.title}</span>
              <a
                href={bioSelection?.linkedIn}
                title="LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ln} alt="LinkedIn" className="bioLinkedInImg" />
              </a>
            </Modal.Title>
            <Button
              className="noStyleBtn modalCloseBtn"
              onClick={() => handleBioSelection(null)}
            >
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body className="bioModalBody" style={{ display: "flex" }}>
          <>
            <section className="investFormSection">
              <Row>
                <Col lg={12} className="inputCol">
                  <hr className="bioHR" />
                  {bioSelection?.id === 1 ? (
                    <p>
                      Travel trailblazer and hospitality enthusiast Davonne
                      Reaves, is an award-winning hotel consultant and asset
                      manager, who on her own path to hotel ownership, is
                      fiercely dedicated to empowering underrepresented
                      communities on opportunities unlocked through investment
                      in the hospitality industry. As founder and chief
                      hospitality strategist behind Atlanta-based hospitality
                      firm, The Vonne Group and CEO of Vesterr, Reaves
                      capitalizes on her more than 14 years of experience in the
                      lodging industry, to teach people how to become successful
                      hotel investors and owners. With a passion for people and
                      pineapples —the premiere symbol for the hospitality
                      industry — Reaves makes hotel ownership real and relatable
                      through educational training and programmatic resources
                      that spotlight each step in the hotel acquisition process,
                      leaning into sustainable success strategies. Through The
                      Vonne Group's signature system, Roadmap to Hotel
                      Ownership, Reaves has educated over 3,000 people and
                      transformed the lives of individuals across the U.S.,
                      helping them attain hotel ownership and diverse markets.
                      Over the years, Reaves held positions across the
                      hospitality industry, and throughout her career, she
                      managed portfolios totaling over $1 billion in hotel
                      assets. A proven leader and trusted advisor, Reaves has
                      been recognized by organizations like The International
                      Society of Hospitality Consultants, Hotel Management
                      Magazine, and Hilton Hotels & Resorts. As a Philadelphia
                      native with deep roots in the Atlanta community, Reaves
                      remains engaged with her alma mater Georgia State
                      University.
                    </p>
                  ) : null}
                  {bioSelection?.id === 2 ? (
                    <p>
                      Anand Patel is the chief technology officer of Vesterr. As
                      the CTO, Anand brings over 12 years of experience for a
                      unique blend of branding, digital marketing, mobile
                      application design and extensive experience working with
                      major brands in the hotel and hospitality sector.
                      <br />
                      <br />
                      Mr. Patel is also the founder of the digital agency
                      Myriann, which Anand has helped his lodging and
                      hospitality real estate clients transform bold ideas into
                      innovative technology that expands their digital footprint
                      and enhances their audiences' lives. Anand is doing the
                      same for Vesterr.
                    </p>
                  ) : null}
                </Col>
              </Row>
            </section>
          </>
        </Modal.Body>
      </Modal>
      <GeneralCoverHeader header="Our Story" />
      <section className="aboutHeaderSection">
        <div className="aboutCoverDiv mainContainer aboutCoverDivDesktop">
          <p className="aboutCoverText">
            Commercial real estate has always been an alternative way to invest.
            But up until now, it's been mostly limited to those who are already
            experienced in the field. That's all changed with Vesterr, a
            crowdfunding platform that connects accredited and non-accredited
            investors to commercial real estate opportunities.
            <br />
            <br />
            With Vesterr, you can invest in commercial real estate securities,
            and we offer a wide variety of investments within the commercial
            real estate realm including hotels, multi-family, retail space and
            more. Whether you're an owner or developer or an experienced
            investor, we can assist to make it atainable for you to get funding
            and add commercial real estate assets to your portfolio
            <br />
            <br />
            Vesterr was founded as a platform to connect investors with owners
            to raise capital. Our mission is simple: help owners grow their real
            estate portfolio by giving them access to the funds they need while
            providing investors opportunities to invest in securities.
          </p>
        </div>
        <div className="aboutCoverDivMobile">
          <img
            src={MobileCoverImg}
            alt="Cover"
            className="aboutCoverImgMobile"
          />
          <p className="aboutCoverText">
            Commercial real estate has always been an alternative way to invest.
            But up until now, it's been mostly limited to those who are already
            experienced in the field. That's all changed with Vesterr, a
            crowdfunding platform that connects accredited and non-accredited
            investors to commercial real estate opportunities.
            <br />
            <br />
            With Vesterr, you can invest in commercial real estate securities,
            and we offer a wide variety of investments within the commercial
            real estate realm including hotels, multi-family, retail space and
            more. Whether you're an owner or developer or an experienced
            investor, we can assist to make it atainable for you to get funding
            and add commercial real estate assets to your portfolio
            <br />
            <br />
            Vesterr was founded as a platform to connect investors with owners
            to raise capital. Our mission is simple: help owners grow their real
            estate portfolio by giving them access to the funds they need while
            providing investors opportunities to invest in securities.
          </p>
        </div>
        <div className="aboutHeaderDiv mainContainer">
          <h1 className="aboutHeader sourceBold">Meet the Team</h1>
          <Row className="aboutBioRow sourceRegular">
            <Col lg={4} className="aboutBioCol">
              <div
                className="aboutTeamDiv hoverDelay"
                onClick={() =>
                  handleBioSelection({
                    id: 1,
                    name: "Davonne Reaves",
                    title: "Founder & CEO",
                    linkedIn: "https://www.linkedin.com/in/davonnereaves",
                  })
                }
              >
                <img
                  src={BioDavonne}
                  alt="Davonne Reaves"
                  className="aboutBioImg"
                />
                <p>
                  Davonne Reaves
                  <br />
                  Founder & CEO
                  <br />
                  <a
                    href="#/"
                    className="aboutBioLink sourceBold hoverDelay"
                    title="Bio Link"
                  >
                    BIO >
                  </a>
                </p>
              </div>
            </Col>
            <Col lg={4} className="aboutBioCol">
              <div
                className="aboutTeamDiv hoverDelay"
                onClick={() =>
                  handleBioSelection({
                    id: 2,
                    name: "Anand Patel",
                    title: "CTO",
                    linkedIn: "https://www.linkedin.com/in/anandmyriann",
                  })
                }
              >
                <img src={BioAnand} alt="Anand Patel" className="aboutBioImg" />
                <p>
                  Anand Patel
                  <br />
                  CTO
                  <br />
                  <a
                    href="#/"
                    className="aboutBioLink sourceBold hoverDelay"
                    title="Bio Link"
                  >
                    BIO >
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
  userActions: state.userActions,
});

export default connect(mapStateToProps)(OurStory);
