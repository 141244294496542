/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";

class GeneralCoverHeader extends Component {
  render() {
    const { header, text } = this.props;

    const hideAngle = true;

    const customHeaderSectionCSS = {
      "-webkit-clip-path": `polygon(
        0% 100%,
        100% 100%,
        70% 100%,
        calc(100% - 600px) 0%,
        0% 0%
      )`,
      "clip-path": `polygon(
        0% 100%,
        100% 100%,
        70% 100%,
        calc(100% - 600px) 0%,
        0% 0%
      )`,
      width: "75%",
      height: !header && "200px",
    };

    return (
      <React.Fragment>
        {/* Desktop Section */}
        <section className="generalCoverSection generalCoverSectionDesktop">
          <div style={{ backgroundColor: "rgba(0,0,0,1 )" }}>
            <div className="generalCoverDiv">
              <div
                className="generalCoverWhiteDiv mainContainer"
                style={
                  !hideAngle
                    ? customHeaderSectionCSS
                    : { height: !header && "200px" }
                }
                // style={{ height: !header && "200px" }}
              >
                {header && (
                  <div
                    className="generalCoverTextDiv"
                    style={{
                      marginBottom: !hideAngle ? "90px" : "30px",
                      width: "100%",
                    }}
                  >
                    <h1 className="generalCoverHeader sourceBold">{header}</h1>
                    {text && (
                      <p className="generalCoverText sourceRegular">{text}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* Mobile Section */}
        <section className="generalCoverSection generalCoverSectionMobile">
          <div className="generalCoverImgDivMobile" />
          {header && (
            <div className="generalCoverTextDiv">
              <h1 className="generalCoverHeader sourceBold">{header}</h1>
              {text && <p className="generalCoverText sourceRegular">{text}</p>}
            </div>
          )}
        </section>
        {/*
        <section className="coverSection coverSectionMobile">
          <div className="coverImgDiv" />
          <div className="coverTextDiv">
            <h1 className="coverText sourceRegular">
              Capital and Collaborations.
            </h1>
            <div className="coverButtonDiv sourceRegular">
              <Button
                href="/currentinvestmentsecurities"
                className="coverButton coverButton1 hoverDelay"
                title="View Current Investments"
              >
                View Current Investments
                Invest in hotels and
                    <br />
                    hospitality investments.
              </Button>
              <Button className="coverButton coverButton2 hoverDelay">
                <img src={playImg} alt="Play" className="playImg" /> How it
                works
              </Button>
            </div>
          </div>
        </section> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
});

export default connect(mapStateToProps)(GeneralCoverHeader);
