/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import CompleteImg from "../../img/complete.png";
import { Row, Col, Button } from "react-bootstrap";
// import parse from "html-react-parser";

class FundTransfer extends Component {
  componentDidMount() {
    const { dispatch, investForm } = this.props;
    if (
      !investForm?.subscription_agreement_sent &&
      investForm?.offeringId &&
      investForm?.accountId &&
      investForm?.tradeId
    ) {
      dispatch({
        type: "HANDLE_SUBSCRIPTION_AGREEMENT",
      });
    }
  }

  sendSubscriptionAgreement() {
    const { dispatch } = this.props;
    dispatch({
      type: "HANDLE_SUBSCRIPTION_AGREEMENT",
      payload: {
        resend: true,
      },
    });
  }

  render() {
    const { investForm, deal, dispatch, handleClose } = this.props;

    const wireInfo = deal?.wire_details;

    return (
      <>
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <img src={CompleteImg} alt="Complete" className="completeImg" />
            <h1 className="congratulationsHeader sourceBold">
              Congratulations!
            </h1>
            <p className="investorCompleteText sourceRegular">
              Your investment commitment is confirmed. We've just sent you an
              email with instructions on sending your funds and another one when
              they're received, so keep a close eye out for more exciting
              updates coming soon after that!
              <br />
              <br />
              <b className="investorCompleteSubscrInstr">
                Make sure you've signed the subscription agreement that was sent
                straight to your inbox.
              </b>
              <br />
              <a
                href="#/"
                onClick={() => this.sendSubscriptionAgreement()}
                className="investorCompleteSubscrInstrResendLink"
              >
                (Click here to resend)
              </a>
              <br />
              <br />
              If you have any questions or need further assistance in funding
              your commitment, please contact{" "}
              <a href="mailto:invest@vesterr.com" className="generalLink">
                invest@vesterr.com
              </a>
              .
              <br />
              <br />
              Sincerely,
              <br />
              The Vesterr Team
            </p>
            <hr />
            <br />
            {investForm?.investment_info?.transactionType?.toUpperCase() ===
            "WIRE" ? (
              <>
                <h1 className="sourceBold text-center">Wire Instructions</h1>
                <p className="sourceRegular text-center">
                  Notice: Investor's bank account name must match investor's
                  name.
                  {/* Notice: Investor's bank account name must match investor's
                  name. Our escrow trustee, Prime Trust, cannot accept Western
                  Union wires. */}
                </p>
                <p>
                  <b>Wire Amount (USD):</b>{" "}
                  {investForm?.investment_info?.amount}
                  <br />
                  {/* {wireInfo ? ( */}
                  <>
                    <b>Wire To:</b>
                    <br />
                    TRISTATE CAPITAL BANK
                    <br />
                    ONE OXFORD CENTRE, SUITE 2700
                    <br />
                    301 GRANT STREET
                    <br />
                    PITTSBURGH, PA 15219
                    <br />
                    <b>ABA Routing Number:</b>
                    <br />
                    043019003
                    <br />
                    <b>Account Number:</b>
                    <br />
                    0220003339
                    <br />
                    <b>Custodian/Account Name:</b>
                    <br />
                    NORTH CAPITAL PRIVATE SECURITIES
                    <br />
                    623 E FORT UNION BLVD SUITE 101
                    <br />
                    MIDVALE, UT 84047
                    <br />
                    <b>FFCT:</b>
                    <br />
                    {deal?.name} -
                    {investForm?.secondary_investor?.firstName ||
                      investForm?.primary_investor?.firstName}{" "}
                    {investForm?.secondary_investor?.lastName ||
                      investForm?.primary_investor?.lastName}
                    <br />
                    <b>SWIFT ID:</b> TRTTUS33
                    <br />
                    {/* {parse(
                    wireInfo?.beneficiary_address?.replaceAll("\n", "<br/>")
                  )} */}
                  </>
                  {/* ) : (
                    <>
                      <b>
                        We will send the wire instructions separately in an
                        email
                      </b>
                      <br />
                    </>
                  )} */}
                  {/* {parse(wireInfo?.bank_address?.replaceAll("\n", "<br/>"))} */}
                  <br />
                  <b>Reference:</b> {investForm?.transactionId}
                </p>
              </>
            ) : (
              <>
                <h1 className="sourceBold text-center">
                  Your investment is on the way!
                </h1>
                <p className="sourceRegular text-center">
                  You don't need to take any further action. Please keep an eye
                  out for a confirmation email when it's been completed
                  successfully.
                </p>
              </>
            )}
            <Button
              className="doneButton"
              onClick={() => {
                dispatch(
                  actions.change(
                    "investFormCurrentModel.model",
                    "investForm.get_started"
                  )
                );
                dispatch(actions.change("investFormCurrentModel.page", 0));
                dispatch(
                  actions.change("investForm", {
                    offeringId: investForm?.offeringId,
                    deal_id: investForm?.deal_id,
                  })
                );
                handleClose();
              }}
            >
              Done
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(FundTransfer);
