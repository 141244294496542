/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Control, actions } from "react-redux-form";
import { Row, Col, Button, Modal, Accordion } from "react-bootstrap";
// import ReactPlayer from "react-player";
import Footer from "../Footer";
import Loading from "../Loading";
import InvestmentForms from "../InvestmentForms";
import GeneralCoverHeader from "../GeneralCoverHeader";
import InputFields from "../InputFields";
// import queryString from "query-string";
import parse from "html-react-parser";
import VesterrFillInImgBlack from "../../global/img/VesterrFillInImgBlack.png";
import SecLogo from "../../global/img/sec.png";
import Close from "../../global/img/close.png";
import Carousel from "react-multi-carousel";
import {
  currencyFormatter,
  nFormatter,
  nTextFormatter,
  formatDate,
} from "../utils";
import "react-multi-carousel/lib/styles.css";
import "./style.css";

function Deal(props) {
  let { slug } = useParams();
  useEffect(() => {
    const { dispatch } = props;
    if (slug) {
      dispatch({ type: "GET_DEAL", payload: { slug } });
      // dispatch(actions.change("investForm", {}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  // const [playing, handlePlay] = useState(false);
  const [showInvestmentModal, handleShowInvestmentModal] = useState(false);
  const [showQAModal, handleShowQAModal] = useState(false);
  const [showInterestModal, handleShowInterestModal] = useState(false);
  const [faqAnswerObj, populateAnswerObj] = useState(null);
  const [showVideoModal, handleShowVideoModal] = useState(null);
  const [showQAVideos, handleShowQAVideos] = useState(null);

  const { userActions, user, deal, showLogin, showSignup, dispatch } = props;

  if (userActions?.isLoading || deal?.isLoading) {
    return <Loading />;
  }
  // If using S3 for Document Storage
  // const FileLink = (file) => {
  //   if (!file) {
  //     return "";
  //   }
  //   const fileUrl = file?.file;
  //   const fileName = fileUrl?.replaceAll("+", " ")?.split("/");
  //   return (
  //     <>
  //       {/* <p className="filesName sourceBold">
  //         {decodeURI(fileName[fileName.length - 1])}
  //       </p> */}
  //       <a
  //         href={fileUrl}
  //         className="generalLink filesLink sourceBold hoverDelay"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         {/* Download */}
  //         {fileName[fileName?.length - 1]}
  //       </a>
  //     </>
  //   );
  // };

  // Using North Capital for Document Storage
  const FileLink = ({ file }) => {
    if (!file) {
      return "";
    }
    const fileUrl = file?.url;
    const fileName = file?.documentTitle;
    return (
      <>
        {/* <p className="filesName sourceBold">
          {decodeURI(fileName[fileName.length - 1])}
        </p> */}
        <a
          href={fileUrl}
          className="generalLink filesLink sourceBold hoverDelay"
          target="_blank"
          rel="noreferrer"
        >
          {/* Download */}
          {fileName}
        </a>
      </>
    );
  };

  const FileLinkUrl = ({ url }) => {
    const urlObj = [];
    for (const [key, value] of Object.entries(url)) {
      urlObj.push(
        <div key={key}>
          -{" "}
          <a
            href={value}
            className="generalLink filesLink sourceBold hoverDelay"
            target="_blank"
            rel="noreferrer"
          >
            {/* Download */}
            {key}
          </a>
          <br />
        </div>
      );
    }
    return urlObj;
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleInterest = () => {
    let showInterestObj = {
      ...userActions?.showInterest,
      deal_id: deal?.deal_id,
    };
    if (user?.login) {
      showInterestObj = {
        ...showInterestObj,
        user_id: user?.id,
        full_name: user?.full_name,
        email: user?.email,
      };
    }
    dispatch(
      actions.change("userActions.showInterest", {
        ...showInterestObj,
      })
    );
    dispatch({
      type: "HANDLE_OFFERING_INTEREST",
    });
    handleShowInterestModal(false);
  };

  const handleAnswerQuestion = (approve) => {
    dispatch(
      actions.change("userActions.qa", { ...faqAnswerObj, ...userActions.qa })
    );
    dispatch({
      type: "HANDLE_QUESTIONS",
      payload: { approve },
    });
    handleShowQAModal(false);
  };

  const handleAskQuestion = () => {
    const questionObj = {
      deal_id: deal?.deal_id,
      user_id: user?.id,
      question: deal?.question,
    };
    dispatch(actions.change("deal.ask_question", questionObj));
    dispatch({ type: "HANDLE_QUESTIONS", payload: { ask: true } });
  };

  const handleComments = (m, add) => {
    const { userActions } = props;
    const commentObj = {
      id: add ? null : m?.id,
      faqs_id: add ? m?.id : m?.faqs_id,
      user_id: user?.id,
      comment: userActions?.comment,
    };
    dispatch({ type: "HANDLE_COMMENTS", payload: { add, commentObj } });
  };

  if (!deal?.deal_id) {
    return (
      <React.Fragment>
        <GeneralCoverHeader />
        <section className="dealSection">
          <div className="dealDiv mainContainer">
            <h1 className="sourceBold text-center">
              This deal is not available.
            </h1>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {/* OFFERING Q&A SECTION */}
      <Modal
        id="loginModalDialog"
        show={showQAModal}
        onHide={() => {
          populateAnswerObj(null);
          dispatch(actions.change("userActions.qa", {}));
          handleShowQAModal(false);
        }}
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="signupModalHeader">
          <section className="loginHeaderSection">
            <Modal.Title className="loginModalTitle sourceBold">
              {!faqAnswerObj ? "Ask a Question" : "Answer the Question"}
            </Modal.Title>
            <Button
              className="noStyleBtn loginModalCloseBtn"
              onClick={() => {
                populateAnswerObj(null);
                dispatch(actions.change("userActions.qa", {}));
                handleShowQAModal(false);
              }}
            >
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body className="">
          <Form
            className="investFormModel container-fluid"
            model={!faqAnswerObj ? "deal" : "userActions.qa"}
            onSubmit={() =>
              !faqAnswerObj ? handleAskQuestion() : handleAnswerQuestion()
            }
          >
            {!faqAnswerObj ? (
              <Row>
                <Col lg={12} className="inputCol">
                  <InputFields
                    type="textarea"
                    inputType="textarea"
                    model=".question"
                    placeholder="Please enter your question here...."
                  />
                  <Control.button
                    model="deal.question"
                    className="generalSubmitButtonInverted"
                    style={{ marginTop: "30px" }}
                    type="submit"
                    disabled={{
                      valid: false,
                    }}
                  >
                    Submit
                  </Control.button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={12} className="inputCol">
                  <InputFields
                    type="textarea"
                    inputType="textarea"
                    model=".answer"
                    placeholder="Please enter your answer here...."
                  />
                  <Control.button
                    model="userActions.qa"
                    className="generalSubmitButtonInverted"
                    style={{ marginTop: "30px" }}
                    type="submit"
                    disabled={{
                      valid: false,
                    }}
                  >
                    Submit
                  </Control.button>
                </Col>
              </Row>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {/* OFFERING INTEREST SECTION */}
      <Modal
        id="loginModalDialog"
        show={showInterestModal}
        onHide={() => {
          dispatch(actions.change("userActions.showInterest", {}));
          handleShowInterestModal(false);
        }}
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="signupModalHeader">
          <section className="loginHeaderSection">
            <Modal.Title className="loginModalTitle sourceBold">
              {deal?.name}
            </Modal.Title>
            <Button
              className="noStyleBtn loginModalCloseBtn"
              onClick={() => {
                dispatch(actions.change("userActions.showInterest", {}));
                handleShowInterestModal(false);
              }}
            >
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body className="">
          <Form
            className="investFormModel container-fluid"
            model={"userActions.showInterest"}
            onSubmit={() => handleInterest()}
          >
            <Row className="inputRow">
              <Col lg={12} className="inputCol">
                <label className="formInputsLabel">
                  Are you an "accredited" investor (meaning do you earn over
                  $200,000 per year, have a net worth of $1m or more, or are an
                  institutional investor)?
                  {/* <img
                src={TooltipIcon}
                alt="Tooltip"
                className="tooltipIcon"
                onClick={() => handleShow()}
              /> */}
                  {!userActions?.showInterest?.accredited_investor ? (
                    <p className="formInputError">Required*</p>
                  ) : null}
                </label>
                <Row className="inputRow" style={{ paddingBottom: "15px" }}>
                  <Col lg={1}>
                    <InputFields
                      label="Yes"
                      type="radio"
                      inputType="radio"
                      value="1"
                      model=".accredited_investor"
                    />
                  </Col>
                  <Col lg={1}>
                    <InputFields
                      label="No"
                      type="radio"
                      inputType="radio"
                      value="0"
                      model=".accredited_investor"
                    />
                  </Col>
                  <br />
                </Row>
                {!user?.login ? (
                  <Row className="inputRow">
                    <Col lg={6} className="inputCol">
                      <InputFields
                        label="Your full name"
                        type="text"
                        inputType="text"
                        model=".full_name"
                        tooltip="The name of the human filling out this form."
                      />
                    </Col>
                    <Col lg={6} className="inputCol">
                      <InputFields
                        label="Email"
                        type="email"
                        inputType="text"
                        model=".email"
                      />
                    </Col>
                  </Row>
                ) : null}
                <Control.button
                  model="userActions.showInterest"
                  className="generalSubmitButtonInverted"
                  style={{ marginTop: "30px" }}
                  type="submit"
                  disabled={{
                    valid: false,
                  }}
                >
                  Submit
                </Control.button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* OFFERING VIDEO SECTION */}
      <Modal
        id="loginModalDialog"
        show={showVideoModal}
        onHide={() => {
          handleShowVideoModal(false);
          handleShowQAVideos(null);
        }}
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="signupModalHeader">
          <section className="loginHeaderSection">
            <Modal.Title className="loginModalTitle sourceBold">
              {!showQAVideos ? (
                <>Offering Video{deal?.video_urls?.length > 1 ? "(s)" : ""}</>
              ) : null}
              {showQAVideos === "QA" ? (
                <>Q&A Video{deal?.qa_video_urls?.length > 1 ? "(s)" : ""}</>
              ) : null}
              {showQAVideos === "FUNDERR" ? (
                <>
                  Meet the Funderr Video
                  {deal?.funderr_video_urls?.length > 1 ? "(s)" : ""}
                </>
              ) : null}
            </Modal.Title>
            <Button
              className="noStyleBtn loginModalCloseBtn"
              onClick={() => {
                handleShowVideoModal(false);
                handleShowQAVideos(null);
              }}
            >
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: "30px" }}>
          {!showQAVideos
            ? deal?.video_urls?.map((url, i) => (
                <iframe
                  key={i}
                  width="100%"
                  height="315"
                  style={{ paddingBottom: "30px" }}
                  src={url}
                  title="Video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              ))
            : null}
          {showQAVideos === "QA"
            ? deal?.qa_video_urls?.map((url, i) => (
                <iframe
                  key={i}
                  width="100%"
                  height="315"
                  style={{ paddingBottom: "30px" }}
                  src={url}
                  title="Video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              ))
            : null}
          {showQAVideos === "FUNDERR"
            ? deal?.funderr_video_urls?.map((url, i) => (
                <iframe
                  key={i}
                  width="100%"
                  height="315"
                  style={{ paddingBottom: "30px" }}
                  src={url}
                  title="Video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                ></iframe>
              ))
            : null}
        </Modal.Body>
      </Modal>
      <InvestmentForms
        show={showInvestmentModal}
        handleClose={() => handleShowInvestmentModal(!showInvestmentModal)}
      />
      <GeneralCoverHeader />
      <section className="dealSection">
        <div className="dealDiv mainContainer">
          <button
            className="offeringsBackButton sourceBold hoverDelay"
            title="Back Button"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <h1 className="dealHeader sourceBold">
            {deal?.name}{" "}
            {deal?.closes_soon || deal?.closed || deal?.complete ? (
              <span
                className={`${
                  deal.complete ? "dealStatus dealStatusFunded" : "dealStatus"
                }`}
              >
                {deal?.closed
                  ? deal?.complete
                    ? "FUNDED"
                    : "CLOSED"
                  : "CLOSING SOON"}
              </span>
            ) : null}
          </h1>
          {deal?.demo_deal ? <p>Testing the Waters Phase</p> : null}
          <p className="dealLocation sourceRegular">{deal?.location}</p>
          <Row className="dealRow">
            <Col lg={8} className="dealCol dealCarouselCol">
              <Carousel
                arrows={false}
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                slidesToSlide={1}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="dealCarouselDotClass"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={props.deviceType}
                // dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {/* <div className="viewDealImgDiv">
                  <ReactPlayer
                    url={video_urls}
                    width="100%"
                    height="100%"
                    controls={true}
                    onPlay={() => handlePlay(true)}
                    onPause={() => handlePlay(false)}
                  />
                </div> */}
                {deal?.images?.length ? (
                  deal?.images?.map((image, i) => (
                    <div key={i} className="dealImgDiv">
                      <img src={image} alt="Portfolio" className="dealImg" />
                    </div>
                  ))
                ) : (
                  <div className="dealImgDiv">
                    <img
                      src={VesterrFillInImgBlack}
                      alt="Portfolio"
                      className="dealImg"
                    />
                  </div>
                )}
              </Carousel>
              <div className="dealSummaryDiv sourceRegular">
                {deal?.pitch_video?.length
                  ? deal?.pitch_video.map((video) => (
                      <iframe
                        width="100%"
                        height="315"
                        style={{ paddingBottom: "30px" }}
                        src={video}
                        title="Video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                      ></iframe>
                    ))
                  : null}
                {deal?.summary?.length
                  ? parse(deal?.summary)
                  : "Summary not available."}
                {deal?.summary_show_disclosures ? (
                  <p className="dealInvestmentDisabledDisclaimer">
                    (i) no money or other consideration is being solicited, and
                    if sent, will not be accepted; (ii) no sales will be made or
                    commitments to purchase accepted until the Form C is filed
                    with the SEC and only through an intermediary's platform;
                    and (iii) a prospective purchaser's indication of interest
                    is non-binding.
                  </p>
                ) : null}
              </div>
            </Col>
            <Col lg={4} className="dealCol dealInfoCol">
              <div className="dealInfoDiv">
                <div className="dealProgressLabelDiv">
                  <p className="dealProgressLabel dealProgressLabelLeft">
                    ${nFormatter(deal?.funds_invested, 1, true)}
                    <br />
                    <span className="dealProgressSubLabel">
                      {nTextFormatter(deal?.funds_invested)} Raised
                    </span>
                  </p>
                  <p className="dealProgressLabel dealProgressLabelRight">
                    ${nFormatter(deal?.targetAmount, 1, true)}
                    <br />
                    <span className="dealProgressSubLabel">
                      {nTextFormatter(deal?.targetAmount)} Raising
                    </span>
                  </p>
                </div>
                <div className="dealProgressBarBorder">
                  <div style={deal?.progress_bar} className="dealProgressBar" />
                </div>
                <Row className="dealsDetailsRow">
                  <Col lg={12} className="dealDetailsCol">
                    <div className="dealDetailsLabelDiv">
                      <p className="dealDetailsLabel dealDetailsLabelLeft">
                        {deal?.investors}
                        <br />
                        <span className="dealDetailsSubLabel">Investor(s)</span>
                      </p>
                      <p className="dealDetailsLabel dealDetailsLabelRight">
                        {
                          currencyFormatter
                            .format(deal?.unitPrice)
                            ?.split(".")[0]
                        }
                        <br />
                        <span className="dealDetailsSubLabel">
                          Price per Share
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} className="dealDetailsCol">
                    <p className="dealDetailsLabel dealDetailsLabelLeft">
                      {deal?.fund_type}
                      <br />
                      <span className="dealDetailsSubLabel">Offering</span>
                    </p>
                  </Col>
                  <Col lg={12} className="dealDetailsCol dealDetailsColLeft">
                    <div className="dealSecFilingsDiv">
                      {/* <a
                        href="#/"
                        className="dealSecFilingsHeader sourceBold hoverDelay"
                      >
                        SEC Filings
                      </a> */}
                      <p className="dealSecFilingsText sourceRegular">
                        Vesterr (Vesterr LLC) is hosting this Reg CF securities
                        offering by {deal?.legal_name}. View the official SEC
                        filing and all updates:
                      </p>
                      <div className="secDiv">
                        <img src={SecLogo} alt="SEC Logo" className="secLogo" />
                        <p
                          className="sourceBold"
                          style={{ margin: "0 0 0 15px" }}
                        >
                          Form C
                        </p>
                      </div>
                      <p
                        className="dealSecFilingsText sourceRegular"
                        style={{ marginTop: "15px" }}
                      >
                        SEC.gov
                        {deal?.company_documents?.length ? (
                          <>
                            <br />
                            <b>Company documents</b>
                          </>
                        ) : null}
                      </p>
                      {deal?.form_c ? <FileLinkUrl url={deal.form_c} /> : null}
                      {/* {deal?.company_documents?.map((d, i) => (
                        <div key={i}>{d}</div>
                      ))} */}
                      {deal?.company_documents?.length ? (
                        deal?.company_documents?.map((file, i) => (
                          <div key={i}>
                            {/* <div key={i} className="filesDiv"> */}
                            - <FileLink file={file} />
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>
                            {!user?.login && deal?.company_documents?.length
                              ? "Please login/signup to view documents"
                              : null}
                          </p>
                        </div>
                      )}
                      {user?.login && deal?.company_documents_url ? (
                        <FileLinkUrl url={deal?.company_documents_url} />
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={12} className="dealDetailsCol dealDetailsColLeft">
                    {!deal?.investment_disabled ? (
                      <>
                        <Button
                          className="generalButtonInverted"
                          onClick={() =>
                            !user?.login
                              ? showLogin(!showLogin)
                              : handleShowInvestmentModal(!showInvestmentModal)
                          }
                          disabled={deal?.closed && user?.login}
                          title="Invest Now"
                        >
                          {!user?.login
                            ? "Click here to invest."
                            : !deal?.closed
                            ? "Invest Now"
                            : "Closed"}
                        </Button>
                        <br />
                        <br />
                      </>
                    ) : null}
                    {deal?.investment_disabled ? (
                      <>
                        {!user?.login ? (
                          <>
                            <Button
                              className="generalButtonInverted"
                              onClick={() =>
                                // handleShowInterestModal(!showInterestModal)
                                showSignup(!showSignup)
                              }
                              title="Click here to learn more about the offering"
                            >
                              Click here to learn more about the offering
                            </Button>
                            <br />
                            <br />
                          </>
                        ) : (
                          <>
                            {!user?.expressedInterest?.length ||
                            !user?.expressedInterest?.find(
                              (e) => e?.deal_id !== deal?.id
                            ) ? (
                              <>
                                <Button
                                  className="generalButtonInverted"
                                  onClick={() =>
                                    // handleShowInterestModal(!showInterestModal)
                                    handleShowInterestModal(!showInterestModal)
                                  }
                                  title="Express interest in offering"
                                >
                                  Express interest in offering
                                </Button>
                                <br />
                                <br />
                              </>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : null}
                    {/* </Col>
                  <Col lg={12} className="dealDetailsCol dealDetailsColLeft"> */}
                    {deal?.funderr_video_urls?.length ? (
                      <>
                        <Button
                          className="generalButton"
                          onClick={() => {
                            handleShowVideoModal(!showVideoModal);
                            handleShowQAVideos("FUNDERR");
                          }}
                          title="Invest Now"
                        >
                          Meet the Funderr Video
                          {deal?.funderr_video_urls?.length > 1 ? "(s)" : ""}
                        </Button>
                        <br />
                        <br />
                      </>
                    ) : null}
                    {/* </Col>
                  <Col lg={12} className="dealDetailsCol dealDetailsColLeft"> */}
                    {user?.login && deal?.video_urls?.length ? (
                      <>
                        <Button
                          className="generalButton"
                          onClick={() => handleShowVideoModal(!showVideoModal)}
                          title="Invest Now"
                        >
                          View Investor Pitch Video Replay
                          {deal?.video_urls?.length > 1 ? "(s)" : ""}
                        </Button>
                        <br />
                        <br />
                      </>
                    ) : null}
                    {/* </Col>
                  <Col lg={12} className="dealDetailsCol dealDetailsColLeft"> */}
                    {user?.login && deal?.qa_video_urls?.length ? (
                      <>
                        <Button
                          className="generalButton"
                          onClick={() => {
                            handleShowVideoModal(!showVideoModal);
                            handleShowQAVideos("QA");
                          }}
                          title="Invest Now"
                        >
                          View Q&A Video
                          {deal?.qa_video_urls?.length > 1 ? "(s)" : ""}
                        </Button>
                        <br />
                        <br />
                      </>
                    ) : null}
                  </Col>
                  {/* {user?.login && deal?.documents?.length && (
                    <Col lg={12} className="filesCol">
                      <h1 className="filesHeader sourceBold">Documents</h1>
                      <hr />
                      {deal?.documents?.map((file, i) => (
                        <div key={i} className="filesDiv">
                          <FileLink file={file} />
                        </div>
                      ))}
                    </Col>
                  )} */}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="qaSection">
        <div className="qaDiv mainContainer">
          <Row className="askRow">
            <Col lg={6}>
              <h1 className="qaHeader sourceBold">Investor FAQs</h1>
            </Col>
            {user?.login ? (
              <Col lg={6}>
                <div className="askButtonDiv">
                  <Button
                    className="askButton"
                    title="Ask a question"
                    onClick={() => handleShowQAModal(true)}
                  >
                    Ask a question
                  </Button>
                </div>
              </Col>
            ) : null}
          </Row>
          <div className="questionAnswerDiv">
            {deal?.faqs?.length
              ? deal?.faqs?.map((qa, i) => (
                  <Accordion key={i}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <span className="accordionHeader">
                          <p className="accordianAskerTitle">
                            <span className="accordianAskerTitleSpan">
                              {qa?.asker?.first_name}
                            </span>{" "}
                            asked a question:
                          </p>
                          {qa?.question}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="answeredByMainDiv">
                          <div className="answeredByDiv">
                            {qa?.answer ? (
                              <>
                                <p className="answeredBy">
                                  Answered by{" "}
                                  <span className="sourceBold answeredByModeratorName">
                                    {qa?.approving_user?.full_name}
                                  </span>
                                </p>
                                {deal?.issuer_agent_ids?.includes(
                                  qa?.approving_user?.id
                                ) ? (
                                  <p className="answeredByModerator">
                                    Issuer Rep
                                  </p>
                                ) : null}
                              </>
                            ) : (
                              <p className="answeredBy sourceRegular">
                                {deal?.owner_ids?.includes(user?.id) ? (
                                  <>
                                    Not answered,{" "}
                                    <a
                                      href="#/"
                                      title="Answer Comment"
                                      className="generalLink answerQuestion"
                                      onClick={() => {
                                        populateAnswerObj({
                                          id: qa?.id,
                                          user_id: qa?.user_id,
                                          deal_id: qa?.deal_id,
                                        });
                                        handleShowQAModal(true);
                                      }}
                                    >
                                      Click here to answer the question
                                    </a>
                                  </>
                                ) : (
                                  "No answers available"
                                )}
                              </p>
                            )}
                          </div>
                          <p className="answeredByAnswer sourceRegular">
                            {qa?.answer}
                          </p>
                          {qa?.answer &&
                          (qa?.approval_user_id === user?.id ||
                            deal?.owner_ids?.includes(user?.id)) ? (
                            <a
                              href="#/"
                              title="Change Answer"
                              className="generalLink changeAnswer"
                              onClick={() => {
                                dispatch(actions.change("userActions.qa", qa));
                                populateAnswerObj({
                                  id: qa?.id,
                                  user_id: qa?.user_id,
                                  deal_id: qa?.deal_id,
                                });
                                handleShowQAModal(true);
                              }}
                            >
                              Change Answer
                            </a>
                          ) : null}
                        </div>
                        <p className="commentHeader">Comments</p>
                        {qa?.comments?.length ? (
                          qa?.comments?.map((c, i) => (
                            <div key={i}>
                              <hr />
                              <p className="comment sourceRegular">
                                {c?.comment}
                              </p>
                              <p className="commenter">
                                <span className="commentBy">By: </span>
                                {c?.first_name}
                                <span className="commentDate">
                                  {formatDate(c?.date_created)}
                                </span>
                                {deal?.issuer_agent_ids?.includes(
                                  qa?.approving_user?.id
                                ) && c?.user_id === qa?.approval_user_id ? (
                                  <span className="answeredByModerator">
                                    Issuer Rep
                                  </span>
                                ) : null}
                              </p>
                              {c?.user_id === user?.id ? (
                                <a
                                  href="#/"
                                  title="Delete Comment"
                                  className="commentDelete"
                                  onClick={() => handleComments(c)}
                                >
                                  Delete
                                </a>
                              ) : null}
                            </div>
                          ))
                        ) : (
                          <p className="answeredBy sourceRegular">
                            No comments available
                          </p>
                        )}
                        <br />
                        <Form
                          className="investFormModel"
                          model="userActions"
                          onClick={() => !user?.login && showLogin(!showLogin)}
                          onSubmit={() =>
                            !user?.login
                              ? showLogin(!showLogin)
                              : handleComments(qa, true)
                          }
                        >
                          <InputFields
                            type="textarea"
                            inputType="textarea"
                            model=".comment"
                            placeholder={
                              user?.login
                                ? "Please enter your comment here...."
                                : "Sign in to comment"
                            }
                            textAreaHeight="100px"
                            disabled={!user?.login}
                          />
                          <Control.button
                            model="userActions"
                            className="generalSubmitButtonInverted"
                            style={{ marginTop: "15px" }}
                            type="submit"
                            // disabled={{
                            //   valid: false,
                            // }}
                          >
                            {user?.login
                              ? "Submit Comment"
                              : "Sign in to comment"}
                          </Control.button>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))
              : null}
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  allDeals: state.allDeals,
  deal: state.deal,
});

export default connect(mapStateToProps)(Deal);
