/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Footer from "../Footer";
import "./style.css";
import ExamplePitchDeck from "../../global/ppt/Pitch Deck Example.pptx";

class HowItWorks extends Component {
  render() {
    const { isFunderr, isInvesterr } = this.props;

    return (
      <React.Fragment>
        <GeneralCoverHeader
          header={
            isFunderr
              ? "Submitting a Request to Raise Capital through Vesterr"
              : "How to Become an Investerr"
          }
        />
        <section className="howSection">
          <div className="mainContainer howDiv">
            {isInvesterr ? (
              <>
                <p className="howDisclaimer">
                  Vesterr offers a wide array of investments within commercial
                  real estate: hotels, daycares, land, multi-family, office,
                  retail, senior living, and student housing. whether you're an
                  owner, developer, or real estate investor (accredited or
                  non-accredited), vesterr makes it easy for owners and
                  developers to acquire funding for their projects; and for
                  investors to add assets to their commercial real estate
                  portfolio.
                  <br />
                  <br />
                  Check out our 5 step process below on how you can get started
                  and become an investerr on our platform.
                </p>
                <div className="howStepsDiv">
                  <div className="howStep">
                    <span className="howStepNumber">1</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        VIEW INVESTMENTS
                      </span>
                      <br />
                      To get started, click on our{" "}
                      <a
                        href="currentinvestmentsecurities"
                        title="current investment securities"
                        className="generalLink"
                      >
                        current investment securities
                      </a>{" "}
                      button below and review commercial projects that need to
                      raise capital through Investerrs.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">2</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        SELECT THE INVESTMENT YOU WANT TO FUND
                      </span>
                      <br />
                      See an investment you like? Read the information provided
                      by the Funderr such as: pitch deck, SEC filings, and
                      financials. Thoroughly research the project and do your
                      due diligence to make sure the investment right for you.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">3</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        CREATE AN ACCOUNT
                      </span>
                      <br />
                      You selected an investment you like, now it is time to
                      create an account; so you can fund the investment. Click
                      on the "create an account" button above to become an
                      Investerr on the platform. An Investerr account must be
                      established to fund an investment.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">4</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        FUND THE PROJECT
                      </span>
                      <br />
                      Now the fun part. Fund your Funderr's project! Each
                      project has a minimum investment, so confirm your
                      investment before sending funds. The following forms of
                      payment can be used to fund the project: retirement
                      account, wire transfer, ACH, credit card, or debit card.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">5</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">STANDBY</span>
                      <br />
                      After you fund the investment, you will then standby until
                      the deal closes. Once the deal is fully funded, you will
                      receive communication from the Funderr.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="howDisclaimer">
                  Vesterr simplifies the raising capital process for Funderrs,
                  by sourcing equity partners for your commercial real estate
                  project. Our platforms provides:
                  <br />
                  <ul className="howList">
                    <li>Access to capital.</li>
                    <li>A connection to a community of Investerrs.</li>
                    <li>
                      Technology that makes it easy to invest in your company
                      through a seamless online process.
                    </li>
                    <li>
                      A dashboard that simplifies managing your Investerrs and
                      the details of your project financing.
                    </li>
                    <li>
                      A way to develop a community of prospective Investerrs who
                      have shown interest, but not yet invested in your
                      offering.
                    </li>
                  </ul>
                </p>
                <p className="howHeader sourceBold">How it Works</p>
                <div className="howStepsDiv">
                  <div className="howStep">
                    <span className="howStepNumber">1</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        YOU MUST HAVE A CONTRACTED PROPERTY
                      </span>
                      <br />
                      In order to raise capital for your project on our
                      platform, you must have a property that is under contract
                      (offer has been accepted and binding) or possess site
                      control (you own or lease the land for the project). No
                      projects can be submitted without verification of
                      ownership.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">2</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        SUBMIT A PITCH DECK
                      </span>
                      <br />
                      Once the property has been verified, you will need to
                      submit a pitch deck for approval. A pitch deck consist of
                      your contact information, business plan, and fundraising
                      goals for the project. Click link above to submit your
                      deal. Please allow 2 business days for pitch deck review
                      and approval. Click{" "}
                      <a
                        className="generalLink"
                        href={ExamplePitchDeck}
                        title="Example Pitch Deck"
                      >
                        HERE
                      </a>{" "}
                      for an example
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">3</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">DUE DILIGENCE</span>
                      <br />
                      After the pitch deck has been approved, your offering
                      documents will be submitted for review by compliance. We
                      highly recommend that you hire a corporate lawyer and
                      certified public accountant to assist you with preparing
                      your offering documents.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">4</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">PROJECT LAUNCH</span>
                      <br />
                      Once your offering documents are approved, you are now
                      ready to tap into our network and go live on our site! Our
                      crowdfunding platform is a hub for all your
                      capital-raising efforts. We provide a single online
                      presence where you can drive all your potential Investerr
                      traffic.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">5</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">
                        CAPITAL CONNECTION
                      </span>
                      <br />
                      Our platform allows you to communicate directly with your
                      community of prospects and Investerrs, so that you can
                      connect to those who are interested in providing capital
                      for your project. Keep your community informed, build
                      momentum to raise funds, and grow a base of Investerrs for
                      your project.
                    </p>
                  </div>
                  <div className="howStep">
                    <span className="howStepNumber">6</span>
                    <p className="howStepText">
                      <span className="howStepTextHeader">FUNDED & CLOSED</span>
                      <br />
                      When you arrive at your deadline for raising capital or
                      you've successfully reached your fundraising goal; our
                      technology securely closes out your project and processes
                      all transactions so you can quickly access your funds.
                    </p>
                  </div>
                </div>
              </>
            )}
            <Button
              className="generalButtonInverted howButton"
              href={`currentinvestmentsecurities`}
            >
              Current Investment Opportunities
            </Button>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
});

export default connect(mapStateToProps)(HowItWorks);
