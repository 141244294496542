import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Home from "./components/Home";
import OurStory from "./components/OurStory";
import CurrentOfferings from "./components/CurrentOfferings";
import RaiseCapital from "./components/RaiseCapital";
import HowItWorks from "./components/HowItWorks";
import FAQ from "./components/FAQ";
import Disclosures from "./components/Disclosures";
import Education from "./components/Education";
// import InvestorResources from "./components/InvestorResources";
import Login from "./components/Login";
import Deal from "./components/Deal";
import CancelInvestment from "./components/CancelInvestment";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./global/css/globalStyle.css";
import Loading from "./components/Loading";
import AlertMessage from "./components/AlertMessage";
import NavbarHeader from "./components/Navbar";
import Events from "./components/Events";
import Admin from "./components/Admin";

function App(props) {
  const [showMenu, handleMenuShow] = useState(false);
  const [show, handleShow] = useState(false);
  const [showSignup, handleShowSignup] = useState(false);

  useEffect(() => {
    const { user, dispatch } = props;
    if (user?.login) {
      dispatch({ type: "REFRESH_USER" });
    }
    dispatch({ type: "GET_IP" });
    dispatch({ type: "GET_OPTIONS" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pathname = window.location.pathname?.toUpperCase();
  let isHome, isInvesterr, isFunderr, userRoute;
  if (pathname === "/") {
    isHome = true;
  } else if (pathname?.includes("FUNDERR")) {
    isFunderr = true;
    userRoute = pathname?.split("/")[1]?.toLowerCase();
  } else if (pathname?.includes("INVESTERR")) {
    isInvesterr = true;
    userRoute = pathname?.split("/")[1]?.toLowerCase();
  }

  const { ipAddr, userActions } = props;
  if (ipAddr?.isLoading || userActions?.isLoading) {
    // if (ipAddr?.isLoading || userActions?.isLoading || user?.isLoading) {
    return <Loading />;
  }

  if (showMenu) {
    return (
      <div style={{ minHeight: "100vh", display: "grid" }}>
        {(show || showSignup) && (
          <Login
            show={show}
            showSignup={showSignup}
            handleClose={() =>
              !showSignup ? handleShow(!show) : handleShowSignup(!showSignup)
            }
          />
        )}
        <NavbarHeader
          showMenu={showMenu}
          toggleMenu={() => handleMenuShow(!showMenu)}
          handleShow={() => handleShow(!show)}
          handleShowSignup={() => handleShowSignup(!showSignup)}
          isHome={isHome}
          isFunderr={isFunderr}
          isInvesterr={isInvesterr}
          userRoute={userRoute}
        />
      </div>
    );
  }

  return (
    <div className={!showMenu ? "" : "hideBodyScroll"}>
      {(show || showSignup) && (
        <Login
          show={show}
          showSignup={showSignup}
          handleClose={() =>
            !showSignup ? handleShow(!show) : handleShowSignup(!showSignup)
          }
        />
      )}
      <NavbarHeader
        showMenu={showMenu}
        toggleMenu={() => handleMenuShow(!showMenu)}
        handleShow={() => handleShow(!show)}
        handleShowSignup={() => handleShowSignup(!showSignup)}
        isHome={isHome}
        isFunderr={isFunderr}
        isInvesterr={isInvesterr}
        userRoute={userRoute}
      />
      <AlertMessage />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            // exact
            path="/:userRoute?"
            element={
              <Home
                isFunderr={isFunderr}
                isInvesterr={isInvesterr}
                toggleMenu={() => handleMenuShow(true)}
              />
            }
          />
          <Route exact path="/:userRoute?/ourstory" element={<OurStory />} />
          <Route
            exact
            path="/:userRoute?/currentinvestmentsecurities"
            element={<CurrentOfferings />}
          />
          {/* <Route exact path="/investorresources" element={<InvestorResources/> } /> */}
          <Route
            exact
            path="/funderr/raisecapital"
            element={<RaiseCapital />}
          />
          <Route
            exact
            path="/:userRoute?/howitworks"
            element={
              <HowItWorks isFunderr={isFunderr} isInvesterr={isInvesterr} />
            }
          />
          <Route exact path="/:userRoute?/education" element={<Education />} />
          <Route
            exact
            path="/:userRoute?/education/:id"
            element={<Education />}
          />
          <Route
            exact
            path="/:userRoute?/faq"
            element={<FAQ userRoute={userRoute} />}
          />
          <Route
            exact
            path="/:userRoute?/disclosures"
            element={<Disclosures />}
          />
          <Route
            exact
            path="/:userRoute?/deal/:slug"
            element={
              <Deal
                showLogin={() => handleShow(!show)}
                showSignup={() => handleShowSignup(!showSignup)}
              />
            }
          />
          <Route exact path="/:userRoute?/events" element={<Events />} />
          <Route exact path="/logout" element={<Home />} />
          <Route
            exact
            path="/login"
            element={<Login handleClose={() => handleShow(false)} />}
          />
          <Route
            exact
            path="/resetpassword"
            element={<Login handleClose={() => handleShow(false)} />}
          />
          <Route exact path="/cancel" element={<CancelInvestment />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
      </Router>
    </div>
  );
}

// export default App;

const mapStateToProps = (state) => ({
  checkToken: state.checkToken,
  ipAddr: state.ipAddr,
  user: state.user,
  userActions: state.userActions,
});

export default connect(mapStateToProps)(App);
