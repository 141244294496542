import { put, takeLeading, all, call, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import { actions } from "react-redux-form";
import {
  // ERRORS,
  // NO_ERRORS,
  GET_IP,
  GET_IP_SUCCESS,
  GET_IP_ERROR,
  AUDIT,
  AUDIT_SUCCESS,
  AUDIT_ERROR,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  CHECK_EXISTING_USER,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  TWO_FACTOR,
  TWO_FACTOR_SUCCESS,
  TWO_FACTOR_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_ERROR,
  VERIFY_PASSWORD_RESET_TOKEN,
  VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
  VERIFY_PASSWORD_RESET_TOKEN_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  GET_DEAL,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  GET_DEALS,
  GET_DEALS_SUCCESS,
  GET_DEALS_ERROR,
  CREATE_DEAL,
  CREATE_DEAL_SUCCESS,
  CREATE_DEAL_ERROR,
  EDIT_DEAL,
  EDIT_DEAL_SUCCESS,
  EDIT_DEAL_ERROR,
  DELETE_DEAL,
  DELETE_DEAL_SUCCESS,
  DELETE_DEAL_ERROR,
  HANDLE_INVESTMENT,
  HANDLE_INVESTMENT_SUCCESS,
  HANDLE_INVESTMENT_ERROR,
  HANDLE_PARTY,
  HANDLE_PARTY_SUCCESS,
  HANDLE_PARTY_ERROR,
  HANDLE_PARTY_KYC_AML_ERROR,
  HANDLE_ENTITY,
  HANDLE_ENTITY_SUCCESS,
  HANDLE_ENTITY_ERROR,
  // HANDLE_ACCOUNT_AND_LINK,
  HANDLE_ACCOUNT_AND_LINK_SUCCESS,
  HANDLE_ACCOUNT_AND_LINK_ERROR,
  HANDLE_PAYMENT,
  HANDLE_PAYMENT_SUCCESS,
  HANDLE_PAYMENT_ERROR,
  HANDLE_SIGNATURE,
  HANDLE_SIGNATURE_SUCCESS,
  HANDLE_SIGNATURE_ERROR,
  HANDLE_TRADE,
  HANDLE_TRADE_SUCCESS,
  HANDLE_TRADE_ERROR,
  HANDLE_SUBSCRIPTION_AGREEMENT,
  HANDLE_SUBSCRIPTION_AGREEMENT_SUCCESS,
  HANDLE_SUBSCRIPTION_AGREEMENT_ERROR,
  GENERATE_PLAID_URL,
  GENERATE_PLAID_URL_SUCCESS,
  GENERATE_PLAID_URL_ERROR,
  GENERATE_STRIPE_URL,
  GENERATE_STRIPE_URL_SUCCESS,
  GENERATE_STRIPE_URL_ERROR,
  UPDATE_PAYMENT_LINK,
  UPDATE_PAYMENT_LINK_SUCCESS,
  UPDATE_PAYMENT_LINK_ERROR,
  SEND_DETAILS,
  SEND_DETAILS_SUCCESS,
  SEND_DETAILS_ERROR,
  EMAIL_SUBSCRIPTIONS,
  EMAIL_SUBSCRIPTIONS_SUCCESS,
  EMAIL_SUBSCRIPTIONS_ERROR,
  PREVENT_INCOME_CHANGE,
  PREVENT_INCOME_CHANGE_SUCCESS,
  PREVENT_INCOME_CHANGE_ERROR,
  HANDLE_CANCEL_TOKEN,
  HANDLE_CANCEL_TOKEN_SUCCESS,
  HANDLE_CANCEL_TOKEN_ERROR,
  HANDLE_QUESTIONS,
  HANDLE_QUESTIONS_SUCCESS,
  HANDLE_QUESTIONS_ERROR,
  GET_QUESTIONS,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_ERROR,
  HANDLE_COMMENTS,
  HANDLE_COMMENTS_SUCCESS,
  HANDLE_COMMENTS_ERROR,
  HANDLE_OFFERING_INTEREST,
  HANDLE_OFFERING_INTEREST_SUCCESS,
  HANDLE_OFFERING_INTEREST_ERROR,
} from "./constants";
import { request, uploadFiles } from "../components/utils/index.js";
// import { pickBy, keys, result } from "lodash";

function* getIP() {
  let result;
  try {
    result = yield request({}, "/getIP", "GET");
    if (result?.data?.message) {
      yield put({
        type: GET_IP_SUCCESS,
        payload: { ip: result.data.IP },
      });
    } else {
      yield put({
        type: GET_IP_ERROR,
        payload: { message: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_IP_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* audit(action) {
  const user = yield select(selectors.user);
  const signup = yield select(selectors.signup);

  const { user_action, description, deal_id, document_id } = action.payload;

  const user_id = user?.id;
  const username = user?.email || signup?.email || "guest";

  let result;
  try {
    const data = {
      user_id,
      username,
      action: user_action,
      description,
      deal_id,
      document_id,
    };
    result = yield request(data, "/audit", "POST", user?.token);
    if (result?.data?.message) {
      yield put({
        type: AUDIT_SUCCESS,
        payload: { message: result?.data?.message },
      });
    } else {
      yield put({
        type: AUDIT_ERROR,
        payload: { error: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: AUDIT_ERROR,
      payload: { error: result?.data?.error },
    });
  }
}

function* refreshUser() {
  let user = yield select(selectors.user);
  let result;
  try {
    result = yield request(
      null,
      `/refreshUser/${user?.id}`,
      "GET",
      user?.token
    );
    if (result?.data?.message) {
      user = { ...user, ...result?.data?.user };
      yield put({
        type: REFRESH_USER_SUCCESS,
        payload: { message: result?.data?.message, user },
      });
    } else {
      yield put({
        type: REFRESH_USER_ERROR,
        payload: { error: result?.data?.error },
      });
      yield put({ type: LOGOUT });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: REFRESH_USER_ERROR,
      payload: { error: result?.data?.error },
    });
    yield put({ type: LOGOUT });
  }
}

function* getOptions() {
  try {
    const result = yield request({}, "/options", "GET");
    if (result?.data?.message) {
      yield put({
        type: GET_OPTIONS_SUCCESS,
        payload: result,
      });
    } else {
      yield put({
        type: GET_OPTIONS_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: GET_OPTIONS_ERROR,
    });
    console.log(error);
  }
}

function* checkExistingUser(action) {
  const { email } = action.payload;
  let result;
  try {
    result = yield request({}, `/checkexistinguser/${email}`, "GET");
    if (result?.data?.message) {
      yield put(actions.change("signup.existing_user", true));
    } else {
      yield put(actions.change("signup.existing_user", false));
    }
  } catch (error) {
    console.log(error);
  }
}

function* signup() {
  const signup = yield select(selectors.signup);
  const { first_name, last_name, email, file } = signup;

  let result;
  try {
    let data = {
      ...signup,
    };
    result = yield request(data, "/signup", "POST");
    if (result?.data?.message) {
      // Begin user document upload section
      const user = result?.data;
      const formData = new FormData();
      const type = "PERSON";
      if (file?.length) {
        formData.append("files", file[0]);
        formData.append("user_id", JSON.stringify(user?.id));
        formData.append("type", JSON.stringify(type));
        let fileEmail = email?.replace("@", "_");
        fileEmail = fileEmail?.replace(/[^\w\s]/gi, "_");
        const folder = `user_docs/user_${
          user?.id
        }/${type?.toLowerCase()}/${first_name}_${last_name}_${fileEmail}`;
        formData.append("folder", JSON.stringify(folder));
        yield uploadFiles(formData, "/upload/files", "POST", user?.token);
      }
      // End user document upload section
      data = { email: user?.email };
      // Send signup email
      yield request(data, "/mail/signup", "POST");
      yield put({
        type: SIGNUP_SUCCESS,
        payload: {
          ...result?.data,
        },
      });
      yield put({
        type: REFRESH_USER,
      });
    } else {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: result?.data?.error },
      });
    }
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  } catch (error) {
    console.log(error);
    if (result?.data?.error) {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  }
}

function* twoFactorAuth(action) {
  const user = yield select(selectors.user);
  let result;
  try {
    let data = { ...user };
    result = yield request(data, "/mail/2fa", "POST");
    if (result?.data?.message) {
      yield put({
        type: TWO_FACTOR_SUCCESS,
        payload: {
          access_code: true,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else if (result?.data?.error) {
      yield put({
        type: TWO_FACTOR_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: TWO_FACTOR_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* login(action) {
  const user = yield select(selectors.user);
  const { handleClose } = action.payload;
  // const deal = yield select(selectors.deal);
  let result;
  try {
    const data = {
      ...user,
    };
    result = yield request(data, "/login", "POST");
    if (result?.data?.message) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: {
          ...result?.data,
        },
      });
      handleClose();
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else if (result?.data?.error) {
      yield put({
        type: LOGIN_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: LOGIN_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* sendPasswordReset() {
  const passwordReset = yield select(selectors.passwordReset);
  let result;
  try {
    const data = {
      passwordReset,
    };
    result = yield request(data, "/mail/sendPasswordReset", "POST");
    if (result?.data?.message) {
      yield put({
        type: SEND_PASSWORD_RESET_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SEND_PASSWORD_RESET_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEND_PASSWORD_RESET_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* verifyPasswordReset(action) {
  const token = action.payload;
  let result;
  try {
    const data = {
      token,
    };
    result = yield request(data, "/verifyPasswordReset", "POST");
    if (result?.data?.message) {
      yield put({
        type: VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
        payload: { message: result?.data?.message, email: result.data.email },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* passwordReset() {
  const passwordReset = yield select(selectors.passwordReset);
  let result;
  try {
    const data = {
      passwordReset,
    };
    result = yield request(data, "/passwordReset", "POST");
    if (result?.data?.message) {
      yield put({
        type: PASSWORD_RESET_SUCCESS,
        payload: { message: result?.data?.message },
      });
      // yield put({
      //   type: AUDIT,
      //   payload: {
      //     user_action: `PASSWORD RESET: ${passwordReset?.email}`,
      //     description: ``,
      //   },
      // });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: PASSWORD_RESET_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: PASSWORD_RESET_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* logout() {
  try {
    yield request({}, "/logout", "GET");
    window.location.href = "/";
  } catch (error) {
    yield put({
      type: LOGOUT_ERROR,
      payload: "Unable to logout",
    });
  }
}

function* getDeal(action) {
  const { slug } = action.payload;
  const user = yield select(selectors.user);
  const investments = user?.investments;
  let result;
  try {
    const data = {
      investments,
      user,
    };
    result = yield request(data, `/investment/deals?slug=${slug}`, "POST");
    if (result?.data?.message) {
      yield put({
        type: GET_DEAL_SUCCESS,
        payload: {
          deal: result?.data?.data[0],
          investForm: result?.data?.investForm,
        },
      });
      // yield put({
      //   type: AUDIT,
      //   payload: {
      //     user_action: `VIEW DEAL`,
      //     deal_id: dealID,
      //   },
      // });
    } else {
      yield put({
        type: GET_DEAL_ERROR,
        payload: { message: "There was an error retrieving your deal" },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_DEAL_ERROR,
      payload: { message: "There was an error retrieving your deal" },
    });
  }
}

function* getAllDeals(action) {
  const user = yield select(selectors.user);
  const filter = action?.payload?.filter;
  let result;
  try {
    const data = {
      user,
      filter,
    };
    result = yield request(data, "/investment/deals", "POST");
    if (result?.data?.message) {
      yield put({
        type: GET_DEALS_SUCCESS,
        payload: {
          ...result?.data,
        },
      });
    } else {
      yield put({
        type: GET_DEALS_ERROR,
        payload: { message: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createDeals() {
  const user = yield select(selectors.user);
  const createDeal = yield select(selectors.createDeal);
  if (createDeal?.nda_required === "0") {
    createDeal.nda_agreement = null;
  }
  let formData = new FormData();
  let { images, files } = createDeal;
  let result;

  try {
    const data = {
      createDeal,
      user,
    };
    result = yield request(data, "/admin/deals/create", "POST", user?.token);
    let createDealSuccess = !!result.data.id;
    if (images.length && createDealSuccess) {
      for (const file of images) {
        formData.append("files", file);
      }
      formData.append("projectName", JSON.stringify(createDeal.name));
      formData.append("id", JSON.stringify(result.data.id));
      formData.append("folder", JSON.stringify("images"));
      formData.append("email", JSON.stringify(user.email));
      yield call(uploadFiles, formData, user);
    }

    if (files && createDealSuccess) {
      let uploadedArr = [];
      const typeArr = ["main", "sub"];
      for (let typeIndex = 0; typeIndex < typeArr.length; typeIndex++) {
        if (!files[typeArr[typeIndex]]) {
          if (files?.sub) {
            for (const [key] of Object.entries(files?.sub)) {
              yield call(request, "/admin/documenttypes", {
                method: "POST",
                cache: "no-cache",
                headers: {
                  "cache-control": "no-cache",
                  "Content-Type": "application/json",
                  "x-access-token": `${user.token}`,
                },
                body: JSON.stringify({
                  addDoc: true,
                  name: key?.split("/")[0],
                  deal_id: result.data.id,
                  isSubDoc: false,
                  mainDocName: null,
                }),
              });
            }
          }
          continue;
        }
        for (const [key, value] of Object.entries(files[typeArr[typeIndex]])) {
          formData = new FormData();
          let documentIds = [];
          const uniqueDocs = [];
          let documentTypeResult;
          for (let f = 0; f < value?.length; f++) {
            if (!uniqueDocs?.includes(key)) {
              uniqueDocs.push(key);
              documentTypeResult = yield call(request, "/admin/documenttypes", {
                method: "POST",
                cache: "no-cache",
                headers: {
                  "cache-control": "no-cache",
                  "Content-Type": "application/json",
                  "x-access-token": `${user.token}`,
                },
                body: JSON.stringify({
                  addDoc: true,
                  name: key,
                  deal_id: result.data.id,
                  isSubDoc: value[f]?.sub,
                  mainDocName: value[f]?.main,
                }),
              });
            }
            formData.append("files", value[f]);
            documentIds.push({
              documentId: documentTypeResult?.data?.DocumentTypesModel?.id,
              // documentId: value[f]?.documentId,
              // subDocumentId: value[f]?.subDocumentId,
              isSubDoc: value[f]?.sub,
              mainDocName: value[f]?.main,
            });
          }
          formData.append("projectName", JSON.stringify(createDeal.name));
          formData.append("id", JSON.stringify(result.data.id));
          formData.append("folder", JSON.stringify(key));
          formData.append("email", JSON.stringify(user.email));
          formData.append("documentIds", JSON.stringify(documentIds));
          if (uploadedArr?.length) {
            formData.append("dataArr", JSON.stringify(uploadedArr));
          }
          const uploadResult = yield call(uploadFiles, formData, user);
          if (uploadResult?.dataArr?.length) {
            uploadedArr = uploadResult?.dataArr;
          }
        }
      }
    }

    if (result?.data?.message) {
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      yield put({
        type: CREATE_DEAL_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: CREATE_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: CREATE_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* editDeals() {
  const user = yield select(selectors.user);
  let editDeal = yield select(selectors.editDeal);
  if (editDeal?.nda_required === "0") {
    editDeal.nda_agreement = null;
  }
  let formData = new FormData();
  let { images, files } = editDeal;
  let result;
  try {
    result = yield call(request, "/admin/deals/edit", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ editDeal, user }),
    });
    if (result?.data?.message) {
      if (images?.length) {
        for (const file of images) {
          formData.append("files", file);
        }
        formData.append("id", JSON.stringify(editDeal.id));
        formData.append("projectName", JSON.stringify(editDeal.name));
        formData.append("folder", JSON.stringify("images"));
        formData.append("email", JSON.stringify(user.email));
        formData.append("images_url", JSON.stringify(editDeal.images_url));
        yield call(uploadFiles, formData, user);
      }

      if (files) {
        const typeArr = ["main", "sub"];
        for (let typeIndex = 0; typeIndex < typeArr.length; typeIndex++) {
          if (!files[typeArr[typeIndex]]) {
            if (files?.sub) {
              for (const [key] of Object.entries(files?.sub)) {
                yield call(request, "/admin/documenttypes", {
                  method: "POST",
                  cache: "no-cache",
                  headers: {
                    "cache-control": "no-cache",
                    "Content-Type": "application/json",
                    "x-access-token": `${user.token}`,
                  },
                  body: JSON.stringify({
                    addDoc: true,
                    name: key?.split("/")[0],
                    deal_id: result.data.id,
                    isSubDoc: false,
                    mainDocName: null,
                  }),
                });
              }
            }
            continue;
          }
        }
      }
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      yield put({
        type: EDIT_DEAL_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: EDIT_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: EDIT_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* deleteDeals() {
  const user = yield select(selectors.user);
  const deleteDeal = yield select(selectors.deleteDeal);
  let result;
  try {
    result = yield call(request, "/admin/deals/delete", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ dealID: deleteDeal.id, user }),
    });
    if (result?.data?.message) {
      yield put({
        type: DELETE_DEAL_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
      yield put({
        type: GET_DEALS,
      });
    } else {
      yield put({
        type: DELETE_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: DELETE_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handlePayment(action) {
  const user = yield select(selectors.user);
  const deal = yield select(selectors.deal);
  let investForm = yield select(selectors.investForm);
  const ipAddr = yield select(selectors.ipAddr);
  const { ach } = action.payload;
  let result;
  try {
    if (ach) {
      const data = {
        investForm,
        ipAddr,
        dealName: deal?.issueName,
        full_name: user?.full_name,
      };
      result = yield request(
        data,
        "/investment/achaccount",
        "POST",
        user?.token
      );
    } else {
      const data = {
        investForm,
        ipAddr,
      };
      result = yield request(
        data,
        "/investment/creditcard",
        "POST",
        user?.token
      );
    }
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      const data = {
        user,
        investForm,
        deal,
      };
      result = yield request(
        data,
        "/mail/completeinvestment",
        "POST",
        user?.token
      );
      yield put({
        type: HANDLE_PAYMENT_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      return result;
      // yield put(actions.change("alertMessage.message", result?.data?.message));
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: HANDLE_PAYMENT_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: HANDLE_PAYMENT_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* handleTrade(action) {
  const user = yield select(selectors.user);
  let investForm = yield select(selectors.investForm);
  const ipAddr = yield select(selectors.ipAddr);
  const deal = yield select(selectors.deal);
  let result;
  try {
    const data = {
      investForm,
      ipAddr,
    };
    result = yield request(data, "/investment/trade", "POST", user?.token);
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      const { investment_info } = investForm;
      if (investment_info?.transactionType?.toUpperCase() === "WIRE") {
        const data = {
          user,
          investForm,
          deal,
        };
        result = yield request(
          data,
          "/mail/completeinvestment",
          "POST",
          user?.token
        );
      }
      yield put({
        type: HANDLE_TRADE_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      return result;
      // yield put(actions.change("alertMessage.message", result?.data?.message));
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: HANDLE_TRADE_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: HANDLE_TRADE_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* handleSubscriptionAgreement(action) {
  const user = yield select(selectors.user);
  let investForm = yield select(selectors.investForm);
  const ipAddr = yield select(selectors.ipAddr);
  let result;
  try {
    const data = {
      investForm,
    };
    result = yield request(
      data,
      "/investment/subscriptionagreement",
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      yield put({
        type: HANDLE_SUBSCRIPTION_AGREEMENT_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      if (action?.payload?.resend) {
        yield put(
          actions.change(
            "alertMessage.message",
            "Subscription Agreement has been resent"
          )
        );
      }
      return result;
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: HANDLE_SUBSCRIPTION_AGREEMENT_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: HANDLE_SUBSCRIPTION_AGREEMENT_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* updatePaymentLink(action) {
  const user = yield select(selectors.user);
  let investForm = yield select(selectors.investForm);
  const { update, type } = action?.payload;
  let result;
  try {
    const data = {
      investForm,
      update,
      type,
    };
    result = yield request(
      data,
      "/investment/updatepaymentlink",
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      yield put({
        type: UPDATE_PAYMENT_LINK_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      return result;
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: UPDATE_PAYMENT_LINK_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: UPDATE_PAYMENT_LINK_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* generatePlaidUrl(action) {
  const user = yield select(selectors.user);
  let investForm = yield select(selectors.investForm);
  const { update } = action?.payload;
  let result;
  try {
    const data = {
      investForm,
      update,
    };
    result = yield request(
      data,
      "/investment/generateplaidurl",
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      yield put({
        type: GENERATE_PLAID_URL_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      return result;
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: GENERATE_PLAID_URL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: GENERATE_PLAID_URL_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* generateStripeUrl(action) {
  const user = yield select(selectors.user);
  let investForm = yield select(selectors.investForm);
  const { update } = action?.payload;
  let result;
  try {
    const data = {
      investForm,
      update,
    };
    result = yield request(
      data,
      "/investment/generatestripeurl",
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      yield put({
        type: GENERATE_STRIPE_URL_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      return result;
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: GENERATE_STRIPE_URL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: GENERATE_STRIPE_URL_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* handleAccount(investForm, accountToLink, investorType) {
  const user = yield select(selectors.user);
  const ipAddr = yield select(selectors.ipAddr);
  let result;
  try {
    const data = {
      investForm,
      ipAddr,
      accountToLink,
      investorType,
    };
    result = yield request(data, "/investment/account", "POST", user?.token);
    if (result?.data?.message) {
      yield put({
        type: HANDLE_ACCOUNT_AND_LINK_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      return result;
      // yield put(actions.change("alertMessage.message", result?.data?.message));
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: HANDLE_ACCOUNT_AND_LINK_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("investForm.error", result?.data?.error));
      return result;
    }
  } catch (error) {
    yield put({
      type: HANDLE_ACCOUNT_AND_LINK_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
    return result;
  }
}

function* handleInvestment(action) {
  const user = yield select(selectors.user);
  let investForm = yield select(selectors.investForm);
  const ipAddr = yield select(selectors.ipAddr);

  let result;
  try {
    const data = {
      user,
      investForm,
      ipAddr,
    };
    result = yield request(data, "/investment/create", "POST", user?.token);
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      yield put({
        type: HANDLE_INVESTMENT_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm,
        },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
      yield put(actions.change("investForm.error", result?.data?.error));
    }
  } catch (error) {
    yield put({
      type: HANDLE_INVESTMENT_ERROR,
      payload: { message: result?.data?.error },
    });
    if (result?.data?.logout || error?.response?.data?.logout) {
      yield put(
        actions.change(
          "alertMessage.error",
          result?.data?.error || error?.response?.data?.error
        )
      );
    }
    yield put(actions.change("investForm.error", result?.data?.error));
  }
}

function* handleParty(action) {
  const user = yield select(selectors.user);
  const userActions = yield select(selectors.userActions);
  let investForm = yield select(selectors.investForm);
  const ipAddr = yield select(selectors.ipAddr);
  const isSecondaryInvestor = action?.payload?.isSecondaryInvestor;

  const isJoint =
    investForm?.investment_info?.type?.toUpperCase() === "TIC" ||
    investForm?.investment_info?.type?.toUpperCase() === "JTWROS" ||
    investForm?.investment_info?.type?.toUpperCase() === "JOINT";

  const isEntity =
    investForm?.investment_info?.type?.toUpperCase() === "ENTITY" ||
    investForm?.investment_info?.type?.toUpperCase() === "SEPIRA" ||
    investForm?.investment_info?.type?.toUpperCase() === "IRA" ||
    investForm?.investment_info?.type?.toUpperCase() === "ROTH";

  let files = [],
    file;
  if (!isSecondaryInvestor) {
    ({ file } = investForm?.primary_investor);
    if (file) {
      files.push(...file);
    }
  } else if (investForm?.secondary_investor) {
    ({ file } = investForm?.secondary_investor);
    if (file) {
      files.push(...file);
    }
  }

  let result;
  try {
    const data = {
      user,
      investForm,
      ipAddr,
      isSecondaryInvestor,
    };
    result = yield request(data, "/investment/party", "POST", user?.token);
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      for (let i = 0; i < files?.length; i++) {
        let type = investForm?.investment_info?.type?.toUpperCase();

        if (isJoint) {
          type = "JOINT";
        } else if (isEntity) {
          type = "ASSOCIATED";
        }

        const partyId =
          isJoint || isEntity
            ? investForm?.secondary_investor?.partyId
            : investForm?.primary_investor?.partyId;

        const document_id =
          isJoint || isEntity
            ? investForm?.secondary_investor?.document?.id
            : investForm?.primary_investor?.document?.id;

        // Begin user document delete section
        const deleteDocumentData = {
          investForm,
          partyId,
          documentId: userActions?.deleted_document_id,
          isParty: true,
          type,
          document_id,
        };
        if (userActions?.deleted_document_id) {
          const deleteDocument = yield request(
            deleteDocumentData,
            "/investment/delete/document",
            "POST",
            user?.token
          );
          if (deleteDocument?.data?.message) {
            ({ investForm } = deleteDocument?.data);
          }
        }
        // End user document delete section

        // Begin user document upload section
        const formData = new FormData();
        formData.append("files", files[i]);
        formData.append("type", JSON.stringify(type));
        formData.append("investForm", JSON.stringify(investForm));
        formData.append("isParty", JSON.stringify(true));
        formData.append("partyId", JSON.stringify(partyId));
        formData.append("createdIpAddress", JSON.stringify(ipAddr?.ip));
        const documentUpload = yield uploadFiles(
          formData,
          "/investment/upload/document",
          "POST",
          user?.token
        );
        if (documentUpload?.data?.message) {
          ({ investForm } = documentUpload?.data);
        }

        // End user document upload section
      }
      result = yield handleAccount(
        investForm,
        isEntity ? "SECONDARY" : "PRIMARY",
        "PARTY"
      );
      if (result?.data?.message) {
        ({ investForm } = result?.data);
        // if (!isSecondaryInvestor) {
        //   yield put(actions.change("investForm.primary_investor", file));
        // } else {
        //   yield put(actions.change("investForm.secondary_investor", file));
        // }
        yield put({
          type: HANDLE_PARTY_SUCCESS,
          payload: {
            message: result?.data?.message,
            investForm,
          },
        });
        // yield put(actions.change("alertMessage.message", result?.data?.message));
        // yield put({
        //   type: GET_DEALS,
        // });
      } else {
        yield put({
          type: HANDLE_PARTY_ERROR,
          payload: {
            message: result?.data?.error,
          },
        });
        yield put(actions.change("investForm.error", result?.data?.error));
      }
    } else {
      if (!result?.data?.kyc_aml_failed) {
        yield put({
          type: HANDLE_PARTY_ERROR,
          payload: { message: result?.data?.error },
        });
      } else {
        ({ investForm } = result?.data);
        yield put({
          type: HANDLE_PARTY_KYC_AML_ERROR,
          payload: {
            message: result?.data?.error,
            kyc_aml_failed: result?.data?.kyc_aml_failed,
            investForm,
          },
        });
      }
      // yield put(actions.change("alertMessage.error", result?.data?.error));
      yield put(actions.change("investForm.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);

    yield put({
      type: HANDLE_PARTY_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
  }
}

function* handleEntity() {
  const user = yield select(selectors.user);
  const userActions = yield select(selectors.userActions);
  let investForm = yield select(selectors.investForm);
  const ipAddr = yield select(selectors.ipAddr);
  let files = [],
    file;
  ({ file } = investForm?.primary_investor);
  if (file) {
    files.push(...file);
  }

  let result;
  try {
    const data = { user, investForm, ipAddr };
    result = yield request(data, "/investment/entity", "POST", user.token);
    if (result?.data?.message) {
      ({ investForm } = result?.data);
      for (let i = 0; i < files?.length; i++) {
        let type = investForm?.investment_info?.type?.toUpperCase();

        const partyId = investForm?.primary_investor?.partyId;
        const document_id = investForm?.primary_investor?.document?.id;

        // Begin user document delete section
        const deleteDocumentData = {
          investForm,
          partyId,
          documentId: userActions?.deleted_document_id,
          isParty: false,
          type,
          document_id,
        };
        if (userActions?.deleted_document_id) {
          const deleteDocument = yield request(
            deleteDocumentData,
            "/investment/delete/document",
            "POST",
            user?.token
          );
          if (deleteDocument?.data?.message) {
            ({ investForm } = deleteDocument?.data);
          }
        }
        // End user document delete section

        // Begin user document upload section
        const formData = new FormData();
        formData.append("files", files[i]);
        formData.append("type", JSON.stringify(type));
        formData.append("investForm", JSON.stringify(investForm));
        formData.append("isParty", JSON.stringify(false));
        formData.append(
          "partyId",
          JSON.stringify(investForm?.primary_investor?.partyId)
        );
        formData.append("createdIpAddress", JSON.stringify(ipAddr?.ip));
        const documentUpload = yield uploadFiles(
          formData,
          "/investment/upload/document",
          "POST",
          user?.token
        );
        if (documentUpload?.data?.message) {
          ({ investForm } = documentUpload?.data);
        }
        // End user document upload section
      }
      result = yield handleAccount(investForm, "PRIMARY", "ENTITY");
      if (result?.data?.message) {
        ({ investForm } = result?.data);
        // yield put(actions.change("investForm.primary_investor", file));
        yield put({
          type: HANDLE_ENTITY_SUCCESS,
          payload: {
            message: result?.data?.message,
            investForm,
          },
        });
        // yield put({
        //   type: GET_DEALS,
        // });
      } else {
        yield put({
          type: HANDLE_ENTITY_ERROR,
          payload: { message: result?.data?.error },
        });
        // yield put(actions.change("alertMessage.error", result?.data?.error));
        yield put(actions.change("investForm.error", result?.data?.error));
      }
    } else {
      yield put({
        type: HANDLE_ENTITY_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
      yield put(actions.change("investForm.error", result?.data?.error));
    }
  } catch (error) {
    yield put({
      type: HANDLE_ENTITY_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
  }
}

function* handleSignature() {
  const user = yield select(selectors.user);
  const investForm = yield select(selectors.investForm);
  let result;
  try {
    const data = {
      user,
      investForm,
    };
    result = yield request(data, "/investment/signature", "POST", user?.token);
    if (result?.data?.message) {
      yield put({
        type: HANDLE_SIGNATURE_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm: result?.data?.investForm,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: HANDLE_SIGNATURE_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
      yield put(actions.change("investForm.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_SIGNATURE_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
    yield put(actions.change("investForm.error", result?.data?.error));
  }
}

function* handlePreventIncomeChange() {
  const user = yield select(selectors.user);
  const investForm = yield select(selectors.investForm);
  const annual_income = investForm?.investor_questionnaire?.annual_income;
  const net_worth = investForm?.investor_questionnaire?.net_worth;
  let result;
  try {
    const data = {
      annual_income,
      net_worth,
    };
    result = yield request(
      data,
      `/investment/preventincomechange/${investForm?.id}`,
      "PATCH",
      user?.token
    );
    if (result?.data?.message) {
      yield put({
        type: PREVENT_INCOME_CHANGE_SUCCESS,
        payload: {
          message: result?.data?.message,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
      // yield put({
      //   type: GET_DEALS,
      // });
    } else {
      yield put({
        type: PREVENT_INCOME_CHANGE_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: PREVENT_INCOME_CHANGE_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleSendDetails() {
  const user = yield select(selectors.user);
  const sendDetails = yield select(selectors.sendDetails);
  const deckUploaded = sendDetails?.deck?.length;
  let result;
  try {
    const formData = new FormData();
    if (deckUploaded) {
      formData.append("files", sendDetails?.deck[0]);
    }
    formData.append("sendDetails", JSON.stringify(sendDetails));
    formData.append("user_id", JSON.stringify(user?.id || 0));
    result = yield uploadFiles(
      formData,
      "/mail/sendDetails",
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      yield put({
        type: SEND_DETAILS_SUCCESS,
        payload: {
          message: result?.data?.message,
          investForm: result?.data?.investForm,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SEND_DETAILS_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEND_DETAILS_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleEmailSubscriptions() {
  const user = yield select(selectors.user);
  const userActions = yield select(selectors.userActions);
  let result;
  try {
    const data = {
      email: userActions?.email,
    };
    result = yield request(data, `/mail/subscriptions`, "POST", user?.token);
    if (result?.data?.message) {
      yield put({
        type: EMAIL_SUBSCRIPTIONS_SUCCESS,
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: EMAIL_SUBSCRIPTIONS_ERROR,
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: EMAIL_SUBSCRIPTIONS_ERROR,
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleInvestmentCancel(action) {
  const token = action?.payload?.token;
  const verify = action?.payload?.verify;
  const user = yield select(selectors.user);
  const cancelInvestment = yield select(selectors.cancelInvestment);
  let result;
  try {
    if (verify) {
      const data = {
        user,
        token,
      };
      result = yield request(data, `/investment/verifycanceltoken`, "POST");
      if (result?.data?.message) {
        yield put({
          type: HANDLE_CANCEL_TOKEN_SUCCESS,
          payload: {
            investmentInfo: result?.data?.investmentInfo,
            is_cancelled: result?.data?.is_cancelled,
            message: result?.data?.message,
          },
        });
        // yield put(
        //   actions.change("alertMessage.message", result?.data?.message)
        // );
      } else {
        yield put({
          type: HANDLE_CANCEL_TOKEN_ERROR,
          payload: { message: result?.data?.error, tokenInvalid: true },
        });
        // yield put(actions.change("alertMessage.error", result?.data?.error));
      }
    } else {
      let data;
      if (!cancelInvestment?.investmentInfo) {
        const investForm = yield select(selectors.investForm);
        data = {
          investmentInfo: {
            investment_id: investForm?.id,
            deal_id: investForm?.deal_id,
            tradeId: investForm?.tradeId,
            email: user?.email,
          },
        };
      } else {
        data = {
          investmentInfo: cancelInvestment?.investmentInfo,
        };
      }
      result = yield request(data, `/investment/cancelinvestment`, "POST");
      if (result?.data?.message) {
        yield put({
          type: HANDLE_CANCEL_TOKEN_SUCCESS,
          payload: { message: result?.data?.message, is_cancelled: true },
        });
        // yield put(
        //   actions.change("alertMessage.message", result?.data?.message)
        // );
        // yield put({
        //   type: GET_DEALS,
        // });
      } else {
        yield put({
          type: HANDLE_CANCEL_TOKEN_ERROR,
          payload: { message: result?.data?.error },
        });
        yield put(actions.change("alertMessage.error", result?.data?.error));
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_CANCEL_TOKEN_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleQuestions(action) {
  const { ask, approve } = action?.payload;
  const user = yield select(selectors.user);
  let deal = yield select(selectors.deal);
  const userActions = yield select(selectors.userActions);
  let result;
  try {
    if (ask) {
      const data = {
        issueName: deal?.issueName,
        questionObj: deal?.ask_question,
        contactEmail: deal?.contact_email,
        username: user?.full_name,
        user_id: user?.id,
      };
      result = yield request(data, `/mail/askquestion`, "POST", user?.token);
      if (result?.data?.message) {
        yield put({
          type: HANDLE_QUESTIONS_SUCCESS,
          payload: { deal },
        });
        yield put(
          actions.change("alertMessage.message", result?.data?.message)
        );
      } else {
        yield put({
          type: HANDLE_QUESTIONS_ERROR,
          payload: { message: result?.data?.error },
        });
        // yield put(actions.change("alertMessage.error", result?.data?.error));
      }
    } else {
      const data = {
        qa: userActions?.qa,
        user,
        approve,
      };
      result = yield request(data, `/mail/answerquestion`, "POST", user?.token);
      if (result?.data?.message) {
        deal.faqs = result?.data?.FAQs;
        yield put({
          type: HANDLE_QUESTIONS_SUCCESS,
          payload: { deal },
        });
        yield put(
          actions.change("alertMessage.message", result?.data?.message)
        );
      } else {
        yield put({
          type: HANDLE_QUESTIONS_ERROR,
          payload: { message: result?.data?.error },
        });
        yield put(actions.change("alertMessage.error", result?.data?.error));
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_QUESTIONS_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* getQuestions(action) {
  const user = yield select(selectors.user);
  yield call(getAllDeals);
  const allDeals = yield select(selectors.allDeals);
  let result;
  try {
    const data = {
      allDeals: allDeals?.data,
    };
    result = yield request(
      data,
      `/admin/questions/${user?.id}`,
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      yield put({
        type: GET_QUESTIONS_SUCCESS,
        payload: {
          questions: result?.data?.questions,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: GET_QUESTIONS_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_QUESTIONS_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleComments(action) {
  const { add, commentObj } = action?.payload;
  const user = yield select(selectors.user);
  const deal = yield select(selectors.deal);
  let result;
  try {
    const data = {
      add,
      faqs: deal?.faqs,
      commentObj,
    };
    result = yield request(
      data,
      `/investment/handleComments`,
      "POST",
      user?.token
    );
    if (result?.data?.message) {
      deal.faqs = result?.data?.faqs;
      yield put({
        type: HANDLE_COMMENTS_SUCCESS,
        payload: { deal },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: HANDLE_COMMENTS_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_COMMENTS_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleOfferingInterest() {
  const deal = yield select(selectors.deal);
  const userActions = yield select(selectors.userActions);
  let result;
  try {
    const data = {
      showInterest: userActions?.showInterest,
      issueName: deal?.issueName,
    };
    result = yield request(data, `/mail/handleShowInterest`, "POST");
    if (result?.data?.message) {
      yield put({
        type: REFRESH_USER,
      });
      yield put({
        type: HANDLE_OFFERING_INTEREST_SUCCESS,
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: HANDLE_OFFERING_INTEREST_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_OFFERING_INTEREST_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* actionWatcher() {
  yield takeLeading(GET_IP, getIP);
  yield takeLeading(AUDIT, audit);
  yield takeLeading(REFRESH_USER, refreshUser);
  yield takeLeading(GET_OPTIONS, getOptions);
  yield takeLeading(CHECK_EXISTING_USER, checkExistingUser);
  yield takeLeading(SIGNUP, signup);
  yield takeLeading(LOGIN, login);
  yield takeLeading(TWO_FACTOR, twoFactorAuth);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(SEND_PASSWORD_RESET, sendPasswordReset);
  yield takeLeading(VERIFY_PASSWORD_RESET_TOKEN, verifyPasswordReset);
  yield takeLeading(PASSWORD_RESET, passwordReset);
  yield takeLeading(GET_DEAL, getDeal);
  yield takeLeading(GET_DEALS, getAllDeals);
  yield takeLeading(CREATE_DEAL, createDeals);
  yield takeLeading(EDIT_DEAL, editDeals);
  yield takeLeading(DELETE_DEAL, deleteDeals);
  yield takeLeading(HANDLE_INVESTMENT, handleInvestment);
  yield takeLeading(HANDLE_PARTY, handleParty);
  yield takeLeading(HANDLE_ENTITY, handleEntity);
  // yield takeLeading(HANDLE_ACCOUNT_AND_LINK, handleAccountAndLink);
  yield takeLeading(HANDLE_TRADE, handleTrade);
  yield takeLeading(HANDLE_SUBSCRIPTION_AGREEMENT, handleSubscriptionAgreement);
  yield takeLeading(GENERATE_PLAID_URL, generatePlaidUrl);
  yield takeLeading(GENERATE_STRIPE_URL, generateStripeUrl);
  yield takeLeading(UPDATE_PAYMENT_LINK, updatePaymentLink);
  yield takeLeading(HANDLE_PAYMENT, handlePayment);
  yield takeLeading(HANDLE_SIGNATURE, handleSignature);
  yield takeLeading(PREVENT_INCOME_CHANGE, handlePreventIncomeChange);
  yield takeLeading(SEND_DETAILS, handleSendDetails);
  yield takeLeading(EMAIL_SUBSCRIPTIONS, handleEmailSubscriptions);
  yield takeLeading(HANDLE_CANCEL_TOKEN, handleInvestmentCancel);
  yield takeLeading(HANDLE_QUESTIONS, handleQuestions);
  yield takeLeading(GET_QUESTIONS, getQuestions);
  yield takeLeading(HANDLE_COMMENTS, handleComments);
  yield takeLeading(HANDLE_OFFERING_INTEREST, handleOfferingInterest);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
