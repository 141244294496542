/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, Control } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import "./style.css";

class FormButtons extends Component {
  render() {
    const {
      dispatch,
      user,
      investFormCurrentModel,
      investForm,
      handlePreviousPage,
    } = this.props;

    const currentPage = investFormCurrentModel?.page;

    return (
      <Row>
        {/* This is the mobile button */}
        <Col lg={12} className="inputFormButtonCol inputFormButtonColMobile">
          <Control.button
            model={investFormCurrentModel?.model}
            className="generalSubmitButtonInverted"
            style={{ marginTop: "30px" }}
            type="submit"
            // disabled={disabled}
            onClick={() => {
              dispatch(actions.change("investForm.showFormError", true));
            }}
          >
            {investFormCurrentModel?.page + 1 ===
            investFormCurrentModel?.total_pages
              ? "Complete"
              : "Next"}
          </Control.button>
        </Col>
        {currentPage > 0 && (
          <Col lg={6} className="inputCol inputFormButtonCol">
            <Button
              model={investFormCurrentModel?.model}
              className="generalSubmitButton"
              style={{ marginTop: "30px" }}
              onClick={() => {
                if (currentPage === 1) {
                  dispatch(
                    actions.change(
                      "investFormCurrentModel.model",
                      "investForm.get_started"
                    )
                  );
                  dispatch(actions.change("investFormCurrentModel.page", 0));
                } else {
                  handlePreviousPage();
                }
              }}
            >
              Previous
            </Button>
            <Control.button
              model={investFormCurrentModel?.model}
              className="generalSubmitButton"
              style={{ marginTop: "30px" }}
              onClick={() => {
                dispatch({
                  type: "HANDLE_CANCEL_TOKEN",
                });
                dispatch(
                  actions.change(
                    "investFormCurrentModel.model",
                    "investForm.get_started"
                  )
                );
                dispatch(actions.change("investFormCurrentModel.page", 0));
                dispatch(
                  actions.change("investForm", {
                    offeringId: investForm?.offeringId,
                    deal_id: investForm?.deal_id,
                    user_id: user?.id,
                    investment_info: {},
                    primary_investor: {},
                    secondary_investor: {},
                    investor_questionnaire: {},
                  })
                );
              }}
            >
              Start Over
            </Control.button>
          </Col>
        )}
        {/* This is the desktop button */}
        <Col
          lg={currentPage === 0 ? 12 : 6}
          className="inputCol inputFormButtonCol inputFormButtonColDesktop"
        >
          <Control.button
            model={investFormCurrentModel?.model}
            className="generalSubmitButtonInverted"
            style={{ marginTop: "30px" }}
            type="submit"
            // disabled={disabled}
            onClick={() => {
              dispatch(actions.change("investForm.showFormError", true));
            }}
          >
            {investFormCurrentModel?.page + 1 ===
            investFormCurrentModel?.total_pages
              ? "Complete"
              : "Next"}
          </Control.button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(FormButtons);
