/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, actions } from "react-redux-form";
import { Alert, Button, Modal } from "react-bootstrap";
import GetStarted from "./forms/GetStarted";
import MainForm from "./forms/MainForm";
import SubForms from "./forms/SubForms";
import FormButtons from "./formButtons";
import { currencyFormatter } from "../utils";
import VesterrFillInImgBlack from "../../global/img/VesterrFillInImgBlack.png";
import Close from "../../global/img/close.png";
import { nFormatter, nTextFormatter } from "../utils";
import parse from "html-react-parser";
import "./style.css";

class InvestmentForms extends Component {
  calculateNonAccreditedMax() {
    const { investForm, dispatch } = this.props;
    const { investment_info, investor_questionnaire } = investForm;
    const amount = parseFloat(investment_info?.amount?.replace(/[^0-9.]/g, ""));
    const annual_income = parseFloat(
      investor_questionnaire?.annual_income?.replace(/[^0-9.]/g, "")
    );
    const net_worth = parseFloat(
      investor_questionnaire?.net_worth?.replace(/[^0-9.]/g, "")
    );

    const useIncome =
      investor_questionnaire?.accredited_investor_type?.toUpperCase() ===
        "INDIVIDUAL_INCOME" &&
      annual_income <= investForm?.accredited_income_minimum;

    const useNetWorth =
      investor_questionnaire?.accredited_investor_type?.toUpperCase() ===
        "INDIVIDUAL_NET_WORTH" &&
      net_worth <= investForm?.accredited_net_worth_minimum;

    const previousInvestmentAmount = parseFloat(
      investor_questionnaire?.usd_invested?.replace(/[^0-9.]/g, "")
    );

    // $2,200; or
    // If your annual income or net worth is less than $107,000, you can invest 5% of the greater of your annual income or net worth; or
    // If both your income and net worth are equal to or more than $107,000, you can invest 10% of the greater of your annual income or net worth, not to exceed an amount of $107,000.

    let max_amount = 2500;
    const maxNonAccreditedAmount = 124000;
    let amount_allowed = true;
    // Bullet point 1 in the max limit requirements
    if (amount > max_amount) {
      if (
        annual_income < maxNonAccreditedAmount &&
        net_worth < maxNonAccreditedAmount
      ) {
        amount_allowed = false;
        const max_annual_income = (
          annual_income * 0.05 -
          previousInvestmentAmount
        )?.toFixed(2);
        const max_net_worth = (
          net_worth * 0.05 -
          previousInvestmentAmount
        )?.toFixed(2);
        if (annual_income > net_worth) {
          max_amount = max_annual_income;
        } else {
          max_amount = max_net_worth;
        }
        // Bullet point 2 in the max limit requirements
      } else if (
        annual_income >= maxNonAccreditedAmount ||
        net_worth >= maxNonAccreditedAmount
      ) {
        amount_allowed = false;
        const max_annual_income =
          annual_income * 0.1 - previousInvestmentAmount;
        const max_net_worth = net_worth * 0.1 - previousInvestmentAmount;
        if (useIncome) {
          max_amount = max_annual_income;
        } else if (useNetWorth) {
          max_amount = max_net_worth;
        } else if (annual_income > net_worth) {
          max_amount = max_annual_income;
        } else {
          max_amount = max_net_worth;
        }
        if (max_amount > maxNonAccreditedAmount) {
          max_amount = maxNonAccreditedAmount;
        }
      }
    }
    if (amount <= max_amount) {
      amount_allowed = true;
    }

    // Your investment amount is too much per regulations, please enter a new amount below:
    // cannot be greater than $2,200.00 for this Title III offering

    if (!amount_allowed) {
      if (max_amount > 0) {
        dispatch(
          actions.change(
            "investForm.accredited.error",
            `Your investment cannot be greater than ${currencyFormatter.format(
              max_amount
            )} dollars for this title offering per regulations. Please enter a new amount below.`
          )
        );
      } else {
        dispatch(
          actions.change(
            "investForm.accredited.error",
            `You have reached your annual investment limit.`
          )
        );
      }
      // These 2 dispatches will trigger the locking of the Annual Income and the Net Worth boxes
      if (investForm?.accredited_attempts > 0) {
        dispatch(actions.change("investForm.prevent_income_change", 1));
        if (
          investForm?.not_accredited_red_flag &&
          investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() ===
            "1"
        ) {
          dispatch(
            actions.change(
              "investForm.accredited.prevent_error",
              `You previously identified yourself as an unaccredited investor and are attempting to identify yourself as an accredited investor. Please contact our office to continue.`
            )
          );
        } else {
          dispatch(
            actions.change(
              "investForm.accredited.prevent_error",
              `You previously entered a different value for income and/or net
                worth as an accredited investor. Please contact our office to
                continue.`
            )
          );
        }
      } else {
        dispatch(actions.change("investForm.accredited_attempts", 1));
      }
      dispatch({ type: "PREVENT_INCOME_CHANGE" });
    } else {
      dispatch(actions.change("investForm.error", null));
    }
    dispatch(actions.change("investForm.amount_allowed", amount_allowed));
    return amount_allowed;
  }

  handleFormModel() {
    const { investForm, investFormCurrentModel, deal, options, dispatch } =
      this.props;

    // North Capital Flow Documentation
    // 1. Client Side: Enter in investment information
    // 2. Client Side: Create Party/Entity (Depends on what "type" the investor chose to invest as) - "Primary Investor Form"
    // 2a. API Side: Create Party/Entity API endpoint
    // 2b. API Side: Create an "Account" API endpoint
    // 2c. API Side: Create "Link" endpoint between the "Party/Entity" and the "Account"
    // 3. Client Side: If they chose anything other than "Individual", go to "Secondary Investor Form"
    // 3a. API Side: Create Party/Entity API endpoint
    // 3b. API Side: Create "Link" endpoint between the "Party" and the "Account"
    // 4. Client Side: Go to Investor Questionnaire page
    // 4a. API Side: "Create Trade" API endpoint
    // 5. Client Side: If "ACH" go to "ACH Form" or If "Credit Card" go to "Credit Card Form"
    // 5a. API Side: If "ACH", call "Create External Account (Creates and Attaches "ACH Account" to the "Account")" endpoint
    //     Or If "Credit Card", call "Add Credit Card (Creates and Attaches "Credit Card Account" to the "Account")" endpoint
    // 5b. API Side: If "ACH", call "External Fund Move (Specific to executing the "ACH" flow)" endpoint
    //     Or If "Credit Card", call "CC Fund Move (Online Credit Card Transaction)" endpoint
    // 6. Client Side: Show final page for finalized investment commitment

    dispatch(actions.change("investForm.error", null));

    const isJoint =
      investForm?.investment_info?.type?.toUpperCase() === "TIC" ||
      investForm?.investment_info?.type?.toUpperCase() === "JTWROS" ||
      investForm?.investment_info?.type?.toUpperCase() === "JOINT";

    const isEntity =
      investForm?.investment_info?.type?.toUpperCase() === "ENTITY" ||
      investForm?.investment_info?.type?.toUpperCase() === "SEPIRA" ||
      investForm?.investment_info?.type?.toUpperCase() === "IRA" ||
      investForm?.investment_info?.type?.toUpperCase() === "ROTH";

    // Types
    // Individual, Entity, TIC, JTWROS, IRA, SepIRA, ROTH, Joint

    // Entity Types
    // Revocable Trust, Irrevocable Trust, Limited Partnership, LLC, Corporation

    // Notes
    // Secondary investor can be the "Joint investor" or "Associated Investor"

    if (
      investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() !==
        "1" &&
      !investForm?.accredited_attempts
    ) {
      dispatch(actions.change("investForm.not_accredited_red_flag", true));
    }

    // Get form model
    const formModelOptions = options?.formModels;

    if (
      investFormCurrentModel?.model?.toUpperCase() ===
      "INVESTFORM.INVESTMENT_INFO"
    ) {
      const originalForms = Object.assign([], formModelOptions?.original_forms);
      formModelOptions.forms = formModelOptions?.original_forms;
      formModelOptions.original_forms = originalForms;
    }

    // If the transaction processing fee is set to 0 or is NULL, do not show the form for it
    if (
      deal?.transaction_fee === 0 &&
      formModelOptions?.forms?.includes("transaction_processing_fee")
    ) {
      let formIndex = formModelOptions?.forms.indexOf(
        "transaction_processing_fee"
      );
      if (formIndex !== -1) {
        formModelOptions?.forms.splice(formIndex, 1);
      }
    }

    // Remove payment forms according to what the investor chose
    if (investForm?.investment_info?.transactionType?.toUpperCase() !== "ACH") {
      let formIndex = formModelOptions?.forms.indexOf("ach_authorization_form");
      if (formIndex !== -1) {
        formModelOptions?.forms.splice(formIndex, 1);
      }
    }
    if (
      investForm?.investment_info?.transactionType?.toUpperCase() !==
      "CREDITCARD"
    ) {
      let formIndex = formModelOptions?.forms.indexOf("cc_billing_info");
      if (formIndex !== -1) {
        formModelOptions?.forms.splice(formIndex, 1);
      }
    }

    // Remove unnecessary forms
    if (investForm?.investment_info?.type?.toUpperCase() === "INDIVIDUAL") {
      let formIndex = formModelOptions?.forms.indexOf("secondary_investor");
      if (formIndex !== -1) {
        formModelOptions?.forms.splice(formIndex, 1);
      }
    }

    let amount_allowed = true;

    if (
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.INVESTOR_QUESTIONNAIRE" &&
      investForm?.investor_questionnaire?.accredited_investor?.toUpperCase() !==
        "1"
    ) {
      amount_allowed = this.calculateNonAccreditedMax();
    }

    // Set minimum required amount for accredited investors
    if (
      investFormCurrentModel?.model?.toUpperCase() ===
      "INVESTFORM.INVESTMENT_INFO"
    ) {
      dispatch(actions.change("investForm.accredited_income_minimum", 200000));
      dispatch(
        actions.change("investForm.accredited_net_worth_minimum", 1000000)
      );
      if (isJoint) {
        dispatch(
          actions.change("investForm.accredited_income_minimum", 300000)
        );
      }
    }

    // Logic on when to call API endpoints to North Capital
    // If a investor goes from person to any other type, transfer the investor details
    // to the associated persons object
    // investorLinkType IndivACParty
    if (
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.INVESTMENT_INFO" &&
      investForm.primary_investor &&
      ((isEntity &&
        investForm?.primary_investor?.investorLinkType?.toUpperCase() ===
          "INDIVACPARTY") ||
        (!isEntity &&
          investForm?.primary_investor?.investorLinkType?.toUpperCase() ===
            "ENTITYACPARTY"))
    ) {
      dispatch(actions.change("investForm.primary_investor", {}));
      if (investForm?.investment_info?.type?.toUpperCase() === "INDIVIDUAL") {
        dispatch(actions.change("investForm.secondary_investor", {}));
      }
    }

    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------
    // --------------- Start Section to choose what action to do based on the page submitted -----------------
    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------

    // If you are on the "Transaction Processing Fee" page, or on the last page and there is a transaction fee, add it to the "amount"
    // if (
    //   investFormCurrentModel?.model?.toUpperCase() ===
    //     "INVESTFORM.TRANSACTION_PROCESSING_FEE" ||
    //   (investFormCurrentModel?.page + 1 ===
    //     investFormCurrentModel?.total_pages &&
    //     investForm?.investor_id)
    // ) {
    const transactionAmount = currencyFormatter.format(
      parseFloat(investForm?.investment_info?.amount?.replace(/[^0-9.]/g, "")) +
        parseFloat(deal?.transaction_fee)
    );
    dispatch(
      actions.change("investForm.investment_info.amount", transactionAmount)
    );

    if (
      investFormCurrentModel?.model?.toUpperCase() ===
      "INVESTFORM.INVESTMENT_INFO"
    ) {
      dispatch({ type: "HANDLE_INVESTMENT" });
    } else if (
      investFormCurrentModel?.model?.toUpperCase() ===
      "INVESTFORM.PRIMARY_INVESTOR"
    ) {
      if (isEntity) {
        dispatch({ type: "HANDLE_ENTITY" });
      } else {
        dispatch({ type: "HANDLE_PARTY" });
      }
    } else if (
      investFormCurrentModel?.model?.toUpperCase() ===
      "INVESTFORM.SECONDARY_INVESTOR"
    ) {
      dispatch({
        type: "HANDLE_PARTY",
        payload: { isSecondaryInvestor: true },
      });
    } else if (
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.INVESTOR_QUESTIONNAIRE" &&
      amount_allowed
    ) {
      // NULL out the errors that get triggered in the Investor Questionnaire Annual income/Net worth input fields
      dispatch(actions.change("investForm.accredited.error", null));
      dispatch(actions.change("investForm.accredited.prevent_error", null));
      dispatch({ type: "HANDLE_TRADE" });
    } else if (
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.ACH_AUTHORIZATION_FORM" ||
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.CC_BILLING_INFO"
    ) {
      dispatch({
        type: "HANDLE_PAYMENT",
        payload: {
          ach:
            investFormCurrentModel?.model?.toUpperCase() ===
            "INVESTFORM.ACH_AUTHORIZATION_FORM",
          cc:
            investFormCurrentModel?.model?.toUpperCase() ===
            "INVESTFORM.CC_BILLING_INFO",
        },
      });
    }

    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------
    // ----------------- End Section to choose what action to do based on the page submitted -----------------
    // -------------------------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------------------------

    if (amount_allowed) {
      // Set the index for the current page
      const currentPage = investFormCurrentModel?.page;

      // Set the index for the next page
      const nextPageIndex = formModelOptions?.forms?.indexOf(
        investFormCurrentModel.model?.split(".")[1]
      );

      // Set the next model and page
      dispatch(
        actions.change(
          "investFormCurrentModel.model",
          `investForm.${formModelOptions?.forms[nextPageIndex + 1]}`
        )
      );
      dispatch(actions.change("investFormCurrentModel.page", currentPage + 1));

      // Set the previous model and page
      dispatch(
        actions.change(
          "investFormCurrentModel.previous_model",
          `investForm.${formModelOptions?.forms[nextPageIndex]}`
        )
      );
      dispatch(
        actions.change("investFormCurrentModel.previous_page", currentPage)
      );

      // Set the total pages
      dispatch(
        actions.change(
          "investFormCurrentModel.total_pages",
          formModelOptions?.forms?.length - 1
        )
      );
    }
  }

  handlePreviousPage() {
    const { investFormCurrentModel, options, dispatch } = this.props;

    dispatch(actions.change("investForm.error", null));

    const currentPage = investFormCurrentModel?.page;

    const formModelOptions = options?.formModels;

    const nextPageIndex = formModelOptions?.forms?.indexOf(
      investFormCurrentModel.model?.split(".")[1]
    );

    // Set the next model and page
    dispatch(
      actions.change(
        "investFormCurrentModel.model",
        `investForm.${formModelOptions?.forms[nextPageIndex - 1]}`
      )
    );
    dispatch(actions.change("investFormCurrentModel.page", currentPage - 1));

    // Set the previous model and page
    dispatch(
      actions.change(
        "investFormCurrentModel.previous_model",
        `investForm.${formModelOptions?.forms[nextPageIndex - 2]}`
      )
    );
    dispatch(
      actions.change("investFormCurrentModel.previous_page", currentPage - 2)
    );

    // Set the total pages
    dispatch(
      actions.change(
        "investFormCurrentModel.total_pages",
        formModelOptions?.forms?.length - 1
      )
    );
  }

  render() {
    const {
      dispatch,
      investForm,
      deal,
      show,
      handleClose,
      investFormCurrentModel,
    } = this.props;

    const currentPage = investFormCurrentModel?.page;

    let validCountry = true;
    if (
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.PRIMARY_INVESTOR" &&
        investForm?.primary_investor?.domicile?.toUpperCase() !==
          "U.S. CITIZEN") ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.SECONDARY_INVESTOR" &&
        investForm?.secondary_investor?.domicile?.toUpperCase() !==
          "U.S. CITIZEN")
    ) {
      validCountry = false;
    }

    const transactionUnits =
      investForm?.investment_info?.transactionUnits?.replace(/[^0-9.]/g, "");

    const remainingShares = deal?.remainingShares?.replace(/[^0-9.]/g, "");

    const amountNotValid =
      transactionUnits > 0 && remainingShares - transactionUnits < 0;

    const disabled =
      investForm?.max_payment_limit ||
      investForm?.min_payment_limit ||
      amountNotValid ||
      transactionUnits <= 0 ||
      !validCountry ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.PRIMARY_INVESTOR" &&
        !investForm?.primary_investor?.file?.length &&
        !investForm?.primary_investor?.document &&
        !investForm?.uploadFileError) ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.SECONDARY_INVESTOR" &&
        !investForm?.secondary_investor?.file?.length &&
        !investForm?.secondary_investor?.document &&
        !investForm?.uploadFileError) ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.PRIMARY_INVESTOR" &&
        (!investForm?.primary_investor?.phone ||
          (investForm?.primary_investor?.phone &&
            investForm.showPhoneFormError))) ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.SECONDARY_INVESTOR" &&
        (!investForm?.secondary_investor?.phone ||
          (investForm?.secondary_investor?.phone &&
            investForm.showPhoneFormError))) ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.ACH_AUTHORIZATION_FORM" &&
        !investForm?.linked_ach_account) ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.CC_BILLING_INFO" &&
        !investForm?.linked_cc_account);

    return (
      <React.Fragment>
        <Modal
          id="investModalDialog"
          show={show}
          onHide={handleClose}
          contentClassName="investModalContent"
        >
          <Modal.Header className="modalHeader">
            <section className="investModalHeaderSection">
              <Modal.Title className="investModalTitle sourceBold">
                Invest Now
              </Modal.Title>
              <Button
                className="noStyleBtn modalCloseBtn"
                onClick={handleClose}
              >
                <img src={Close} alt="Close" className="modalClose" />
              </Button>
            </section>
            <section className="investModalTitleSection">
              <div className="investModalTitleDiv">
                <img
                  src={
                    deal?.images?.length
                      ? deal?.images[0]
                      : VesterrFillInImgBlack
                  }
                  alt="Deal"
                  className="investModalImg"
                />
                <div className="investModalTitleTextDiv">
                  <h1 className="investModalTitleTextHeader sourceBold">
                    {deal?.name}
                  </h1>
                  <p className="investModalTitleTextLocation sourceBold">
                    {deal?.location}
                  </p>
                  <p className="investModalTitleTextDesc sourceRegular">
                    {deal?.description}
                  </p>
                  <div className="investProgressBarDiv">
                    <p className="investProgressBarLabel investProgressBarLabelLeft">
                      ${nFormatter(deal?.funds_invested, 1, true)}
                      <br />
                      <span className="investProgressBarSubLabel">
                        {nTextFormatter(deal?.funds_invested)} Raised
                      </span>
                    </p>
                    <div className="investProgressBarBorder">
                      <div
                        className="investProgressBar"
                        style={deal?.progress_bar}
                      />
                    </div>
                    <p className="investProgressBarLabel investProgressBarLabelRight">
                      ${nFormatter(deal?.targetAmount, 1, true)}
                      <br />
                      <span className="investProgressBarSubLabel">
                        {nTextFormatter(deal?.targetAmount)} Raising
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </Modal.Header>
          <Modal.Body className="modalBody">
            <Form
              className="investFormModel container-fluid"
              model={investFormCurrentModel?.model}
              onSubmit={() => {
                if (currentPage === 0) {
                  dispatch(
                    actions.change(
                      "investFormCurrentModel.model",
                      "investForm.investment_info"
                    )
                  );
                  dispatch(
                    actions.change(
                      "investFormCurrentModel.page",
                      currentPage + 1
                    )
                  );
                  dispatch(actions.change("investForm.showFormError", null));
                } else if (!disabled) {
                  dispatch(actions.change("investForm.showFormError", null));
                  this.handleFormModel();
                }
              }}
            >
              {investForm?.error && (
                <Alert className="formAlert sourceBold" variant="warning">
                  {parse(investForm?.error) || "Error occured"}
                </Alert>
              )}
              <section className="investFormSection">
                {investFormCurrentModel?.page === 0 && <GetStarted />}
                {investFormCurrentModel?.page === 1 && <MainForm />}
                {investFormCurrentModel?.page > 1 && (
                  <SubForms handleClose={handleClose} />
                )}
                {investFormCurrentModel?.page !==
                  investFormCurrentModel?.total_pages && (
                  <FormButtons
                    handlePreviousPage={() => this.handlePreviousPage()}
                  />
                )}
              </section>
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(InvestmentForms);
