/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
// import { Row, Col, Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../../../../InputFields";
// import TooltipIcon from "../../../../../global/img/tooltipIcon.svg";
import { formatDate } from "../../../../utils";
import EFT from "../../../../../global/pdf/eft.pdf";

function ACH(props) {
  const { investForm } = props;

  const closeStripeIframe = () => {
    document.getElementById("plaidContainer").style.display = "none";
  };

  const handlePlaidLink = (update) => {
    const { dispatch } = props;
    if (update) {
      dispatch({
        type: "UPDATE_PAYMENT_LINK",
        payload: { update, type: "ACH" },
      });
    }
    dispatch({
      type: "GENERATE_PLAID_URL",
      payload: { update },
    });
    document.getElementById("plaidContainer").style.display = "block";
    window.addEventListener("message", function (event) {
      try {
        let response = JSON?.parse(event.data);
        if (
          // eslint-disable-next-line eqeqeq
          response?.statusCode == 101
        ) {
          dispatch(actions.change("investForm.linked_ach_account", true));
          dispatch({
            type: "UPDATE_PAYMENT_LINK",
            payload: { update: false, type: "ACH" },
          });
          const accountNickname = `${response?.plaidAccountDetails[0]?.account_name} - ${response?.plaidAccountDetails[0]?.institution_name}`;
          dispatch(
            actions.change("investForm.linked_ach_nickname", accountNickname)
          );
          closeStripeIframe();
        } else if (response.errorCode) {
        } //Handle error case (not required)
      } catch (error) {}
    });
  };

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" className="tooltipBody" {...props}>
  //     {props?.popper?.state?.options?.tooltip}
  //   </Tooltip>
  // );

  return (
    <>
      <Row className="inputRow">
        <Col lg={12} className="inputCol">
          <p className="brandColor sourceBold">
            IMPORTANT: To finish your investment, connect your bank account
            using Plaid. Just follow the link below to securely and quickly sync
            a bank account.
          </p>
          {/* {!investForm?.plaid_url ? ( */}
          {!investForm?.linked_ach_account ? (
            <Button
              className="iframeWidgetButton"
              onClick={() => handlePlaidLink()}
            >
              Connect Bank Account
            </Button>
          ) : null}
          {/* ) : null} */}
          {investForm?.plaid_url && investForm?.linked_ach_account ? (
            <>
              {investForm?.linked_ach_nickname ? (
                <p className="sourceBold">
                  Current linked account: {investForm?.linked_ach_nickname}
                </p>
              ) : null}
              <Button
                className="iframeWidgetButton"
                onClick={() => handlePlaidLink(true)}
              >
                Update Bank Account
              </Button>
            </>
          ) : null}
          <hr className="brandHrLine" />
          <div id="plaidContainer">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe id="plaidIframe" src={investForm?.plaid_url}></iframe>
          </div>
        </Col>
        {/* <Col lg={12} className="inputCol">
          <InputFields
            label="Name on Account"
            type="text"
            inputType="text"
            model=".ExtAccountfullname"
            value={investForm?.ach_authorization_form?.ExtAccountfullname}
          />
        </Col>
        <Col lg={12} className="inputCol">
          <InputFields
            label="Nickname for Account"
            type="text"
            inputType="text"
            model=".Extnickname"
            value={investForm?.ach_authorization_form?.Extnickname}
          />
        </Col>
        <Col lg={6} className="inputCol">
          <InputFields
            label="Routing Number"
            type="number"
            inputType="text"
            model=".ExtRoutingnumber"
            value={investForm?.ach_authorization_form?.ExtRoutingnumber}
          />
        </Col>
        <Col lg={6} className="inputCol">
          <InputFields
            label="Account Number"
            type="number"
            inputType="text"
            model=".ExtAccountnumber"
            value={investForm?.ach_authorization_form?.ExtAccountnumber}
          />
        </Col>
        <Col lg={12} className="inputCol">
          <label className="formInputsLabel">
            Account Type
            <OverlayTrigger
              placement="auto"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
              popperConfig={{
                tooltip: "Bank account type (checking or savings).",
              }}
            >
              <img
                src={TooltipIcon}
                alt="Tooltip"
                className="tooltipIcon"
                // show={true}
              />
            </OverlayTrigger>
            {!investForm.ach_authorization_form?.accountType &&
            investForm?.showFormError ? (
              <p className="formInputError">Required*</p>
            ) : null}
          </label>
          <Row>
            <Col lg={2}>
              <InputFields
                label="Checking"
                type="radio"
                inputType="radio"
                value="Checking"
                model=".accountType"
              />
            </Col>
            <Col lg={2}>
              <InputFields
                label="Saving"
                type="radio"
                inputType="radio"
                value="Saving"
                model=".accountType"
              />
            </Col>
          </Row>
        </Col> */}
        <Col className="inputCol">
          <p className="sourceBold">
            IMPORTANT: Please read before completing, signing and submitting.
          </p>
          <p className="sourceRegular">
            Direct Payment via ACH is a transfer of funds from (or to) a bank
            account for the purposes of making a payment.
            <br />
            <br />
            I (we) hereby authorize the escrow agent (“Escrow Agent”), to
            electronically initiate credit and debit entries to my (our) account
            at the financial institution listed below (hereinafter “Bank”), and,
            if necessary, initiate adjustments for any transactions
            credited/debited in error.
            <br />
            <br />
            This authority authorizes the Escrow Agent to credit my (our)
            account to correct errors and with payments, refunds,
            reimbursements, and other funds, and to debit my (our) account for
            the investments I make, and will remain in effect until such time
            that the Escrow Agent is notified by me (us) in writing to revoke
            it, with such notice provided at invest@vesterr.com at least
            {/* it, with such notice provided at support@fundamerica.com at least */}{" "}
            3 business days in advance. I (we) agree that the ACH transactions I
            (we) authorize comply with all applicable law.
            <br />
            <br />
            Please read the full{" "}
            <a
              href={EFT}
              className="generalLink"
              title="EFT"
              target="_blank"
              rel="noreferrer"
            >
              Electronic Funds Transfer
            </a>
            <br />
            <br />
            First transaction will occur on or after {formatDate(true)}.
          </p>
          <InputFields
            label="Please type your name here to sign ACH authorization."
            placeholder="Please type your name here to sign ACH authorization."
            type="text"
            inputType="text"
            model=".literal"
            value={investForm?.ach_authorization_form?.literal}
          />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(ACH);
