/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Footer from "../Footer";
import "./style.css";

class Disclosures extends Component {
  render() {
    return (
      <React.Fragment>
        <GeneralCoverHeader header="Fee Disclosures" />
        <section className="educationSection">
          <div className="educationDiv mainContainer">
            <div className="educationMainDiv">
              <div className="educationSubDiv">
                <Row className="educationMainRow">
                  <Col lg={12} className="educationMainCol">
                    <p className="educationText">
                      These are the fees that Vesterr charges for its services:
                    </p>
                    <br />
                  </Col>
                  <Col lg={12} className="educationMainCol">
                    <div className="edcucationInfoDiv">
                      <b className="educationTextNumber">I.</b>
                      <p className="educationText">
                        As part of the services that we provide as a
                        crowdfunding portal, Vesterr charges an issuer/funderr
                        onboarding fee of $1,250, and
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="educationMainRow">
                  <Col lg={12} className="educationMainCol">
                    <div className="edcucationInfoDiv">
                      <b className="educationTextNumber">II.</b>
                      <p className="educationText">
                        up to 5% of the capital raised per offering.
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} className="educationMainCol">
                    <p className="educationText">
                      <b className="educationDisclosurePurple">
                        Investerrs who make investments through our crowdfunding
                        platform will not be charged by Vesterr.
                      </b>
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
});

export default connect(mapStateToProps)(Disclosures);
