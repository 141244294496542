// MISC
export const ERRORS = "ERRORS";
export const NO_ERRORS = "NO_ERRORS";
export const REFRESH_USER = "REFRESH_USER";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";
export const REFRESH_USER_ERROR = "REFRESH_USER_ERROR";
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_ERROR = "GET_OPTIONS_ERROR";
export const GET_IP = "GET_IP";
export const GET_IP_SUCCESS = "GET_IP_SUCCESS";
export const GET_IP_ERROR = "GET_IP_ERROR";
export const HANDLE_OFFERING_INTEREST = "HANDLE_OFFERING_INTEREST";
export const HANDLE_OFFERING_INTEREST_SUCCESS =
  "HANDLE_OFFERING_INTEREST_SUCCESS";
export const HANDLE_OFFERING_INTEREST_ERROR = "HANDLE_OFFERING_INTEREST_ERROR";

// AUDIT
export const AUDIT = "AUDIT";
export const AUDIT_SUCCESS = "AUDIT_SUCCESS";
export const AUDIT_ERROR = "AUDIT_ERROR";

// SIGNUP/LOGINS
export const CHECK_EXISTING_USER = "CHECK_EXISTING_USER";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const TWO_FACTOR = "TWO_FACTOR";
export const TWO_FACTOR_SUCCESS = "TWO_FACTOR_SUCCESS";
export const TWO_FACTOR_ERROR = "TWO_FACTOR_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_ERROR = "SEND_PASSWORD_RESET_ERROR";
export const VERIFY_PASSWORD_RESET_TOKEN = "VERIFY_PASSWORD_RESET_TOKEN";
export const VERIFY_PASSWORD_RESET_TOKEN_SUCCESS =
  "VERIFY_PASSWORD_RESET_TOKEN_SUCCESS";
export const VERIFY_PASSWORD_RESET_TOKEN_ERROR =
  "VERIFY_PASSWORD_RESET_TOKEN_ERROR";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
// CREATE/EDIT DEALS
export const CREATE_DEAL = "CREATE_DEAL";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_ERROR = "CREATE_DEAL_ERROR";
export const EDIT_DEAL = "EDIT_DEAL";
export const EDIT_DEAL_SUCCESS = "EDIT_DEAL_SUCCESS";
export const EDIT_DEAL_ERROR = "EDIT_DEAL_ERROR";
export const DELETE_DEAL = "DELETE_DEAL";
export const DELETE_DEAL_SUCCESS = "DELETE_DEAL_SUCCESS";
export const DELETE_DEAL_ERROR = "DELETE_DEAL_ERROR";
// GET DEALS
export const GET_DEALS = "GET_DEALS";
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS";
export const GET_DEALS_ERROR = "GET_DEALS_ERROR";
export const GET_DEAL = "GET_DEAL";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";
export const GET_DEAL_ERROR = "GET_DEAL_ERROR";
// HANDLE INVESTMENT
export const HANDLE_INVESTMENT = "HANDLE_INVESTMENT";
export const HANDLE_INVESTMENT_SUCCESS = "HANDLE_INVESTMENT_SUCCESS";
export const HANDLE_INVESTMENT_ERROR = "HANDLE_INVESTMENT_ERROR";
export const HANDLE_PARTY = "HANDLE_PARTY";
export const HANDLE_PARTY_SUCCESS = "HANDLE_PARTY_SUCCESS";
export const HANDLE_PARTY_ERROR = "HANDLE_PARTY_ERROR";
export const HANDLE_PARTY_KYC_AML_ERROR = "HANDLE_PARTY_KYC_AML_ERROR";
export const HANDLE_ENTITY = "HANDLE_ENTITY";
export const HANDLE_ENTITY_SUCCESS = "HANDLE_ENTITY_SUCCESS";
export const HANDLE_ENTITY_ERROR = "HANDLE_ENTITY_ERROR";
export const HANDLE_ACCOUNT_AND_LINK = "HANDLE_ACCOUNT_AND_LINK";
export const HANDLE_ACCOUNT_AND_LINK_SUCCESS =
  "HANDLE_ACCOUNT_AND_LINK_SUCCESS";
export const HANDLE_ACCOUNT_AND_LINK_ERROR = "HANDLE_ACCOUNT_AND_LINK_ERROR";
export const HANDLE_PAYMENT = "HANDLE_PAYMENT";
export const HANDLE_PAYMENT_SUCCESS = "HANDLE_PAYMENT_SUCCESS";
export const HANDLE_PAYMENT_ERROR = "HANDLE_PAYMENT_ERROR";
export const HANDLE_SIGNATURE = "HANDLE_SIGNATURE";
export const HANDLE_SIGNATURE_SUCCESS = "HANDLE_SIGNATURE_SUCCESS";
export const HANDLE_SIGNATURE_ERROR = "HANDLE_SIGNATURE_ERROR";
export const HANDLE_TRADE = "HANDLE_TRADE";
export const HANDLE_TRADE_SUCCESS = "HANDLE_TRADE_SUCCESS";
export const HANDLE_TRADE_ERROR = "HANDLE_TRADE_ERROR";
export const INVESTMENT_SUCCESS = "INVESTMENT__SUCCESS";
export const INVESTMENT_ERROR = "INVESTMENT__ERROR";
export const PREVENT_INCOME_CHANGE = "PREVENT_INCOME_CHANGE";
export const PREVENT_INCOME_CHANGE_SUCCESS = "PREVENT_INCOME_CHANGE_SUCCESS";
export const PREVENT_INCOME_CHANGE_ERROR = "PREVENT_INCOME_CHANGE_ERROR";
export const HANDLE_CANCEL_TOKEN = "HANDLE_CANCEL_TOKEN";
export const HANDLE_CANCEL_TOKEN_SUCCESS = "HANDLE_CANCEL_TOKEN_SUCCESS";
export const HANDLE_CANCEL_TOKEN_ERROR = "HANDLE_CANCEL_TOKEN_ERROR";
// HANDLE SUBSCRIPTION AGREEMENT
export const HANDLE_SUBSCRIPTION_AGREEMENT = "HANDLE_SUBSCRIPTION_AGREEMENT";
export const HANDLE_SUBSCRIPTION_AGREEMENT_SUCCESS =
  "HANDLE_SUBSCRIPTION_AGREEMENT_SUCCESS";
export const HANDLE_SUBSCRIPTION_AGREEMENT_ERROR =
  "HANDLE_SUBSCRIPTION_AGREEMENT_ERROR";
// GENERATE PLAID URL
export const GENERATE_PLAID_URL = "GENERATE_PLAID_URL";
export const GENERATE_PLAID_URL_SUCCESS = "GENERATE_PLAID_URL_SUCCESS";
export const GENERATE_PLAID_URL_ERROR = "GENERATE_PLAID_URL_ERROR";
// GENERATE STRIPE URL
export const GENERATE_STRIPE_URL = "GENERATE_STRIPE_URL";
export const GENERATE_STRIPE_URL_SUCCESS = "GENERATE_STRIPE_URL_SUCCESS";
export const GENERATE_STRIPE_URL_ERROR = "GENERATE_STRIPE_URL_ERROR";
export const UPDATE_PAYMENT_LINK = "UPDATE_PAYMENT_LINK";
export const UPDATE_PAYMENT_LINK_SUCCESS = "UPDATE_PAYMENT_LINK_SUCCESS";
export const UPDATE_PAYMENT_LINK_ERROR = "UPDATE_PAYMENT_LINK_ERROR";
// SEND DETAILS
export const SEND_DETAILS = "SEND_DETAILS";
export const SEND_DETAILS_SUCCESS = "SEND_DETAILS_SUCCESS";
export const SEND_DETAILS_ERROR = "SEND_DETAILS_ERROR";
// EMAIL SUBSCRIPTIONS
export const EMAIL_SUBSCRIPTIONS = "EMAIL_SUBSCRIPTIONS";
export const EMAIL_SUBSCRIPTIONS_SUCCESS = "EMAIL_SUBSCRIPTIONS_SUCCESS";
export const EMAIL_SUBSCRIPTIONS_ERROR = "EMAIL_SUBSCRIPTIONS_ERROR";
// HANDLE QUESTIONS
export const HANDLE_QUESTIONS = "HANDLE_QUESTIONS";
export const HANDLE_QUESTIONS_SUCCESS = "HANDLE_QUESTIONS_SUCCESS";
export const HANDLE_QUESTIONS_ERROR = "HANDLE_QUESTIONS_ERROR";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_ERROR = "GET_QUESTIONS_ERROR";
// HANDLE COMMENTS
export const HANDLE_COMMENTS = "HANDLE_COMMENTS";
export const HANDLE_COMMENTS_SUCCESS = "HANDLE_COMMENTS_SUCCESS";
export const HANDLE_COMMENTS_ERROR = "HANDLE_COMMENTS_ERROR";
