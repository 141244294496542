/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";

import "./style.css";
import Loading from "../Loading";

class ForgotPassword extends Component {
  state = {
    forgotPassword: false,
  };

  render() {
    const { forgotPassword } = this.state;
    const {
      passwordReset,
      handleSendPasswordReset,
      handleChangePassword,
      toggleSignupModal,
    } = this.props;

    const matchPasswords =
      passwordReset?.password?.length >= 6 &&
      passwordReset?.confirmPassword?.length >= 6 &&
      passwordReset?.password === passwordReset?.confirmPassword;

    const changePassword = passwordReset?.verifyPasswordToken;

    return (
      <>
        <Form
          className="loginForm container-fluid"
          model="passwordReset"
          onSubmit={() =>
            !passwordReset?.verifyPasswordToken
              ? handleSendPasswordReset()
              : handleChangePassword()
          }
        >
          <Row>
            {passwordReset?.isLoading ? (
              <Loading inline={true} />
            ) : (
              <>
                {!passwordReset?.verifyPasswordToken ? (
                  <>
                    {!passwordReset?.passwordResetSent ? (
                      <>
                        <Col lg={12} className="inputCol">
                          <InputFields
                            label="Email"
                            type="email"
                            inputType="text"
                            model=".email"
                          />
                        </Col>
                        <Col
                          lg={12}
                          className="inputCol"
                          style={{ flexGrow: "1" }}
                        >
                          <Control.button
                            className="loginButton generalButtonInverted"
                            model="passwordReset"
                            type="submit"
                            disabled={{ valid: false }}
                          >
                            Submit
                          </Control.button>
                        </Col>
                      </>
                    ) : (
                      <h3
                        className="sourceBold"
                        style={{ marginBottom: "30px", textAlign: "center" }}
                      >
                        Please check your email for the password reset link.
                      </h3>
                    )}
                    <Col lg={12} className="inputCol">
                      <p className="signupChangeText sourceRegular">
                        Have an account?{" "}
                        <a
                          href="#/"
                          className="signupChangeLink sourceBold"
                          onClick={() => toggleSignupModal(false, true, false)}
                        >
                          {forgotPassword ? "Forgot Password?" : "Login now"}
                        </a>
                      </p>
                    </Col>
                  </>
                ) : (
                  <>
                    {changePassword ? (
                      <>
                        <Col lg={12} className="inputCol">
                          <InputFields
                            label="Password"
                            type="password"
                            inputType="text"
                            model=".password"
                          />
                        </Col>
                        <Col lg={12} className="inputCol">
                          <InputFields
                            label="Confirm Password"
                            type="password"
                            inputType="text"
                            model=".confirmPassword"
                          />
                          {!matchPasswords ? (
                            <p className="brandColor transatFontBold">
                              <b>Please match your passwords*</b>
                            </p>
                          ) : null}
                        </Col>
                        <Col
                          lg={6}
                          className="inputCol"
                          style={{ flexGrow: "1" }}
                        >
                          <Control.button
                            className="loginButton generalButtonInverted"
                            model="passwordReset"
                            type="submit"
                            disabled={!matchPasswords || { valid: false }}
                          >
                            Submit
                          </Control.button>
                        </Col>
                      </>
                    ) : (
                      <Col
                        lg={6}
                        className="inputCol"
                        style={{ flexGrow: "1" }}
                      >
                        <Button
                          className="loginButton generalButtonInverted"
                          href="/"
                        >
                          Home
                        </Button>
                      </Col>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  generalAlert: state.generalAlert,
  signup: state.signup,
  passwordReset: state.passwordReset,
});

export default connect(mapStateToProps)(ForgotPassword);
