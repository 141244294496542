import React, { useState } from "react";
import { connect } from "react-redux";
import { Navbar, Row, Col, Nav, Container } from "react-bootstrap";
import logo from "../../global/img/logo.png";
import whiteLogo from "../../global/img/whiteLogo.png";
import closeIcon from "./img/close.png";
import fb from "../../global/img/logos/white/fb.png";
import tw from "../../global/img/logos/white/tw.png";
import ln from "../../global/img/logos/white/ln.png";
// import insta from "../../global/img/logos/white/insta.png";
import fbBlack from "../../global/img/logos/black/fb.png";
import twBlack from "../../global/img/logos/black/tw.png";
import lnBlack from "../../global/img/logos/black/ln.png";
// import instaBlack from "../../global/img/logos/black/insta.png";
import SendDetails from "../RaiseCapital/SendDetails";
import "./style.css";

function NavbarHeader(props) {
  const {
    user,
    showMenu,
    toggleMenu,
    handleShow,
    handleShowSignup,
    dispatch,
    isHome,
    isFunderr,
    isInvesterr,
    userRoute,
  } = props;

  const [showRaiseCapital, handleRaiseCapitalShow] = useState(false);

  const pathname = window.location.pathname?.toUpperCase();
  const hideCloseButton = pathname === "/INVESTERR" || pathname === "/FUNDERR";

  return (
    <>
      {showRaiseCapital ? (
        <SendDetails
          show={showRaiseCapital}
          handleClose={() => handleRaiseCapitalShow(false)}
        />
      ) : null}
      {showMenu ? (
        <div className="menuBackgroundContainer">
          <div className="menuBox menuOpenBackgroundDiv" />
          <div className="menuBox menuOpenDarkDiv" />
          <div className="menuBox menuOpenLightDiv" />
          <div className="mainContainer">
            <Navbar id="menu" className="openMenu" collapseOnSelect expand="lg">
              <Navbar.Brand href="/">
                <img src={whiteLogo} alt="Logo" className="logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto "></Nav>
                <Nav className="sourceRegular">
                  {/* {!user?.login && ( */}
                  <>
                    {isFunderr ? (
                      <>
                        <Nav.Link
                          className="navLinks"
                          href="/investerr/howitworks"
                        >
                          Investerr
                          <br />
                          Click here to invest
                        </Nav.Link>
                        <Nav.Link
                          className="navLinks"
                          href="#/"
                          onClick={() => handleRaiseCapitalShow(true)}
                        >
                          Submit a Request to Raise Capital
                        </Nav.Link>
                      </>
                    ) : null}
                    {isInvesterr ? (
                      <>
                        <Nav.Link
                          className="navLinks"
                          href="/funderr/howitworks"
                        >
                          Funderr
                          <br />
                          Click here to raise capital
                        </Nav.Link>
                        {!user?.login ? (
                          <Nav.Link
                            className="navLinks"
                            href="#/"
                            onClick={() => handleShowSignup()}
                          >
                            Create an account
                          </Nav.Link>
                        ) : null}
                      </>
                    ) : null}
                    <Nav.Link
                      className="navLinks"
                      eventKey={2}
                      href="#/"
                      onClick={() =>
                        user?.login
                          ? dispatch({ type: "LOGOUT" })
                          : handleShow()
                      }
                    >
                      {user?.login ? "Logout" : "Login"}
                    </Nav.Link>
                  </>
                  {/* )} */}
                  {showMenu && !hideCloseButton ? (
                    <Nav.Link eventKey={3} href="#/" onClick={toggleMenu}>
                      <div className="closeBtnDiv">
                        <img
                          src={closeIcon}
                          alt="Close Menu"
                          className="closeBtn"
                        />
                      </div>
                    </Nav.Link>
                  ) : null}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <div className="openMenuMainDiv">
            <div className="openMenuLinksDiv sourceRegular mainContainer">
              <a className="openMenuLink" href="/">
                <span style={{ fontWeight: "900" }}>HOME</span>
              </a>
              <a className="openMenuLink" href={`/${userRoute}/ourstory`}>
                OUR STORY
              </a>
              {isFunderr ? (
                <a className="openMenuLink" href="/funderr/howitworks">
                  HOW TO RAISE CAPITAL AS A FUNDERR
                </a>
              ) : null}
              {isInvesterr ? (
                <a className="openMenuLink" href="/investerr/howitworks">
                  HOW TO BECOME AN INVESTERR
                </a>
              ) : null}
              <a className="openMenuLink" href={`/${userRoute}/education`}>
                EDUCATION
              </a>
              <a
                className="openMenuLink"
                href="https://www.youtube.com/channel/UChPaS_2fQgHrILlkSYV7eKA"
                target="_blank"
                rel="noreferrer"
              >
                PODCAST: INVESTERR TALKS
              </a>
              <a
                className="openMenuLink"
                href="https://www.linkedin.com/newsletters/7018675804180029440/"
                target="_blank"
                rel="noreferrer"
              >
                BLOG
              </a>
              <a className="openMenuLink" href={`/${userRoute}/events`}>
                EVENTS
              </a>
              <a className="openMenuLink" href={`/${userRoute}/faq`}>
                FAQ
              </a>
              {user?.admin ? (
                <a className="openMenuLink" href="/admin">
                  <span style={{ fontWeight: "900" }}>ADMIN</span>
                </a>
              ) : null}
              {/* <a className="openMenuLink" href="/investorresources">
                INVESTOR RESOURCES
              </a> */}
              {/* <a
                className="openMenuLink openMenuSubLink"
                href="/disclosures"
                target="_blank"
                rel="noreferrer"
              >
                FEE DISCLOSURES
              </a> */}
            </div>
            <Row className="openMenuBottomRow mainContainer">
              <Col lg={4} className="openMenuBottomCol">
                <a
                  className="offeringsMenuButton hoverDelay sourceBold"
                  href="/investerr/currentinvestmentsecurities"
                >
                  CURRENT INVESTMENT SECURITIES
                </a>
                {/* <div className="openMenuSocial">
                  <a
                    href="https://www.facebook.com/Vesterr-103711092376055"
                    title="Facebook"
                    className="openMenuSocialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={fb}
                      alt="Facebook"
                      className="openMenuSocialImg"
                    />
                  </a>
                  <a
                    href="https://twitter.com/investvesterr"
                    title="Twitter"
                    className="openMenuSocialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={tw} alt="Twitter" className="openMenuSocialImg" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/vesterr/?viewAsMember=true"
                    title="LinkedIn"
                    className="openMenuSocialLink"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={ln}
                      alt="LinkedIn"
                      className="openMenuSocialImg"
                    />
                  </a>
                </div> */}
              </Col>
              <Col lg={4} className="openMenuBottomCol">
                <p className="openMenuCopyright">
                  ©2024 Vesterr LLC, All rights reserved.
                </p>
              </Col>
              <Col lg={4} className="openMenuBottomCol">
                <a
                  href="https://myriann.com"
                  className="openMenuMyriannLink hoverDelay"
                  title="Myriann"
                  target="_blank"
                  rel="noreferrer"
                >
                  Designed by Myriann
                </a>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
      {!showMenu ? (
        <>
          <Navbar
            id="menu"
            className="mainContainer"
            collapseOnSelect
            expand="lg"
            style={{ position: "absolute" }}
            // fixed="top"
          >
            <Navbar.Brand href="/">
              <img src={logo} alt="Logo" className="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto "></Nav>
              <Nav className="sourceRegular">
                {!user?.login && (
                  <>
                    {/* <Nav.Link
                    className="navLinks"
                    href={`/${userRoute}/currentinvestmentsecurities`}
                  >
                    Invest Now
                  </Nav.Link> */}
                    <Nav.Link
                      className="navLinks"
                      eventKey={2}
                      href="#/"
                      onClick={handleShow}
                    >
                      Login
                    </Nav.Link>
                    {isFunderr ? (
                      <Nav.Link
                        className="navLinks"
                        eventKey={3}
                        href="#/"
                        onClick={() => handleRaiseCapitalShow(true)}
                      >
                        Submit a Request to Raise Capital
                      </Nav.Link>
                    ) : null}
                  </>
                )}
                {!isHome ? (
                  <Nav.Link eventKey={3} href="#/" onClick={toggleMenu}>
                    <div className="menuBarIcon">
                      <div className="menuBar1" />
                      <div className="menuBar2" />
                      <div className="menuBar3" />
                    </div>
                  </Nav.Link>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </>
      ) : null}
      <Navbar id="mobileMenu" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} className="logo" alt="Logo" />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Toggle onClick={toggleMenu}>
            <div className="menuSpan"></div>
            <div className="menuSpan"></div>
            <div className="menuSpan"></div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto sourceBold">
              <Nav.Link href="/">HOME</Nav.Link>
              {user?.admin ? <Nav.Link href="/admin">ADMIN</Nav.Link> : null}
              <Nav.Link href={`/${userRoute}/ourstory`}>OUR STORY</Nav.Link>
              {isFunderr || (!isFunderr && !isInvesterr) ? (
                <Nav.Link href="/funderr/howitworks">
                  HOW TO RAISE CAPITAL AS A FUNDERR
                </Nav.Link>
              ) : null}
              {isInvesterr || (!isFunderr && !isInvesterr) ? (
                <Nav.Link href="/investerr/howitworks">
                  HOW TO BECOME AN INVESTERR
                </Nav.Link>
              ) : null}
              <Nav.Link href="/education">EDUCATION</Nav.Link>
              <Nav.Link
                href="https://www.youtube.com/channel/UChPaS_2fQgHrILlkSYV7eKA"
                target="_blank"
                rel="noreferrer"
              >
                PODCAST: INVESTERR TALKS
              </Nav.Link>
              <Nav.Link
                href="https://www.linkedin.com/newsletters/7018675804180029440/"
                target="_blank"
                rel="noreferrer"
              >
                BLOG
              </Nav.Link>
              <Nav.Link href={`/${userRoute}/events`}>EVENTS</Nav.Link>
              <Nav.Link href="/faq">FAQ</Nav.Link>
              {/* <Nav.Link href="/disclosures">FEE DISCLOSURES</Nav.Link> */}
              <Nav.Link
                className="offeringsMobileLink hoverDelay"
                href="/investerr/currentinvestmentsecurities"
              >
                CURRENT INVESTMENT SECURITIES
              </Nav.Link>
              {isFunderr ? (
                <>
                  <Nav.Link
                    className="offeringsMobileLinkPurple"
                    href="/investerr/howitworks"
                  >
                    Investerr
                    <br />
                    Click here to invest
                  </Nav.Link>
                  <Nav.Link
                    className="offeringsMobileLinkPurple"
                    href="#/"
                    onClick={() => handleRaiseCapitalShow(true)}
                  >
                    Submit a Request to Raise Capital
                  </Nav.Link>
                </>
              ) : null}
              {isInvesterr ? (
                <>
                  <Nav.Link
                    className="offeringsMobileLinkPurple"
                    href="/funderr/howitworks"
                  >
                    Funderr
                    <br />
                    Click here to raise capital
                  </Nav.Link>
                </>
              ) : null}
              {!user?.login ? (
                <Nav.Link
                  className="offeringsMobileLinkPurple"
                  href="#/"
                  onClick={() =>
                    user?.login ? dispatch({ type: "LOGOUT" }) : handleShow()
                  }
                >
                  {user?.login ? "Logout" : "Login"}
                </Nav.Link>
              ) : null}
              <Row className="openMenuBottomRow mainContainer">
                <Col lg={4} className="openMenuBottomCol">
                  <div className="openMenuSocial">
                    <a
                      href="https://www.facebook.com/Vesterr-103711092376055"
                      title="Facebook"
                      className="openMenuSocialLink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={fbBlack}
                        alt="Facebook"
                        className="openMenuSocialImg"
                      />
                    </a>
                    <a
                      href="https://twitter.com/investvesterr"
                      title="Twitter"
                      className="openMenuSocialLink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={twBlack}
                        alt="Twitter"
                        className="openMenuSocialImg"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/vesterr/?viewAsMember=true"
                      title="LinkedIn"
                      className="openMenuSocialLink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={lnBlack}
                        alt="LinkedIn"
                        className="openMenuSocialImg"
                      />
                    </a>
                    {/* <a
                        href="#/"
                        title="Instagram"
                        className="openMenuSocialLink"
                      >
                        <img
                          src={instaBlack}
                          alt="Instagram"
                          className="openMenuSocialImg"
                        />
                      </a> */}
                  </div>
                </Col>
                <Col lg={4} className="openMenuBottomCol">
                  <p className="openMenuCopyright">
                    ©2024 Vesterr LLC, All rights reserved.
                  </p>
                </Col>
                <Col lg={4} className="openMenuBottomCol">
                  <a
                    href="https://myriann.com"
                    className="openMenuMyriannLink hoverDelay"
                    title="Myriann"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Designed by Myriann
                  </a>
                </Col>
              </Row>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(NavbarHeader);
