/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../../../InputFields";
import { formatDate } from "../../../utils";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

class PartyForm extends Component {
  addFiles(file, isSecondaryInvestor) {
    const { dispatch, investForm } = this.props;
    if (investForm?.investment_info?.type?.toUpperCase() !== "INDIVIDUAL") {
      file.isSecondaryInvestor = true;
    }
    const files = [file];
    if (!isSecondaryInvestor) {
      dispatch(actions.change("investForm.primary_investor.file", files));
    } else {
      dispatch(actions.change("investForm.secondary_investor.file", files));
    }
    // this.setState({ [type]: files });
  }

  removeFile(fileName, isSecondaryInvestor) {
    const { investForm, dispatch } = this.props;
    let files = [];
    if (!isSecondaryInvestor) {
      const { primary_investor } = investForm;
      files = primary_investor?.file;
    } else {
      const { secondary_investor } = investForm;
      files = secondary_investor?.file;
    }
    files = files?.filter((x) => x.name !== fileName);

    if (!isSecondaryInvestor) {
      dispatch(actions.change("investForm.primary_investor.file", files));
    } else {
      dispatch(actions.change("investForm.secondary_investor.file", files));
    }
    // this.setState({ [type]: files });
  }

  removeExistingFile(fileName, isSecondaryInvestor) {
    const { investForm, dispatch } = this.props;

    if (!isSecondaryInvestor) {
      dispatch(
        actions.change(
          "userActions.deleted_document_id",
          investForm.primary_investor.document?.documentId
        )
      );
      dispatch(actions.change("investForm.primary_investor.document", null));
    } else {
      dispatch(
        actions.change(
          "userActions.deleted_document_id",
          investForm.secondary_investor.document?.documentId
        )
      );
      dispatch(actions.change("investForm.secondary_investor.document", null));
    }
    // this.setState({ [type]: files });
  }

  render() {
    const {
      options,
      investFormCurrentModel,
      investForm,
      setPhoneErrors,
      dispatch,
    } = this.props;

    let isSecondaryInvestor;
    if (
      investForm?.investment_info?.type?.toUpperCase() !== "INDIVIDUAL" &&
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.SECONDARY_INVESTOR"
    ) {
      isSecondaryInvestor = true;
    }

    const DocumentUploader = (type, subType) => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.addFiles(file, isSecondaryInvestor);
          dispatch(actions.change("investForm.uploadFileError", false));
        } else if (status === "removed") {
          this.removeFile(file?.name, isSecondaryInvestor);
          dispatch(actions.change("investForm.uploadFileError", false));
        } else if (status === "error_file_size") {
          dispatch(actions.change("investForm.uploadFileError", true));
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/png,image/jpg,image/jpeg,.pdf"
          maxSizeBytes={1000000}
          PreviewComponent={null}
          inputContent="Upload"
        />
      );
    };

    const currentModel = !isSecondaryInvestor
      ? `primary_investor`
      : `secondary_investor`;

    let investorType = "";
    if (
      investForm?.investment_info?.type?.toUpperCase() !== "INDIVIDUAL" &&
      investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.SECONDARY_INVESTOR"
    ) {
      investorType = "Associated Person's";
    } else {
      investorType = "Investor's";
    }

    const idUploadRequired =
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.PRIMARY_INVESTOR" &&
        !investForm?.primary_investor?.document &&
        !investForm?.primary_investor?.file?.length) ||
      (investFormCurrentModel?.model?.toUpperCase() ===
        "INVESTFORM.SECONDARY_INVESTOR" &&
        !investForm?.secondary_investor?.document &&
        !investForm?.secondary_investor?.file?.length);

    return (
      <>
        <Row className="inputRow">
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} First Name`}
              type="text"
              inputType="text"
              model=".firstName"
              value={investForm[currentModel]?.firstName}
              placeholder="First Name"
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Last Name`}
              type="text"
              inputType="text"
              model=".lastName"
              value={investForm[currentModel]?.lastName}
              placeholder="Last Name"
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Country (Must reside in the United States)`}
              type="select"
              inputType="select"
              model=".primCountry"
              // value={239} // United States Default Value
              acceptedValue="US"
              acceptedValueMessage="Must be incorporated or headquartered in the US"
              value={investForm[currentModel]?.primCountry}
              options={options?.countries || []}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Street Address`}
              type="text"
              inputType="text"
              model=".primAddress1"
              placeholder="Street Address"
              value={investForm[currentModel]?.primAddress1}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Apartment, suite or unit number"
              type="text"
              inputType="text"
              model=".primAddress2"
              placeholder="Apartment, suite or unit number"
              value={investForm[currentModel]?.primAddress2}
              ignore={true}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="City"
              type="text"
              inputType="text"
              model=".primCity"
              value={investForm[currentModel]?.primCity}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="State"
              type="select"
              inputType="select"
              model=".primState"
              options={options?.states || []}
              value={investForm[currentModel]?.primState}
              // excludedValues={["NY"]}
              // excludedValuesMessage="At the current time, we do not accept New York investors."
            />
            {/* <p className="formInputError">
              At the current time, we do not accept New York investors.
            </p> */}
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Zip Code"
              type="text"
              inputType="text"
              model=".primZip"
              value={investForm[currentModel]?.primZip}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Email`}
              type="email"
              inputType="text"
              model=".emailAddress"
              value={investForm[currentModel]?.emailAddress}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Phone`}
              type="tel"
              inputType="customPhone"
              model={`${investFormCurrentModel?.model}.phone`}
              value={investForm[currentModel]?.phone}
              setPhoneErrors={(v) => setPhoneErrors(v)}
            />
            {!investForm[currentModel]?.phone && investForm?.showFormError ? (
              <p className="formInputError">Required*</p>
            ) : null}
            {investForm[currentModel]?.phone &&
            investForm?.showPhoneFormError &&
            investForm?.showFormError ? (
              <p className="formInputError">
                {investForm?.showPhoneFormError}*
              </p>
            ) : null}
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label={`${investorType} Social Security Number`}
              type="text"
              inputType="text"
              model=".socialSecurityNumber"
              value={investForm[currentModel]?.socialSecurityNumber}
              minLength={9}
              maxLength={9}
              tooltip="Social Security Number, EIN, or tax identification number for your country (e.g. your Social Insurance Number). 
                  This is used for identity verification, investor screening, and tax forms that may be associated with this investment."
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Date of Birth"
              type="date"
              inputType="date"
              model=".dob"
              tooltip="This is used for identity verification purposes as well as determining whether investor is age appropriate."
              minValue={18}
              minValueMessage="You must be 18 years or older in order to invest."
              // value={`1993-08-02`}
              value={investForm[currentModel]?.dob}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <InputFields
              label="I am a US citizen, US resident, or other US person."
              type="radio"
              inputType="radio"
              value="U.S. citizen"
              model=".domicile"
            />
            <InputFields
              label="I am not a US citizen, US resident, or other US person."
              type="radio"
              inputType="radio"
              value="non-resident"
              model=".domicile"
            />
            {investForm[currentModel]?.domicile?.toUpperCase() !==
            "U.S. CITIZEN" ? (
              <p className="formInputError">
                We are not accepting non-U.S. citizens at this time.
              </p>
            ) : null}
          </Col>

          {idUploadRequired ? (
            <>
              <p className="identificationUpload sourceBold">
                Upload form of identification (Drivers License or Passport)
                <br />
                Only jpg, jpeg, png, pdf
                <span className="required">*</span>
                <br />
                (Must be 100mb or less)
              </p>
              <Col
                lg={12}
                style={{
                  paddingBottom: !investForm?.showFormError ? "30px" : 0,
                }}
              >
                <DocumentUploader />
              </Col>
              {investForm?.showFormError || investForm?.uploadFileError ? (
                <p
                  className="formInputError"
                  style={{ marginTop: "5px", paddingBottom: "15px" }}
                >
                  {investForm?.uploadFileError ? (
                    <>
                      File is too large, please upload a file with a size 100mb
                      or less.
                      <br />
                    </>
                  ) : null}
                  Identification upload is required*
                </p>
              ) : null}
            </>
          ) : null}
          {investForm[currentModel]?.document ? (
            <Col lg={12} className="fileCol">
              <h1 className="filesHeader sourceBold">
                Uploaded identification
              </h1>
              <div className="fileButtonDiv">
                <p className="fileButtonName">
                  {investForm[currentModel]?.document?.documentName}
                </p>
                <Button
                  className="fileCloseButton hoverDelay"
                  onClick={() =>
                    this.removeExistingFile(
                      investForm[currentModel]?.document?.documentName,
                      false
                    )
                  }
                >
                  X
                </Button>
              </div>
            </Col>
          ) : null}
          {investForm[currentModel]?.file?.map((file, i) => (
            <Col key={i} lg={12} className="fileCol">
              <h1 className="filesHeader sourceBold">
                Uploaded identification
              </h1>
              <div className="fileButtonDiv">
                <p className="fileButtonName">{file?.name}</p>
                <Button
                  className="fileCloseButton hoverDelay"
                  onClick={() => this.removeFile(file?.name, false)}
                >
                  X
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(PartyForm);
