/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Footer from "../Footer";
import GeneralCoverHeader from "../GeneralCoverHeader";
import "./style.css";

function Events(props) {
  const { options } = props;
  const { events } = options;
  return (
    <React.Fragment>
      <GeneralCoverHeader header="Events" />
      <section className="offeringsSection">
        <div className="offeringsDiv mainContainer">
          <Row className="eventsRow">
            {events?.length ? (
              events?.map((e) => (
                <Col lg={12}>
                  <a
                    href={e.url}
                    className="eventDiv"
                    title={e.title}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="eventDateDiv">
                      <p className="eventMonth">{e.month}</p>
                      <h1 className="eventDate">{e.date}</h1>
                    </div>
                    <div className="eventDescriptionDiv">
                      <p className="eventTime">{e.time}</p>
                      <h1 className="eventTitle">{e.title}</h1>
                    </div>
                    <div className="eventImgDiv">
                      <img
                        src={e.image_url}
                        alt={e.title}
                        className="eventImg"
                      />
                    </div>
                  </a>
                </Col>
              ))
            ) : (
              <Col lg={12}>
                <h1 className="generalSubHeader">
                  Stay tuned, there are currently no events available at this
                  time.
                  <br />
                  Join the mailing list to be among those first informed about
                  them!
                </h1>
              </Col>
            )}
          </Row>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
  userActions: state.userActions,
  events: state.events,
});

export default connect(mapStateToProps)(Events);
