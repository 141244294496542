/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import EntityForm from "./GlobalForms/EntityForm";
import PartyForm from "./GlobalForms/PartyForm";
import InvestorQuestionnaireForm from "./GlobalForms/InvestorQuestionnaireForm";
import TransactionFeeForm from "./GlobalForms/TransactionFeeForm";
import SubscriptionAgreementForm from "./GlobalForms/SubscriptionAgreementForm";
import FundTransferForm from "./GlobalForms/FundTransferForm";
import ACHForm from "./GlobalForms/PaymentForms/ACHForm";
import CreditCardForm from "./GlobalForms/PaymentForms/CreditCardForm";
import Loading from "../../Loading";

// 'get_started,investment_info,primary_investor,secondary_investor,investor_questionnaire,ach_authorization_form,cc_billing_info,transaction_processing_fee,fund_transfer'

class SubForms extends Component {
  setPhoneErrors = (clear) => {
    const { dispatch } = this.props;
    dispatch(actions.change("investForm.showFormError", true));
    if (!clear) {
      dispatch(
        actions.change(
          "investForm.showPhoneFormError",
          "Phone number is not valid"
        )
      );
    } else {
      dispatch(actions.change("investForm.showPhoneFormError", null));
    }
  };

  render() {
    const { investForm, investFormCurrentModel, handleClose } = this.props;

    if (investForm?.isLoading) {
      return <Loading inline={true} />;
    }

    const steps = `Step ${investFormCurrentModel?.page}/${investFormCurrentModel.total_pages}`;

    let formHeader = `Primary Investor Details - Investor is a ${
      investForm?.investment_info?.type?.toUpperCase() === "ENTITY"
        ? investForm?.investment_info?.entityType
        : investForm?.investment_info?.type
    }`;

    const isEntity =
      investForm?.investment_info?.type?.toUpperCase() === "ENTITY" ||
      investForm?.investment_info?.type?.toUpperCase() === "SEPIRA" ||
      investForm?.investment_info?.type?.toUpperCase() === "IRA" ||
      investForm?.investment_info?.type?.toUpperCase() === "ROTH";

    // Types
    // Individual, Entity, TIC, JTWROS, IRA, SepIRA, ROTH, Joint

    // Entity Types
    // Revocable Trust, Irrevocable Trust, Limited Partnership, LLC, Corporation

    // Notes
    // Secondary investor can be the "Joint investor" or "Associated Investor"

    if (investFormCurrentModel?.model === "investForm.secondary_investor") {
      if (investForm?.investment_info?.type?.toUpperCase() === "ENTITY") {
        formHeader = `Associated Person - Investor is a ${investForm?.investment_info?.entityType}`;
      } else if (investForm?.investment_info?.type?.toUpperCase() === "JOINT") {
        formHeader = "Joint Investor Details - Investor is a Joint";
      } else if (
        investForm?.investment_info?.type?.toUpperCase() === "JTWROS"
      ) {
        formHeader =
          "Joint Investor Details - Investor is a Joint Tenants with Right of Survivorship";
      } else if (investForm?.investment_info?.type?.toUpperCase() === "TIC") {
        formHeader =
          "Joint Investor Details - Investor is a Joint Tenants in Common";
      } else if (investForm?.investment_info?.type?.toUpperCase() === "IRA") {
        formHeader =
          "Associated Person - Investor is a Self-Directed IRA or 401(k)";
      } else if (
        investForm?.investment_info?.type?.toUpperCase() === "SEPIRA"
      ) {
        formHeader = "Associated Person - Investor is a Sep IRA";
      } else if (investForm?.investment_info?.type?.toUpperCase() === "ROTH") {
        formHeader = "Associated Person - Investor is a ROTH";
      }
    } else if (
      investFormCurrentModel?.model !== "investForm.primary_investor" &&
      investFormCurrentModel?.model !== "investForm.secondary_investor"
    ) {
      if (
        investFormCurrentModel?.model === "investForm.investor_questionnaire"
      ) {
        formHeader = "Investor Questionnaire";
      } else if (
        investFormCurrentModel?.model ===
        "investForm.transaction_processing_fee"
      ) {
        formHeader = "Transaction Processing Fee";
      } else if (
        investFormCurrentModel?.model === "investForm.ach_authorization_form"
      ) {
        formHeader = "ACH Authorization Form";
      } else if (
        investFormCurrentModel?.model === "investForm.cc_billing_info"
      ) {
        formHeader = "Credit Card Billing Information";
      } else if (
        investFormCurrentModel?.model === "investForm.subscription_agreement"
      ) {
        formHeader = "Sign Subscription Agreement";
      } else if (investFormCurrentModel?.model === "investForm.fund_transfer") {
        formHeader = "Fund Transfer Instructions";
      }
    }

    return (
      <React.Fragment>
        <>
          <div className="investFormHeaderDiv">
            <h1 className="investFormHeader">{formHeader}</h1>
            <div className="investFormPageDiv">
              <p className="investFormPage">{steps}</p>
            </div>
          </div>
          {investFormCurrentModel?.model === "investForm.primary_investor" && (
            <>
              {isEntity ? (
                <EntityForm setPhoneErrors={(v) => this.setPhoneErrors(v)} />
              ) : (
                <PartyForm setPhoneErrors={(v) => this.setPhoneErrors(v)} />
              )}
            </>
          )}
          {investFormCurrentModel?.model ===
            "investForm.secondary_investor" && (
            <PartyForm setPhoneErrors={(v) => this.setPhoneErrors(v)} />
          )}
          {investFormCurrentModel?.model ===
            "investForm.investor_questionnaire" && (
            <InvestorQuestionnaireForm />
          )}
          {investFormCurrentModel?.model ===
            "investForm.transaction_processing_fee" && <TransactionFeeForm />}
          {investFormCurrentModel?.model ===
            "investForm.ach_authorization_form" && <ACHForm />}
          {investFormCurrentModel?.model === "investForm.cc_billing_info" && (
            <CreditCardForm />
          )}
          {investFormCurrentModel?.model ===
            "investForm.subscription_agreement" && (
            <SubscriptionAgreementForm />
          )}
          {investFormCurrentModel?.model === "investForm.fund_transfer" && (
            <FundTransferForm handleClose={handleClose} />
          )}
        </>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(SubForms);
