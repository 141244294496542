/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";
import TermsOfUse from "../../global/pdf/terms_of_use.pdf";
import PrivacyPolicy from "../../global/pdf/privacy_notice.pdf";
import Arbitration from "../../global/pdf/arbitration.pdf";
import Education from "../../global/pdf/education.pdf";
import Disclosures from "../../global/pdf/disclosures.pdf";

import "./style.css";
import Loading from "../Loading";

class Signup extends Component {
  render() {
    const {
      dispatch,
      handleClose,
      generalAlert,
      signup,
      checkExistingUser,
      handleSignup,
      toggleSignupModal,
      // removeFile,
      // DocumentUploader,
    } = this.props;

    // const disabled = !signup?.file?.length || signup?.existing_user;
    // Removing ID upload requirement
    const disabled = signup?.existing_user;

    return (
      <>
        {!signup?.success ? (
          <Form
            className="investFormModel container-fluid"
            model="signup"
            onSubmit={() => {
              if (!disabled) {
                handleSignup();
              }
            }}
          >
            <section className="investFormSection">
              {signup?.isLoading ? (
                <Loading inline={true} />
              ) : (
                <>
                  <Row>
                    {generalAlert?.error ? (
                      <Col lg={12}>
                        {/* <Alert
                          className="formAlert sourceBold"
                          variant="warning"
                        > */}
                        <p className="errorText">{generalAlert?.error}</p>
                        {/* </Alert> */}
                      </Col>
                    ) : null}
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="First Name"
                        type="text"
                        inputType="text"
                        model=".first_name"
                        value={signup?.first_name}
                      />
                    </Col>
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="Last Name"
                        type="text"
                        inputType="text"
                        model=".last_name"
                        value={signup?.last_name}
                      />
                    </Col>
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="Email"
                        type="email"
                        inputType="text"
                        model=".email"
                        value={signup?.email}
                        onBlur={() => checkExistingUser()}
                      />
                      {signup?.existing_user ? (
                        <p
                          className="sourceBold"
                          style={{
                            color: "red",
                            marginTop: "15px",
                            marginBottom: 0,
                          }}
                        >
                          This user already exists, please login to continue
                        </p>
                      ) : null}
                    </Col>
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="Password"
                        type="password"
                        inputType="text"
                        model=".password"
                        value={signup?.password}
                      />
                    </Col>
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label={
                          <p className="soureRegular">
                            I agree to the{" "}
                            <a
                              className="checkboxLabelLink"
                              href={TermsOfUse}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms of Use
                            </a>{" "}
                            and{" "}
                            <a
                              className="checkboxLabelLink"
                              href={PrivacyPolicy}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>
                            .
                          </p>
                        }
                        type="checkbox"
                        inputType="checkbox"
                        model=".signup_1"
                        // standardLabel={true}
                      />
                    </Col>
                  </Row>
                  <Row className="inputRow">
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="I certify that all of the information provided by me is true and complete. I agree to keep this information up to date and will notify Vesterr of any changes."
                        type="checkbox"
                        inputType="checkbox"
                        model=".signup_2"
                        // standardLabel={true}
                      />
                    </Col>
                  </Row>
                  <Row className="inputRow">
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="I understand the investments are illiquid and speculative, and that I may lose my entire investment. I am in a financial position to bear the loss of my investment. I acknowledge that, by making an investment, there are restrictions on canceling my investment and receiving a return of my funds. I understand I may cancel my investment at any time up until 48-hours prior to the target offering deadline. I acknowledge the securities I purchase are illiquid and may be difficult to resell."
                        type="checkbox"
                        inputType="checkbox"
                        model=".signup_3"
                        // standardLabel={true}
                      />
                    </Col>
                  </Row>
                  <Row className="inputRow">
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label={
                          <>
                            I agree to receive all communications from Vesterr
                            electronically. By marking this checkbox, I agree to
                            the{" "}
                            <a
                              href={Arbitration}
                              className="hyperLink"
                              target="_blank"
                              rel="noreferrer"
                            >
                              pre-dispute arbitration agreement
                            </a>
                            . I acknowledge I have reviewed the{" "}
                            <a
                              href={Education}
                              // href="/education"
                              className="hyperLink"
                              target="_blank"
                              rel="noreferrer"
                            >
                              investor education
                            </a>{" "}
                            and{" "}
                            <a
                              href={Disclosures}
                              // href="/disclosures"
                              className="hyperLink"
                              target="_blank"
                              rel="noreferrer"
                            >
                              fee disclosure materials.
                            </a>
                          </>
                        }
                        type="checkbox"
                        inputType="checkbox"
                        model=".signup_4"
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                    {!signup?.file?.length && (
                      <>
                        <p className="identificationUpload sourceBold">
                          Upload form of identification
                          <br />
                          (Drivers License or Passport)
                          <br />
                          Only jpg, jpeg, png
                          <span className="required">*</span>
                        </p>
                        <Col
                          lg={12}
                          style={{
                            paddingBottom: !signup?.showFormError ? "30px" : 0,
                          }}
                        >
                          <DocumentUploader />
                        </Col>
                        {signup?.showFormError ? (
                          <p
                            className="formInputError"
                            style={{ marginTop: "5px", paddingBottom: "15px" }}
                          >
                            Identification upload is required*
                          </p>
                        ) : null}
                      </>
                    )}
                    {signup?.file?.map((file, i) => (
                      <Col key={i} lg={12} className="fileCol">
                        <h1 className="filesHeader sourceBold">
                          Uploaded identification
                        </h1>
                        <div className="fileButtonDiv">
                          <p className="fileButtonName">{file?.name}</p>
                          <Button
                            className="fileCloseButton hoverDelay"
                            onClick={() => removeFile(file?.name)}
                          >
                            X
                          </Button>
                        </div>
                      </Col>
                    ))}
                  </Row> */}
                  <Col lg={12} className="inputCol">
                    <Control.button
                      className="signupButton generalButtonInverted"
                      model="signup"
                      type="submit"
                      // disabled={
                      //   !signup?.file?.length ||
                      //   signup?.existing_user || { valid: false }
                      // }
                      onClick={() => {
                        dispatch(actions.change("signup.showFormError", true));
                      }}
                    >
                      Signup
                    </Control.button>
                  </Col>
                  <Col lg={12} className="inputCol">
                    <p className="signupChangeText sourceRegular">
                      Already have an account?{" "}
                      <a
                        href="#/"
                        className="signupChangeLink sourceBold"
                        onClick={() => toggleSignupModal(false, true, false)}
                      >
                        Login now
                      </a>
                    </p>
                  </Col>
                </>
              )}
            </section>
          </Form>
        ) : (
          <section className="investFormSection">
            <Row>
              <Col lg={12} className="inputCol">
                {/* <Alert className="formAlert sourceBold" variant="success"> */}
                <h1 className="messageHeader sourceBold">
                  You have successfully signed up!
                </h1>
                {/* </Alert> */}
                <Button
                  className="signupButton generalButtonInverted"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  generalAlert: state.generalAlert,
  signup: state.signup,
  passwordReset: state.passwordReset,
});

export default connect(mapStateToProps)(Signup);
