/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Footer from "../Footer";
import DealCards from "../DealCards";
import HotelsImg from "./img/hotels.png";
import DaycaresImg from "./img/daycares.png";
import LandImg from "./img/land.png";
import MultiFamilyImg from "./img/multifamily.png";
import OfficeImg from "./img/office.png";
import RetailImg from "./img/retail.png";
import SeniorLivingImg from "./img/seniorliving.png";
import StudentHousingImg from "./img/studenthousing.png";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Loading from "../Loading";
import "./style.css";

function CurrentOfferings(props) {
  let [searchParams] = useSearchParams();
  const offeringType = searchParams.get("type");

  useEffect(() => {
    const { dispatch } = props;
    dispatch({ type: "GET_DEALS", payload: { filter: offeringType } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { allDeals, userActions, options } = props;
  const { dealTags } = options;

  if (userActions?.isLoading || allDeals?.isLoading) {
    return <Loading />;
  }

  let tiles = [
    {
      type: "hotels",
      html: (
        <>
          <a className="categoryTile categoryTileHotels" href="?type=hotels">
            <img src={HotelsImg} alt="Hotels" className="categoryTileIcon" />
          </a>
          <p className="categoryTileLabel">Hotels</p>
        </>
      ),
    },
    {
      type: "daycares",
      html: (
        <>
          <a
            className="categoryTile categoryTileDaycares"
            href="?type=daycares"
          >
            <img
              src={DaycaresImg}
              alt="Daycares"
              className="categoryTileIcon"
            />
          </a>
          <p className="categoryTileLabel">Daycares</p>
        </>
      ),
    },
    {
      type: "land",
      html: (
        <>
          <a className="categoryTile categoryTileLand" href="?type=land">
            <img src={LandImg} alt="Land" className="categoryTileIcon" />
          </a>
          <p className="categoryTileLabel">Land</p>
        </>
      ),
    },
    {
      type: "multifamily",
      html: (
        <>
          <a
            className="categoryTile categoryTileMultiFamily"
            href="?type=multifamily"
          >
            <img
              src={MultiFamilyImg}
              alt="Multi-Family"
              className="categoryTileIcon"
            />
          </a>
          <p className="categoryTileLabel">Multi-Family</p>
        </>
      ),
    },
    {
      type: "office",
      html: (
        <>
          <a className="categoryTile categoryTileOffice" href="?type=office">
            <img src={OfficeImg} alt="Office" className="categoryTileIcon" />
          </a>
          <p className="categoryTileLabel">Office</p>
        </>
      ),
    },
    {
      type: "retail",
      html: (
        <>
          <a className="categoryTile categoryTileRetail" href="?type=retail">
            <img src={RetailImg} alt="Retail" className="categoryTileIcon" />
          </a>
          <p className="categoryTileLabel">Retail</p>
        </>
      ),
    },
    {
      type: "seniorliving",
      html: (
        <>
          <a
            className="categoryTile categoryTileSeniorLiving"
            href="?type=seniorliving"
          >
            <img
              src={SeniorLivingImg}
              alt="Senior Living"
              className="categoryTileIcon"
            />
          </a>
          <p className="categoryTileLabel">Senior Living</p>
        </>
      ),
    },
    {
      type: "studenthousing",
      html: (
        <>
          <a
            className="categoryTile categoryTileStudentHousing"
            href="?type=studenthousing"
          >
            <img
              src={StudentHousingImg}
              alt="Student Housing"
              className="categoryTileIcon"
            />
          </a>
          <p className="categoryTileLabel">Student Housing</p>
        </>
      ),
    },
  ];

  tiles = tiles.filter((t) => dealTags?.includes(t.type));

  return (
    <React.Fragment>
      <GeneralCoverHeader
        header="Current Investment Securities"
        text={
          <>
            {!offeringType
              ? "We offer 8 investment opportunities in the following sectors. All investments are available for your vetting and due diligence review."
              : "All investments are available for your vetting and due diligence review."}
          </>
        }
      />
      <section className="offeringsSection">
        <div className="offeringsDiv mainContainer">
          {!offeringType ? (
            <Row className="categoryTileRow">
              {/* {options?.offeringTags?.map(
                (t) =>
                  t?.tags && (
                    <Col lg={4} className="categoryTileCol">
                      <a className="categoryTile" href={`?type=${t?.tags}`}>
                        <img
                          src={VesterrFillInImgBlack}
                          alt={t}
                          className="categoryTileIcon"
                        />
                      </a>
                      <p className="categoryTileLabel">{t?.tags}</p>
                    </Col>
                  )
              )} */}
              {tiles?.map((t) => (
                <Col lg={4} className="categoryTileCol">
                  {t.html}
                </Col>
              ))}
            </Row>
          ) : null}
          <Row>
            {offeringType ? (
              <>
                <button
                  className="offeringsBackButton sourceBold hoverDelay"
                  title="Back Button"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
                {allDeals?.data?.length ? (
                  allDeals?.data?.map((d, i) => (
                    <DealCards key={i} deal={d} navigate={(v) => navigate(v)} />
                  ))
                ) : (
                  <Col lg={12}>
                    <h1 className="generalSubHeader">
                      Stay tuned, we're in the process of reviewing deals.
                      <br />
                      Join the mailing list to be among those first informed
                      about them!
                    </h1>
                  </Col>
                )}
              </>
            ) : null}
          </Row>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
  userActions: state.userActions,
});

export default connect(mapStateToProps)(CurrentOfferings);
