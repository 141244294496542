/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Footer from "../Footer";
import Close from "../../global/img/closeWhite.png";
import "./style.css";

function Education(props) {
  const [educationItem, handleEducationText] = useState(null);
  return (
    <React.Fragment>
      <Modal
        id="loginModalDialog"
        show={educationItem}
        onHide={() => handleEducationText(null)}
        contentClassName=""
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="bioModalHeader">
          <section className="investModalHeaderSection">
            <Modal.Title className="investModalTitle sourceBold">
              {educationItem?.name}
            </Modal.Title>
            <Button
              className="noStyleBtn modalCloseBtn"
              onClick={() => handleEducationText(null)}
            >
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body className="bioModalBody" style={{ display: "flex" }}>
          <>
            <section className="investFormSection">
              <Row>
                <Col lg={12} className="inputCol">
                  <hr className="bioHR" />
                  <p>{educationItem?.text}</p>
                </Col>
              </Row>
            </section>
          </>
        </Modal.Body>
      </Modal>
      <GeneralCoverHeader header="Education" />
      <section className="educationSection">
        <div className="educationDiv mainContainer">
          <div className="educationMainDiv">
            <Row className="educationTileRow">
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Cancellation Rights",
                      text: (
                        <>
                          You have the right to cancel your investment
                          commitment in an offering at any time until 48-hours
                          prior to the deadline identified in the company's
                          offering materials. After that, your investment will
                          be final.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Cancellation Rights</p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Disclosure and Ongoing Reporting by Issuers",
                      text: (
                        <>
                          <span style={{ fontStyle: "italic" }}>
                            Regulation Crowdfunding imposes specific disclosure
                            requirements on issuers regarding their business and
                            the securities offering. Issuers are required to
                            file certain information with the SEC and provide
                            this information to investors.
                            <br />
                            <br />
                            You are highly recommended to read the disclosure
                            materials of an issuer before you invest. You should
                            also pay attention to the ongoing reporting by the
                            issuer if you become an investor of the issuer.
                            <br />
                            <br />
                            Click{" "}
                            <a
                              href="https://www.law.cornell.edu/cfr/text/17/227.202"
                              title="Cornell"
                              className="educationHyperLink hoverDelay"
                              target="_blank"
                              rel="noreferrer"
                            >
                              https://www.law.cornell.edu/cfr/text/17/227.202
                            </a>{" "}
                            for more information regarding disclosure and
                            ongoing reporting by issuers.
                          </span>
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">
                    Disclosure and Ongoing Reporting by Issuers
                  </p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Due Diligence",
                      text: (
                        <>
                          You are responsible for conducting legal, accounting
                          and other due diligence review on the company and any
                          offerings posted on the Vesterr website to determine
                          whether the investment is suitable for your investment
                          needs. These investments are high risk and you should
                          be able to bear the loss of your entire investment
                          before you agree to invest.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Due Diligence</p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Equity Crowdfunding",
                      text: (
                        <>
                          Is the online offering of a startup or private company
                          securities for investment. Title III of the Jumpstart
                          Our Business Startups (JOBS) Act permits anyone to
                          invest in crowdfunding securities offerings of up to
                          $5 million, pursuant to Section 4(a)(6) of the
                          Securities Act of 1933. These crowdfunding investments
                          are made directly through the <b>Vesterr</b> website.
                          Vesterr is a registered crowdfunding portal, focused
                          on the hotel and hospitality industry, which website
                          is used to post Regulation Crowdfunding offerings.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Equity Crowdfunding</p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Investment Limits",
                      text: (
                        <>
                          Regulation Crowdfunding limits the amount
                          nonaccredited investors can invest (accredited
                          investors have no investment limit). The aggregate
                          amount of securities sold to any non-accredited
                          investor across all Regulation Crowdfunding issuers
                          during the 12-month period preceding the date of this
                          transaction, including the securities sold to such
                          investor in any current Regulation Crowdfunding
                          transaction, cannot exceed:
                          <br />
                          <br />
                          (i) The greater of $2,200 or 5 percent of the greater
                          of the investor's annual income or net worth if either
                          the investor's annual income or net worth is less than
                          $107,000; or
                          <br />
                          <br />
                          (ii) 10 percent of the greater of the investor's
                          annual income or net worth, not to exceed an amount
                          sold of $107,000, if both the investor's annual income
                          and net worth are equal to or more than $107,000.
                          <br />
                          <br />
                          Accredited investor do not have investment
                          limitations. Accredited investors generally have a net
                          worth, or joint net worth with their spouse or spousal
                          equivalent, in excess of $1 million, not including
                          their primary residence; or had an annual income of
                          over $200,000 in each of the last two years, or joint
                          income with their spouse or spousal equivalent
                          exceeding $300,000 for those years, and reasonably
                          expects to have the same income this year.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Investment Limits</p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "No Investment Advice or Recommendations",
                      text: (
                        <>
                          Vesterr does not provide any investment advice or
                          recommendations. The posting of an offering on
                          Vesterr's website is neither a recommendation,
                          solicitation or endorsement of the offering by us. Any
                          decision to invest needs to be based solely upon your
                          own evaluation and analysis of the offering as an
                          investor, and any investment you make is at your own
                          risk. You are strongly advised to consult with your
                          legal and financial advisers before making any
                          investment.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">
                    No Investment Advice or Recommendations
                  </p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Risks of Investing",
                      text: (
                        <>
                          Investing in startups and other private companies is
                          highly speculative and should only be done by
                          investors who can bear the complete loss of their
                          investment without any change in their lifestyle.
                          Risks include, but are not limited to: (i) limited
                          operating history, (ii) lack of liquidity or any
                          market for the resale of your investment, (iii)
                          possibility of fraud or misrepresentation, (iv)
                          arbitrary valuation of the company, (v) limited
                          shareholder rights including voting rights, (vi) the
                          possibility of dilution of your interest in the
                          company (meaning the reduction in the ownership
                          percentage of a company caused by the issuance of more
                          shares by the company), and (vii) the inability of the
                          company to generate revenue or raise additional
                          capital to fund operations, continue its relationship
                          with Vesterr or to publish annual reports where an
                          investor obtains the most current financial
                          information about the company.
                          <br />
                          <br />
                          There are additional risks to investing in real estate
                          securities' investments, which include (i) general
                          market risk, such as market ups and downs tied to the
                          economy, interest rates or inflation; (ii) asset risk,
                          such as developing a hotel in a community where there
                          is a lot of competition already, poses a risk to the
                          value of the hotel asset; (iii) risk of the particular
                          property, such as deficiencies of construction or
                          other negative attributes of the particular property
                          can increase or lower investment value; and (iv)
                          liquidity risk, not only are real estate investments
                          considered illiquid, but you will not be able to
                          resale your securities acquired through a Crowdfunding
                          offering on Vesterr for a period of one year, subject
                          to certain limited exceptions, including sales back to
                          the issuer, to accredited investors, to family members
                          under certain circumstances (i.e. death or divorce).
                          However, even after the 1-year restriction period,
                          there is no guarantee that there will be a marketplace
                          for your securities to be sold. Investment risks in
                          real estate securities thus include, for example,
                          purchasing a hospitality investment in a bad location,
                          or purchasing a hospitality project that has negative
                          cash flow or high vacancies. Downturns or recessions
                          can impact the hospitality market, as well as
                          competition with other hospitality properties. More
                          real estate related-risks include a lack of liquidity
                          (difficulty selling), potential hidden structural
                          problems, and generally, the hospitality market can be
                          unpredictable, as with the onset of Covid-19, as well
                          as other factors that may cause your investment to
                          decrease in value.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Risks of Investing</p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Transfer and Resale Restrictions",
                      text: (
                        <>
                          You will not be able to resale your securities
                          acquired through a Crowdfunding offering on Vesterr
                          for a period of one year, subject to certain limited
                          exceptions, including sales back to the issuer, to
                          accredited investors, to family members under certain
                          circumstances (i.e. death or divorce). However, even
                          after the 1-year restriction period, there is no
                          guarantee that there will be a marketplace for the
                          securities to be sold. Regulation crowdfunding
                          securities are generally illiquid, meaning not easily
                          sold.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">
                    Transfer and Resale Restrictions
                  </p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Types of securities offered on Vesterr",
                      text: (
                        <>
                          The types of securities offered and sold on Vesterr’s
                          crowdfunding portal and the risks associated with each
                          type of security, including the risk of having limited
                          voting power as a result of dilution, are discussed
                          below:
                          <br />
                          <br />
                          <b>Common Stock</b> - Investors that receive common
                          stock are the equity shareholders of the Company.
                          There are different classes of common stock so you
                          should carefully review the terms describing the type
                          of security being offered. Although shareholders of
                          common stock generally have voting rights, the Company
                          may issue a class of common stock which does not give
                          you the right to vote. Unlike lenders that receive
                          debt of the Company, investors who receive common
                          stock do not have a right of repayment of their
                          investment amount. In the event of bankruptcy, lenders
                          (debt holders) and holders of other financial
                          obligations are paid before shareholders when assets
                          (to the extent they are available) are distributed.
                          The Company may also issue preferred stock which may
                          have greater rights than common stockholders, such as
                          greater voting rights, dividend rights, or right to
                          representation on the board of directors. Even though
                          you may have voting rights as a shareholder of common
                          stock, if you are a small shareholder, or if your
                          interest in the Company is diluted by later offerings
                          that include more shareholders, you may have little
                          influence on the outcome of any matters brought before
                          the stockholders for voting. The price of the shares
                          of common stock may be arbitrarily determined by the
                          Company which means that you may have paid a price
                          greater than the actual value of the security you
                          purchased. Unlike public companies, the price of the
                          shares being offered are not determined by the market
                          or a securities exchange but rather by the Company
                          issuing such securities. Diversification of your
                          investments by spreading your investments across
                          several different types of investments can help you
                          reduce the risk of your investment portfolio.
                          <br />
                          <br />
                          <b>Preferred Stock.</b> A Company may issue a separate
                          class of stock which may have special rights and
                          privileges, including without limitation voting
                          rights, dividend rights, anti-dilution and liquidation
                          preferences. Stock holders of Preferred Stock have
                          similar risks to common stock holders but receive
                          better or different terms.
                          <br />
                          <br />
                          <b>Debt Instruments:</b> A debt instrument is an
                          investment where an investor loans money to a Company,
                          which borrows the funds for a period of time at a set
                          interest rate. Thus investors in debt instruments are
                          lenders to the Company. An investor in a debt
                          instrument runs the risk that the Company may default
                          on payment, among other risks.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">
                    Types of securities offered on Vesterr
                  </p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Vesterr and the website",
                      text: (
                        <>
                          The Vesterr website includes materials prepared by the
                          startup or private company concerning its investment
                          offering. All Vesterr users should carefully review
                          the company offering materials carefully, including
                          the company's Form C filed with the Securities and
                          Exchange Commission, which provides material facts
                          related to the investment offering.
                          <br />
                          <br />
                          The entire investment process occurs on the Vesterr
                          website. Investors should carefully review all the
                          information concerning the company's offering to see
                          if it is an appropriate offering for you to invest,
                          meaning that each investor has the risk tolerance to
                          invest in an offering that involves a high level of
                          risk and that the investor can sustain the loss of
                          some or all of the r investment.
                          <br />
                          <br />
                          Vesterr's fees for the services it provides is
                          disclosed in the “fee disclosure” page of this
                          website. These fees are generally paid by the
                          company's who list on Vesterr's website. No fee for
                          the use of the website is charged directly to
                          investors.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Vesterr and the website</p>
                </div>
              </Col>
              <Col lg={4} className="educationTileCol">
                <div
                  className="educationTileDiv"
                  onClick={() =>
                    handleEducationText({
                      name: "Fractional Investment",
                      text: (
                        <>
                          Fractional investment is the practice of purchasing a
                          portion or fraction of a security rather than the
                          entire security.
                        </>
                      ),
                    })
                  }
                >
                  <p className="educationTileText">Fractional Investment</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
});

export default connect(mapStateToProps)(Education);
