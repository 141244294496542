/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../../../../InputFields";
import EFT from "../../../../../global/pdf/eft.pdf";

function CreditCard(props) {
  const { investForm } = props;
  // const { cc_billing_info } = investForm;
  // let currentYear = new Date().getFullYear();

  const closeStripeIframe = (ccLinkSuccessful) => {
    document.getElementById("stripeContainer").style.display = "none";
  };

  const handleStripeLink = (update) => {
    const { dispatch } = props;
    if (update) {
      dispatch({
        type: "UPDATE_PAYMENT_LINK",
        payload: { update, type: "CC" },
      });
    }
    dispatch({
      type: "GENERATE_STRIPE_URL",
      payload: { update },
    });
    document.getElementById("stripeContainer").style.display = "block";
    window.addEventListener("message", function (event) {
      try {
        const response = JSON?.parse(event.data);
        if (
          // eslint-disable-next-line eqeqeq
          response?.statusCode == 101
        ) {
          dispatch(actions.change("investForm.linked_cc_account", true));
          dispatch(actions.change("investForm.ccLinkSuccessful", true));
          dispatch({
            type: "UPDATE_PAYMENT_LINK",
            payload: { update: false, type: "CC" },
          });
          closeStripeIframe();
        } else if (response.errorCode) {
        } //Handle error case (not required)
      } catch (error) {}
    });
  };

  return (
    <>
      <Row className="inputRow">
        <Col lg={12} className="inputCol">
          <p className="brandColor sourceBold">
            IMPORTANT: To finish your investment, input your credit card
            information using Stripe. Just follow the link below to securely and
            quickly sync your credit card account.
          </p>
          {!investForm?.stripe_url ? (
            <Button
              className="iframeWidgetButton"
              onClick={() => handleStripeLink()}
            >
              Connect Credit Card
            </Button>
          ) : null}
          {investForm?.stripe_url && investForm?.linked_cc_account ? (
            <>
              {investForm?.linked_cc_last_4 ? (
                <p className="sourceBold">
                  Current linked credit card: {investForm?.linked_cc_last_4}
                </p>
              ) : null}
              {investForm?.ccLinkSuccessful ? (
                <p className="sourceBold">Card added successfully</p>
              ) : null}
              <Button
                className="iframeWidgetButton"
                onClick={() => handleStripeLink(true)}
              >
                Update Credit Card
              </Button>
            </>
          ) : null}
          <hr className="brandHrLine" />
          <div id="stripeContainer">
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe id="stripeIframe" src={investForm?.stripe_url}></iframe>
          </div>
        </Col>
        {/* <Col lg={12} className="inputCol">
            <InputFields
              label="Name on Card"
              type="text"
              inputType="text"
              model=".creditCardName"
              value={cc_billing_info?.creditCardName}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <InputFields
              label="Credit Card Number"
              type="cc"
              inputType="number"
              model=".creditCardNumber"
              value={cc_billing_info?.creditCardNumber}
            />
          </Col>
          <Col lg={4} className="inputCol">
            <InputFields
              label="Card Expiration"
              type="number"
              placeholder="MMYY"
              inputType="number"
              model=".expirationDate"
              minLength={4}
              maxLength={4}
              value={cc_billing_info?.expirationDate}
            />
          </Col>
          <Col lg={4} className="inputCol">
            <InputFields
              label="CVV"
              type="number"
              inputType="number"
              model=".cvvNumber"
              value={cc_billing_info?.cvvNumber}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Card Type"
              type="select"
              inputType="select"
              model=".cardType"
              options={[
                { label: "Visa", value: "VI" },
                { label: "Mastercard", value: "MC" },
              ]}
              value={cc_billing_info?.cardType}
            />
          </Col>
          <Col lg={6} className="inputCol">
            <InputFields
              label="Zip Code"
              type="text"
              inputType="text"
              model=".billing_postal_code"
              // minLength={5}
              // maxLength={5}
              value={cc_billing_info?.billing_postal_code}
            />
          </Col> */}
        <Col lg={12} className="inputCol">
          Please read the full{" "}
          <a
            href={EFT}
            className="generalLink"
            title="EFT"
            target="_blank"
            rel="noreferrer"
          >
            Electronic Funds Transfer
          </a>
          <br />
          <br />
          <InputFields
            label="Your credit card will be charged immediately. Refunds are subject to a case by case review by the issuer; transactions are generally not refundable. 
              I acknowledge that the transaction is processed by North Capital as the merchant to deposit funds in escrow accounts held by North Capital and agree to the terms 
              and conditions for processing credit or debit card transactions."
            type="checkbox"
            inputType="checkbox"
            model=".credit_card_agreement"
          />
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(CreditCard);
