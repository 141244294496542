/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";
import Loading from "../Loading";
import "./style.css";

class Login extends Component {
  render() {
    const {
      user,
      userActions,
      handleClose,
      generalAlert,
      handleLogin,
      handle2FA,
      toggleSignupModal,
    } = this.props;

    return (
      <>
        <Form
          className="loginForm container-fluid"
          model="user"
          onSubmit={() =>
            userActions?.access_code ? handleLogin() : handle2FA()
          }
        >
          {user?.isLoading ? (
            <Loading inline={true} />
          ) : (
            <>
              {!user?.id ? (
                <Row>
                  {generalAlert?.error ? (
                    <Col lg={12}>
                      {/* <Alert className="formAlert sourceBold" variant="warning"> */}
                      <p className="errorText">{generalAlert?.error}</p>
                      {/* </Alert> */}
                    </Col>
                  ) : null}
                  {!userActions?.access_code ? (
                    <>
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label="Email"
                          type="email"
                          inputType="text"
                          model=".email"
                        />
                      </Col>
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label="Password"
                          type="password"
                          inputType="text"
                          model=".password"
                        />
                      </Col>
                      <Col lg={12} className="inputCol">
                        <Control.button
                          className="loginButton generalButtonInverted"
                          model="user"
                          type="submit"
                          disabled={{ valid: false }}
                        >
                          Login
                        </Control.button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label={
                            <>
                              Please enter the 7 digit authentication code sent
                              to your email. This token will expire in 15
                              minutes. Didn't receive the code? Check your spam
                              or{" "}
                              <a
                                href="#/"
                                className="signupChangeLink"
                                onClick={() => handle2FA()}
                              >
                                Resend
                              </a>
                              .
                            </>
                          }
                          type="text"
                          inputType="text"
                          model=".auth_code"
                        />
                      </Col>
                      <Col lg={12} className="inputCol">
                        <Control.button
                          className="loginButton generalButtonInverted"
                          model="user"
                          type="submit"
                          disabled={{ valid: false }}
                        >
                          Verify
                        </Control.button>
                      </Col>
                    </>
                  )}
                  <Col lg={6} className="inputLoginCol">
                    <p className="signupChangeText sourceRegular">
                      Don't have an account?{" "}
                      <a
                        href="#/"
                        className="signupChangeLink sourceBold"
                        onClick={() => toggleSignupModal(true, false, false)}
                      >
                        Join now
                      </a>
                    </p>
                  </Col>
                  <Col lg={6} className="inputLoginCol">
                    <p className="signupChangeText signupChangeTextRight sourceRegular">
                      Forgot Password?{" "}
                      <a
                        href="#/"
                        className="signupChangeLink sourceBold"
                        onClick={() => toggleSignupModal(false, false, true)}
                      >
                        Click here
                      </a>
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={12} className="inputCol">
                    {/* <Alert className="formAlert sourceBold" variant="success"> */}
                    <h1 className="congratulationsHeader sourceBold">
                      You have successfully logged in!
                    </h1>
                    {/* </Alert> */}
                    <Button
                      className="signupButton generalButtonInverted"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  generalAlert: state.generalAlert,
});

export default connect(mapStateToProps)(Login);
