/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Row, Col, Button, Modal } from "react-bootstrap";
import InputFields from "../InputFields";
import Loading from "../Loading";
import Close from "../../global/img/close.png";
import CheckMark from "./img/checkmark.png";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import "./style.css";

class SendDetails extends Component {
  handleSendDetails() {
    const { dispatch } = this.props;
    dispatch(actions.change("sendDetails.showFormError", null));
    this.props.dispatch({ type: "SEND_DETAILS" });
  }

  addFiles(file) {
    const { dispatch } = this.props;
    let files = [];
    files.push(file);
    dispatch(actions.change("sendDetails.deck", files));
    // this.setState({ [type]: files });
  }

  removeFile(fileName) {
    const { sendDetails, dispatch } = this.props;
    let files = [];
    files = sendDetails?.deck;
    files = files?.filter((x) => x.name !== fileName);
    dispatch(actions.change("sendDetails.deck", files));
    // this.setState({ [type]: files });
  }

  setPhoneErrors = (clear) => {
    const { dispatch } = this.props;
    dispatch(actions.change("sendDetails.showFormError", true));
    if (!clear) {
      dispatch(
        actions.change(
          "sendDetails.showPhoneFormError",
          "Phone number is not valid"
        )
      );
    } else {
      dispatch(actions.change("sendDetails.showPhoneFormError", null));
    }
  };

  // removeExistingFile(fileName, type) {
  //   const { editDeal, dispatch } = this.props;
  //   const { removedFiles } = this.state;
  //   let files;
  //   files = [...removedFiles];
  //   files.push(fileName);
  //   this.setState({ removedFiles: files });
  //   dispatch(actions.change(`editDeal.removed_files_url`, files));
  //   const documents = editDeal?.documents?.filter(
  //     (d) => d?.id !== fileName?.id
  //   );
  //   dispatch(actions.change(`editDeal.documents`, documents));
  // }

  render() {
    const { dispatch, show, handleClose, sendDetails } = this.props;

    const DocumentUploader = (type, subType) => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.addFiles(file, type, subType);
        } else if (status === "removed") {
          this.removeFile(file?.name, type, subType);
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept=".pdf,.ppt,.doc,.docx"
          PreviewComponent={null}
          inputContent="Upload Pitch Deck"
        />
      );
    };

    const disabled =
      !sendDetails?.phone ||
      (sendDetails?.phone && !!sendDetails?.showPhoneFormError) ||
      !sendDetails?.deck?.length;

    return (
      <Modal
        id="investModalDialog"
        show={show}
        onHide={handleClose}
        contentClassName="investModalContent"
        backdrop
        backdropClassName="modalBackdrop"
        centered
      >
        <Modal.Header className="signupModalHeader">
          <section className="investModalHeaderSection">
            <Modal.Title className="investModalTitle sourceBold">
              Raise Capital For Your Project
            </Modal.Title>
            <Button className="noStyleBtn modalCloseBtn" onClick={handleClose}>
              <img src={Close} alt="Close" className="modalClose" />
            </Button>
          </section>
        </Modal.Header>
        <Modal.Body
          className="signupModalBody"
          style={{ display: sendDetails?.success ? "flex" : "" }}
        >
          {sendDetails?.isLoading ? (
            <Loading />
          ) : (
            <>
              {sendDetails?.success ? (
                <div className="detailsThankYouDiv">
                  <img
                    src={CheckMark}
                    alt="Check Mark"
                    className="detialsCheckMark"
                  />
                  <h1 className="sourceBold detailsThankYouHeader">
                    Thank You!
                  </h1>
                  <p className="detailsThankYouSubHeader sourceRegular">
                    Your details have been submitted successfully
                  </p>
                  <p className="detailsThankYouText sourceRegular">
                    We will be in touch with you shortly.
                  </p>
                  <Button
                    className="doneButton"
                    alt="Done"
                    onClick={() => {
                      dispatch(actions.change("sendDetails", {}));
                      handleClose();
                    }}
                  >
                    Done
                  </Button>
                </div>
              ) : (
                <Form
                  className="investFormModel container-fluid"
                  model="sendDetails"
                  onSubmit={() => {
                    if (!disabled) {
                      this.handleSendDetails();
                    }
                  }}
                >
                  <section className="investFormSection">
                    <Row>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="First Name"
                          type="text"
                          inputType="text"
                          model=".first_name"
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="Last Name"
                          type="text"
                          inputType="text"
                          model=".last_name"
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="Company Name"
                          type="text"
                          inputType="text"
                          model=".company_name"
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="Email"
                          type="email"
                          inputType="text"
                          model=".email"
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="Phone"
                          type="tel"
                          inputType="customPhone"
                          model="sendDetails.phone"
                          value={sendDetails?.phone}
                          setPhoneErrors={(v) => this.setPhoneErrors(v)}
                        />
                        {!sendDetails?.phone && sendDetails?.showFormError ? (
                          <p className="formInputError">Required*</p>
                        ) : null}
                        {sendDetails?.phone &&
                        sendDetails?.showPhoneFormError &&
                        sendDetails?.showFormError ? (
                          <p className="formInputError">
                            {sendDetails?.showPhoneFormError}*
                          </p>
                        ) : null}
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="Website URL"
                          type="text"
                          inputType="text"
                          model="sendDetails.website_url"
                          ignore={true}
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="How much have you previously raised?"
                          type="text"
                          inputType="currency"
                          model="sendDetails.raised"
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="How are you Incorporated?"
                          type="text"
                          inputType="text"
                          model="sendDetails.incorporated"
                        />
                      </Col>
                      <Col lg={6} className="inputCol">
                        <InputFields
                          label="Deal Type"
                          type="select"
                          inputType="select"
                          model="sendDetails.deal_type"
                          options={[
                            { label: "Hotel", value: "hotel" },
                            { label: "Multifamily", value: "multifamily" },
                            { label: "Mixed Use", value: "mixed use" },
                            { label: "Office", value: "office" },
                            { label: "Other", value: "other" },
                          ]}
                        />
                      </Col>
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label="Please tell us about the deal and why someone should invest?"
                          type="text"
                          inputType="text"
                          model="sendDetails.info"
                        />
                      </Col>
                      {!sendDetails?.deck?.length && (
                        <>
                          <p className="identificationUpload sourceBold">
                            Upload Pitch Deck
                            <br /> Only pdf, ppt, doc
                            {/* <span className="required">*</span> */}
                          </p>
                          <Col
                            lg={12}
                            style={{
                              paddingBottom: !sendDetails?.showFormError
                                ? "30px"
                                : 0,
                            }}
                          >
                            <DocumentUploader />
                          </Col>
                          {sendDetails?.showFormError ? (
                            <p
                              className="formInputError"
                              style={{
                                marginTop: "5px",
                                paddingBottom: "15px",
                              }}
                            >
                              Pitch deck upload is required*
                            </p>
                          ) : null}
                        </>
                      )}
                      {sendDetails?.deck?.map((file, i) => (
                        <Col key={i} lg={12} className="fileCol">
                          <h1 className="filesHeader sourceBold">
                            Uploaded Pitch Deck
                          </h1>
                          <div className="fileButtonDiv">
                            <p className="fileButtonName">{file?.name}</p>
                            <Button
                              className="fileCloseButton hoverDelay"
                              onClick={() => this.removeFile(file?.name)}
                            >
                              X
                            </Button>
                          </div>
                        </Col>
                      ))}
                      <Col lg={12} className="inputCol">
                        <Control.button
                          className="signupButton generalButtonInverted"
                          model="sendDetails"
                          type="submit"
                          // disabled={{ valid: false }}
                          onClick={() => {
                            dispatch(
                              actions.change("sendDetails.showFormError", true)
                            );
                          }}
                        >
                          Submit
                        </Control.button>
                      </Col>
                    </Row>
                  </section>
                </Form>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  sendDetails: state.sendDetails,
});

export default connect(mapStateToProps)(SendDetails);
