/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form } from "react-redux-form";
import { Row, Col, Button, Modal } from "react-bootstrap";
import queryString from "query-string";
import InputFields from "../InputFields";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Footer from "../Footer";
import "./style.css";
import Close from "../../global/img/close.png";
import Loading from "../Loading";

class OurProcess extends Component {
  state = {
    token: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const parsed = queryString.parse(window.location.search);
    const token = parsed?.token;
    if (token) {
      this.setState({ token });
      dispatch({
        type: "HANDLE_CANCEL_TOKEN",
        payload: { token, verify: true },
      });
    }
  }

  handleCancel() {
    const { dispatch } = this.props;
    const { token } = this.state;
    dispatch({
      type: "HANDLE_CANCEL_TOKEN",
      payload: { token },
    });
  }

  render() {
    const { cancelInvestment } = this.props;

    return (
      <React.Fragment>
        <GeneralCoverHeader header="Cancel Investment" />
        <section className="processSection">
          <Modal
            id="loginModalDialog"
            show={true}
            backdrop
            backdropClassName="modalBackdrop"
            centered
          >
            <Modal.Header className="signupModalHeader">
              <section className="loginHeaderSection">
                <Modal.Title className="loginModalTitle sourceBold">
                  Cancel Investment
                </Modal.Title>
                <Button className="noStyleBtn loginModalCloseBtn" href="/">
                  <img src={Close} alt="Close" className="modalClose" />
                </Button>
              </section>
            </Modal.Header>
            <Modal.Body className="signupModalBody">
              {cancelInvestment?.isLoading ? <Loading inline={true} /> : null}
              {cancelInvestment?.tokenInvalid ? (
                <h1 className="cancelHeader sourceBold">
                  This link is no longer valid.
                </h1>
              ) : null}
              {cancelInvestment?.is_cancelled ? (
                <>
                  <h1 className="cancelHeader sourceBold">
                    {cancelInvestment?.message}
                  </h1>
                  <Button
                    className="loginButton generalButtonInverted"
                    href="/"
                    title="Home"
                  >
                    Home
                  </Button>
                </>
              ) : null}
              {!cancelInvestment?.tokenInvalid &&
                !cancelInvestment?.is_cancelled && (
                  <Form
                    className="loginForm container-fluid"
                    model="cancelInvestment"
                    onSubmit={() => this.handleCancel()}
                  >
                    <Row>
                      <Col lg={12}>
                        <InputFields
                          label="Are you sure you want to cancel your investment?"
                          type="checkbox"
                          inputType="checkbox"
                          model=".cancel"
                          // standardLabel={true}
                        />
                      </Col>
                      <Col lg={12} className="inputCol">
                        <Control.button
                          className="loginButton generalButtonInverted"
                          model="cancelInvestment"
                          title="Cancel Investment"
                          type="submit"
                          disabled={{ valid: false }}
                        >
                          Cancel
                        </Control.button>
                      </Col>
                    </Row>
                  </Form>
                )}
            </Modal.Body>
          </Modal>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
  cancelInvestment: state.cancelInvestment,
});

export default connect(mapStateToProps)(OurProcess);
