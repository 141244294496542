/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import Building from "./img/building.png";
import Footer from "../Footer";
import GeneralCoverHeader from "../GeneralCoverHeader";
import SendDetails from "./SendDetails";
import "./style.css";

class RaiseCapital extends Component {
  state = {
    show: false,
  };

  render() {
    const { show } = this.state;

    const handleClose = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });

    return (
      <React.Fragment>
        {show && <SendDetails show={show} handleClose={handleClose} />}
        <GeneralCoverHeader header="Raise Capital" />
        <section className="mainRaiseSection">
          <div className="mainRaiseDiv mainContainer">
            <Row className="mainRaiseRow">
              <Col
                lg={6}
                className="mainRaiseCol mainRaiseCol2 mainRaiseCol2Mobile"
              >
                <img src={Building} alt="Building" className="mainRaiseImg" />
              </Col>
              <Col lg={6} className="mainRaiseCol">
                <p className="mainRaiseText sourceBold">
                  Vesterr simplifies the process of finding equity partners for
                  your commercial real estate project. We work with you to
                  onboard your project to our platform. Supporting equity or
                  debt transactions for commercial real estate projects, our
                  platform provides
                </p>
                <ul className="mainRaiseUl sourceRegular">
                  <li className="mainRaiseLi">Faster access to capital</li>
                  <li className="mainRaiseLi">
                    A connection to a community of passionate investors
                  </li>
                  <li className="mainRaiseLi">
                    Technology that makes it easy to invest in your company
                    through a seamless, completely online process
                  </li>
                  <li className="mainRaiseLi">
                    A dashboard that simplifies managing your investors and the
                    details of your financing
                  </li>
                  <li className="mainRaiseLi">
                    A way to develop a community of prospective investors who
                    have shown interest, but not yet invested in your offering
                  </li>
                </ul>
                <Row className="mainRaiseBtnRow">
                  <Col lg={6}>
                    <Button
                      className="generalButtonInverted raiseHowItWorksButton"
                      title="How It Works"
                      href="#howitworks"
                    >
                      How It Works
                    </Button>
                  </Col>
                  <Col lg={6}>
                    <Button
                      className="generalButton"
                      title="Get Started Now"
                      onClick={handleShow}
                    >
                      Get Started Now
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={6}
                className="mainRaiseCol mainRaiseCol2 mainRaiseCol2Desktop"
              >
                <img src={Building} alt="Building" className="mainRaiseImg" />
              </Col>
            </Row>
          </div>
        </section>
        <section className="raiseHowSection" id="howitworks">
          <div className="raiseHowDiv mainContainer">
            <h1 className="sourceBold generalWhiteHeader">How it works</h1>
            <Row className="raiseHowRow">
              <Col lg={2} className="raiseHowCol">
                <p className="raiseHowNumber">01</p>
              </Col>
              <Col lg={10} className="raiseHowCol">
                <div className="raiseHowStepsTextDiv">
                  <p className="raiseHowStepsTextHeader">Apply</p>
                  <p className="raiseHowStepsText">
                    Submit your business plan, describe your fundraising goals
                    and share contact information. We will review your documents
                    and learn more about your business processes, financial
                    position and legal status so we can recommend the financing
                    option that seems best to meet your needs.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="raiseHowRow">
              <Col lg={2} className="raiseHowCol">
                <p className="raiseHowNumber">02</p>
              </Col>
              <Col lg={10} className="raiseHowCol">
                <div className="raiseHowStepsTextDiv">
                  <p className="raiseHowStepsTextHeader">Document</p>
                  <p className="raiseHowStepsText">
                    Once you're approved, we guide you through the preparation
                    of your marketing materials and offering documents. At the
                    end of the process, you will have at the end of the process
                    a comprehensive media kit, a pitch deck and supporting
                    documents for use throughout your offering.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="raiseHowRow">
              <Col lg={2} className="raiseHowCol">
                <p className="raiseHowNumber">03</p>
              </Col>
              <Col lg={10} className="raiseHowCol">
                <div className="raiseHowStepsTextDiv">
                  <p className="raiseHowStepsTextHeader">Launch</p>
                  <p className="raiseHowStepsText">
                    Take your offer live and tap into our investor pool. Our
                    crowdfunding investment platform is a hub for all your
                    capital-raising efforts: a single online presence where you
                    can drive all your potential investor traffic.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="raiseHowRow">
              <Col lg={2} className="raiseHowCol">
                <p className="raiseHowNumber">04</p>
              </Col>
              <Col lg={10} className="raiseHowCol">
                <div className="raiseHowStepsTextDiv">
                  <p className="raiseHowStepsTextHeader">Connect</p>
                  <p className="raiseHowStepsText">
                    Our platform allows you to communicate directly with your
                    community of prospects and investors, keeping them up to
                    date, building momentum and growing a base of champions for
                    your business or project.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="raiseHowRow">
              <Col lg={2} className="raiseHowCol">
                <p className="raiseHowNumber">05</p>
              </Col>
              <Col lg={10} className="raiseHowCol">
                <div className="raiseHowStepsTextDiv">
                  <p className="raiseHowStepsTextHeader">Close</p>
                  <p className="raiseHowStepsText">
                    When you arrive at your fundraising deadline or you've
                    successfully reached your goal, our technology quickly,
                    securely closes out the offering and processes all
                    transactions so you can access your capital.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
});

export default connect(mapStateToProps)(RaiseCapital);
