/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { currencyFormatter } from "../../../utils";
import { Row, Col } from "react-bootstrap";
import InputFields from "../../../InputFields";

class TransactionFee extends Component {
  render() {
    const { investForm, deal } = this.props;

    // const c1 = "$123,097.28";
    // const c2 = "$3,189.94";

    // const transactionAmount = currencyFormatter.format(
    //   parseFloat(c1?.replace(/[^0-9.]/g, "")) +
    //     parseFloat(25)
    // );

    const transactionAmount = currencyFormatter.format(
      parseFloat(investForm?.investment_info?.amount?.replace(/[^0-9.]/g, "")) +
        parseFloat(deal?.transaction_fee)
    );

    const transactionFee = currencyFormatter.format(
      parseFloat(deal?.transaction_fee)
    );

    return (
      <>
        <Row className="inputRow">
          <Col lg={12} className="inputCol">
            <p className="investorQuestText sourceBold">
              In addition to your investment amount, a non-refundable
              transaction fee of {transactionFee} will be assessed to cover
              processing expenses related to this investment. As such, to
              complete this investment commitment, you will need to transfer a
              total of {transactionAmount}
            </p>
            <InputFields
              label={`I understand that I will be transferring a total of ${transactionAmount} and that ${transactionFee} is a transaction fee that is non-refundable in the event I wish to cancel my commitment.`}
              type="checkbox"
              inputType="checkbox"
              model=".transaction_fee_agreement"
              value={
                investForm?.transaction_processing_fee
                  ?.transaction_fee_agreement
              }
              // standardLabel={true}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  deal: state.deal,
  investForm: state.investForm,
  investFormCurrentModel: state.investFormCurrentModel,
});

export default connect(mapStateToProps)(TransactionFee);
