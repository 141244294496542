/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Row, Col, Button, Modal } from "react-bootstrap";
import InputFields from "../InputFields";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Close from "../../global/img/close.png";
// import Select from "react-select";
import { toTitleCase } from "./../utils/index";
import axios from "axios";

class GeneralSettings extends Component {
  state = {
    customLink: null,
    show: false,
  };

  toggleButtons = (addOption, removeOption, editOption) => {
    this.setState({
      addOption: addOption,
      removeOption: removeOption,
      editOption: editOption,
    });
  };

  handleQuestions(approve) {
    this.props.dispatch({
      type: "HANDLE_QUESTIONS",
      payload: { approve },
    });
  }

  render() {
    const { dispatch } = this.props;
    const { show } = this.state;
    let questions = this.props?.questions?.data;
    const columns = [];

    const handleShow = (id, answer, user_id, deal_id) => {
      this.setState({ show: !show || null });
      dispatch(
        actions.change("userActions.qa", {
          id,
          answer,
          user_id,
          deal_id,
        })
      );
    };

    const approveButton = (cell, row, rowIndex, formatExtraData) => {
      const approved = row?.approved;
      dispatch(
        actions.change("userActions.qa", {
          id: row?.id,
        })
      );
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            className={!approved ? "adminOptionBtn" : "adminOptionDenyBtn"}
            onClick={() => this.handleQuestions(!approved)}
          >
            {!approved ? "Approve" : "Deny"}
          </Button>
        </div>
      );
    };

    const answerButton = (cell, row, rowIndex, formatExtraData) => {
      const answer = row?.answer;
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            className={!answer ? "adminOptionBtn" : "adminOptionDenyBtn"}
            onClick={() =>
              handleShow(row?.id, row?.answer, row?.asker_id, row?.deal_id)
            }
          >
            {!answer ? "Answer" : "Change"}
          </Button>
        </div>
      );
    };

    let questionsHeader = questions?.[0];
    questionsHeader = {
      Approve: "Approve",
      Answer: "Answer",
      ...questionsHeader,
    };

    const ignoredColumns = ["approved", "id", "asker_id", "deal_id"];

    for (const property in questionsHeader) {
      if (property === "Approve") {
        columns.push({
          dataField: property,
          text: property?.replaceAll("_", " "),
          sort: true,
          formatter: approveButton,
          headerClasses: "tableHeader",
          headerStyle: (column, colIndex) => {
            return { width: "50px" };
          },
        });
      } else if (property === "Answer") {
        columns.push({
          dataField: property,
          text: `Answer Question`,
          // text: property?.toUpperCase()?.replaceAll("_", " "),
          sort: true,
          formatter: answerButton,
          headerClasses: "tableHeader",
          headerStyle: (column, colIndex) => {
            return { width: "50px" };
          },
        });
      } else if (!ignoredColumns.includes(property)) {
        columns.push({
          dataField: property,
          text: toTitleCase(property?.replaceAll("_", " ")),
          sort: true,
          headerClasses: "tableHeader",
          headerStyle: (column, colIndex) => {
            return { width: "50px" };
          },
        });
      }
    }

    return (
      <>
        <Modal
          id="loginModalDialog"
          show={show}
          onHide={() => handleShow(false)}
          backdrop
          backdropClassName="modalBackdrop"
          centered
        >
          <Modal.Header className="signupModalHeader">
            <section className="loginHeaderSection">
              <Modal.Title className="loginModalTitle sourceBold">
                Answer Question
              </Modal.Title>
              <Button
                className="noStyleBtn loginModalCloseBtn"
                onClick={() => handleShow(false)}
              >
                <img src={Close} alt="Close" className="modalClose" />
              </Button>
            </section>
          </Modal.Header>
          <Modal.Body className="">
            <Form
              className="investFormModel container-fluid"
              model="userActions.qa"
              // onSubmit={() => handleAskQuestion()}
              onSubmit={() => this.handleQuestions()}
            >
              <Row>
                <Col lg={12} className="inputCol">
                  <InputFields
                    type="textarea"
                    inputType="textarea"
                    model=".answer"
                    placeholder="Please enter your answer here...."
                  />
                  <Control.button
                    model="userActions.qa"
                    className="generalSubmitButtonInverted"
                    style={{ marginTop: "30px" }}
                    type="submit"
                    disabled={{
                      valid: false,
                    }}
                  >
                    Submit
                  </Control.button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        <Col lg={12}>
          <div className="adminHeaderDiv">
            <h1 className="generalHeader">Questions</h1>
            {/* <Button className="generalButton adminHeaderDivButton">
              Save Changes
            </Button> */}
          </div>
          <span className="headerSpacer"></span>
          <div className="adminTable">
            {columns?.length && questions?.length ? (
              <BootstrapTable
                keyField="id"
                data={questions}
                columns={columns}
              />
            ) : (
              <h3 className="sourceBold">No questions available</h3>
            )}
          </div>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  questions: state.questions,
});

export default connect(mapStateToProps)(GeneralSettings);
