import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../global/img/logo.png";
import fb from "../../global/img/logos/purple/fb.png";
import tw from "../../global/img/logos/purple/tw.png";
import ln from "../../global/img/logos/purple/ln.png";
// import insta from "../../global/img/logos/purple/insta.png";
import "./style.css";

class Footer extends Component {
  render() {
    return (
      <section className="footerSection">
        <div className="mainFooterDiv">
          <img src={logo} className="footerLogo" alt="Logo" />
          {/* <div className="verticalLine" />
          <p className="footerGetInTouch sourceRegular">GET IN TOUCH</p> */}
          <div className="footerContactDiv sourceRegular">
            <p>
              384 Northyards Blvd Suite 190
              <br />
              Atlanta, GA 30313
              <br />
              (678)981-4660
              <br />
              invest@vesterr.com
            </p>
          </div>
          <div className="footerSocialDiv">
            <a
              href="https://www.facebook.com/Vesterr-103711092376055"
              className="footerSocialLink"
              target="_blank"
              rel="noreferrer"
              title="Facebook"
            >
              <img src={fb} className="footerSocialImg" alt="Facebook" />
            </a>
            <a
              href="https://twitter.com/investvesterr"
              className="footerSocialLink"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <img src={tw} className="footerSocialImg" alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/vesterr/?viewAsMember=true"
              className="footerSocialLink"
              target="_blank"
              title="LinkedIn"
              rel="noreferrer"
            >
              <img src={ln} className="footerSocialImg" alt="LinkedIn" />
            </a>
            {/* <a
              href="#/"
              className="footerSocialLink"
              target="_blank"
              title="Instagram"
            >
              <img src={insta} className="footerSocialImg" alt="Instagram" />
            </a> */}
          </div>
          <div className="footerTextDiv mainContainer">
            <p className="footerText">
              <strong>IMPORTANT LEGAL NOTICE: </strong>
              Vester LLC is a funding portal ("Funding Portal") registered with
              the Securities and Exchange Commission and a member of the
              Financial Industry Regulatory Authority. By accessing the Funding
              Portal and any pages on the Funding Portal, you agree to be bound
              by the Terms of Use and Privacy Policy, as may be amended from
              time to time. The Funding Portal only permits the posting of
              securities offerings made pursuant to Section 4(a)(6) of the
              Securities Act of 1933, as amended, in accordance with the Title
              III of the Jumpstart Our Business Startups (JOBS) Act of 2012,
              including its adopting release and subsequent guidance. Investors
              must acknowledge and accept the high risks associated with
              investing in private securities offerings, include holding your
              investment for periods of many years with limited ability to
              resell, limited access to periodic reporting, and losing your
              entire investment. You must have the ability to bear a total loss
              of your investment without a change in your lifestyle. Funding
              Portal is only required to conduct limited onboarding due
              diligence on each offering and does not in any way give investment
              advice, provide analysis or recommendations regarding any offering
              posted on the Funding Portal. Past performance is not indicative
              of future performance. All investors should make their own
              determination of whether or not to make any investment in an
              offering, based on their own independent evaluation, due diligence
              and analysis and after consulting with their financial, tax and
              investment advisors. Prior to making any investment, you will be
              required to demonstrate your understanding of the speculative
              nature of investing in such private securities. The securities
              presented on this Funding Portal can only be marketed in
              jurisdictions where public solicitation of offerings are
              permitted; it is solely your responsibility to comply with the
              laws and regulations of your country of residence. You are
              strongly advised to consult your legal, tax and financial advisor
              before investing.
              <br />
              <br />
              You can learn more about investing in crowdfunding from the{" "}
              <a
                href="https://www.sec.gov/oiea/investor-alerts-bulletins/ib_crowdfunding-.html"
                target="_blank"
                rel="noreferrer"
                className="generalLink"
              >
                SEC
              </a>
              ,{" "}
              <a
                href="http://www.finra.org/investors/alerts/crowdfunding-and-jobs-act-what-investors-should-know"
                target="_blank"
                rel="noreferrer"
                className="generalLink"
              >
                FINRA
              </a>{" "}
              or{" "}
              <a
                href="http://www.nasaa.org/13676/small-business-advisory-crowdfunding/"
                target="_blank"
                rel="noreferrer"
                className="generalLink"
              >
                NASAA
              </a>
              .
              <br />
              <br />
              Vesterr LLC, FINRA Registered Funding Portal (
              <a
                href="http://www.finra.org/about/funding-portals-we-regulate"
                target="_blank"
                rel="noreferrer"
                className="generalLink"
              >
                http://www.finra.org/about/funding-portals-we-regulate
              </a>
              )
              <br />
              <br />
              Additional information about companies raising money on the
              Funding Portal is also available on the SEC's{" "}
              <a
                href="https://www.sec.gov/edgar/searchedgar/companysearch.html"
                target="_blank"
                rel="noreferrer"
                className="generalLink"
              >
                EDGAR Database
              </a>
              .
              <br />
              <br />
              Copyright © 2024 Vesterr LLC.
              <br />
              <br />
              View{" "}
              <a
                href="/disclosures"
                className="footerDisclosureLink hoverDelay"
                title="Disclosure"
                target="_blank"
                rel="noreferrer"
              >
                Fee Disclosures
              </a>
            </p>
          </div>
          <div className="footerCopyrightDiv">
            <div className="footerCopyrightTextDiv sourceRegular mainContainer">
              <p className="footerCopyrightTextLeft">
                ©2024 Vesterr LLC, All rights reserved.
              </p>
              <br />
              <a
                href="https://myriann.com"
                title="Myriann Link"
                className="footerCopyrightTextRight hoverDelay"
                target="_blank"
                rel="noreferrer"
              >
                Designed by Myriann
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(Footer);
